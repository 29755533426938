<section class="section pt-120 pb-100">
    <div class="container web-article">
        <div class="row">
            <div class="col-12 mt-md-0 mt-5">
                <h1 class="text-uppercase title my-2">プライバシーポリシー</h1>
            </div>
            <div class="col-lg-12 mt-4 pt-2">
                <h5>1.当サイト運営者（事業者）の氏名又は名称：株式会社エスラボ</h5>
                <h5>2.当社で取扱う個人情報の利用目的</h5>
                <p class="text-muted ps-4">1)お取引先の皆様に関する個人情報</p>
                <p class="text-muted ps-4">・当社各事業に係る連絡、協力、交渉、契約履行等のため</p>
                <p class="text-muted ps-4">2)お問い合わせされた皆様に関する個人情報</p>
                <p class="text-muted ps-4">・当社各事業に関するお問い合わせ対応のため</p>
                <p class="text-muted ps-4">3) 従業員に関する個人情報の利用目的</p>
                <p class="text-muted ps-4">・人事管理等のため</p>
                <p class="text-muted ps-4">・お客様等への提案、営業のため</p>
                <p class="text-muted ps-4">4) 採用募集者に関する個人情報の利用目的</p>
                <p class="text-muted ps-4">・当サイト運営者並びに契約締結者（Jobinder会員／有料職業紹介事業者）から採用募集者への紹介利用</p>
                <p class="text-muted ps-4">・採用選考のため</p>
                <p class="text-muted ps-4">・採用選考手続き及びその結果に関する連絡のため</p>
                <p class="text-muted ps-4">・入社前手続き実施とその他の手続きに関する連絡のため</p>
                <h5>3.苦情・相談の申し出先</h5>
                <p class="text-muted ps-4">個人情報の取扱いに関するお問い合わせ、苦情及びご相談につきましては、以下第6項の「個人情報お問合せ窓口」にご連絡ください。</p>
                <h5>4.開示等の請求等に応じる手続</h5>
                <p class="text-muted ps-4">1) 開示対象の個人情報について</p>
                <p class="text-muted ps-4">当社で取扱う個人情報に関して、本人から利用目的の通知、開示、内容の訂正、追加または削除、利用の停止、消去および第三者への提供の停止（以下、「開示等」という）の請求がございましたら、以下第6項の「個人情報お問合せ窓口」にご連絡ください。</p>
                <p class="text-muted ps-4">2) 開示請求の申出先</p>
                <p class="text-muted ps-4">開示請求は以下第6項の「個人情報お問合せ窓口」にご連絡ください。当社が保有するご自身の個人情報の開示等を求める場合には、当社所定の書面に必要事項をご記入の上、ご提出をお願いいたします。</p>
                <p class="text-muted ps-4">3) 開示請求依頼にあたっては、本人確認をいたします。場合によっては、公的な証明書の写しをご郵送いただき、確認を行います。</p>
                <p class="text-muted ps-4">※ 代理人については、委任状をご提出いただき確認を行います。なお、代理人を立てる場合には、ご本人と代理人両者の公的証明書のコピーを提出いただく必要がございます。また、電話やメール、FAXなどで開示等の要求は、一切受け付けておりませんので、簡易書留郵便等の発送記録が取れる方法での提出をお願いいたします。</p>
                <p class="text-muted ps-4">※ 未成年者又は成年被後見人の法定代理人の場合は、法的な代理権を証明する書類で代理権があることを確認いたします。</p>
                <p class="text-muted ps-4">4) 開示等請求のうち「利用目的の通知」「開示」の請求にあたり、手数料は1,000円を上限に徴収いたします。これを超えることが明白な場合は別途、ご連絡いたします。</p>
                <h5 #cookie>5.WebサイトにおけるCookie等の取扱いについて</h5>
                <p class="text-muted ps-4">1) 当社では、当社サービスの利便性向上、統計データ分析及び広告の効果測定の目的でCookie（クッキー）及びウェブ・ビーコンなどの統計的なサイト利用情報を取得しています。</p>
                <p class="text-muted ps-4">Cookie（クッキー）とは、お客様がWebサイトを快適にご利用できるように、Webサーバがお客様のブラウザに送信する情報です。なお、お客様は、Cookie（クッキー）の受け取りを拒否するように設定することもできますが、その場合、当社Webサイトが提供するサービスの全部又は一部をご利用できなくなることがありますので予めご了承ください。</p>
                <p class="text-muted ps-4">また、リンクされている当社以外が運営するWebサイトにおける個人情報の取扱いについては、責任を負うことはできません。</p>
                <p class="text-muted ps-4">また、ウェブ・ビーコンとは、ウェブサイトに埋め込まれた小さな画像ファイルをいい、お客様によるWebサイトへの訪問の有無や訪問回数等の統計データを把握することができるものですが、ウェブ・ビーコンには、個人を特定する情報は含まれておりません。</p>
                <p class="text-muted ps-4">2) Google、Facebook 第三者配信事業者の利用</p>
                <p class="text-muted ps-4">当社は、Jobinderにおいて、サービス利用者である求職者の利用者登録を行うためにGoogle及びFacebookのアカウントを利用する場合がございます。</p>
                <p class="text-muted ps-4">当社が保有する求職登録者の個人情報はご本人同意の上、職業紹介事業者による照合の結果に基づいて職業紹介サービスに利用します。</p>
                <p class="text-muted ps-4">また、当社が保有する個人情報を暗号化し、当該第三者配信事業者による照合の結果に基づいて広告を配信することがありますが、当該第三者配信事業者は広告配信以外の目的でこれらの情報を利用することはありません。</p>
                <p class="text-muted ps-4">3) Facebookのカスタムオーディエンスを利用した広告配信について</p>
                <p class="text-muted ps-4">当社は、当社サービスの利用状況をもとにした広告を表示するため、Facebookのカスタムオーディエンスを利用します。詳細は下記をご確認ください。</p>
                <p class="text-muted ps-4">提供会社：Facebook, Inc.</p>
                <p class="text-muted ps-4">サービスの説明：<a href="https://ja-jp.facebook.com/business/help/744354708981227?id=2469097953376494" target="_blank">https://ja-jp.facebook.com/business/help/744354708981227?id=2469097953376494</a></p>
                <p class="text-muted ps-4">オプトアウト方法：<a href="https://www.facebook.com/about/ads" target="_blank">https://www.facebook.com/about/ads</a></p>

                <h5>6. 個人情報お問合せ窓口</h5>
                <p class="text-muted ps-4">当社の「個人情報お問合せ窓口」の連絡先は以下になります。</p>
                <br>
                <p class="text-muted">個人情報お問合せ窓口 ／ Email：info@slabcorp.co.jp</p>
                <h5 class="text-muted">当社では、より良くお客様の個人情報保護を図るため、及び法令等の変更に対応するために、「個人情報の取扱いについて」を改定することがあります。</h5>
            </div>
        </div>

    </div>
</section>
