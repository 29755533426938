export class ProfileForm {
  // 名前
  nameKanji!: string;
  // 名前(かな)
  nameKana!: string;
  // 名前アルファベット
  nameAlphabet!: string;
  // 性別
  gender!: number;
  // 年龄
  age!: number;
  // 誕生日
  birthday!: string;
  // 国籍
  country!: number;
  // 出身地
  birthplace!: string;
  // 郵便番号
  zipcode!: string;
  // 住所
  address!: string;
  // 電話番号
  tel!: string;
  // Wechat ID/LineID
  sns!: string;
  // メールアドレス
  email!: string;
  // スキル
  skill!: string;
  // 最寄駅
  nearestStation!: string;
  // 経験年数
  experience!: number;
  // 資格
  qualification!: string;
  // 履歴書
  resume!: string;
  // 職務経歴
  career!: string;
  // 現在の状態
  currentStatus!: number;
  // 自己紹介
  selfIntroduction!: string;
  // 学位
  degree!: number;
  // 学校名
  schoolName!: string;
  // 専攻
  major!: string;
  // 時期開始
  studyPeriodStart!: string;
  // 時期終了
  studyPeriodEnd!: string;

}

export class User {
  userForeignUuid!: string;
  status!: number;
  foreignType!: string;
  accountId!: string;
  showName!: string;
  password!: string;
  createDate!: string;
}

export class Skill{
  id: number;
  techTagId: number;
  processId: number;
  years: number;
}

export class TechTag{
  id: number;
  content: string;
}

export class Process{
  id: number;
  content: string;
}

export class ExYear{
  id: number;
  content: string;
}

export class DropdownOption{
  id: number;
  content: string;
}
