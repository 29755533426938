<footer class="footer bg-ebony-clay dark-mode-texts" *ngIf="getShowFooter()">
    <div class="container">
        <div class="pt-4 pt-lg-20 pb-4 pb-lg-20 border-bottom border-width-1 border-default-color-2 mobile-container">
            <div class="row justify-content-center">
                <div class="col-xl-7 col-lg-12" data-aos="fade-right" data-aos-duration="800" data-aos-once="true">
                    <div class="pb-xl-0 pb-9 text-xl-left text-left">
                        <!-- logo -->
                        <img src="./assets/img/jobinder-logo-white.png" alt="" class="w-45 mb-2 px-5">
<!--                        <p class="text-white font-size-5 mb-0 text-left slg-text">-->
<!--                            自分に見合う求人を自分で探すシステムで、効率よく、成約率が高いです。<br>さらに税抜き契約で単価アップしますし、契約書の締結で安心して取引します。 </p>-->
                    </div>
                </div>
                <div class="col-xl-5 col-lg-12" data-aos="fade-left" data-aos-duration="800" data-aos-once="true">
                    <div class="btns d-flex justify-content-xl-end justify-content-center align-items-xl-center flex-wrap mx-n4 footer-btn">
                        <!--            <a class="btn btn-outline-gallery font-size-4 btn-xl mx-4 mt-6 text-uppercase" routerLink="/login">ログイン</a>-->
<!--                        <a class="register-btn mx-2" routerLink="/signup">無料登録</a>-->
                        <a class="register-btn mx-2" href="https://slabcorp.co.jp/contact/" target="_blank">問い合わせ</a>
                    </div>
<!--                    <div class="px-4 pt-4" style="text-align: center">-->
<!--                        <a class="heading-default-color font-size-4 font-weight-normal" href="https://slabcorp.co.jp/" target="_blank">-->
<!--                            運営会社-->
<!--                        </a>-->
<!--                    </div>-->
                </div>
            </div>
        </div>
        <div class="pt-50 pt-lg-20 pb-4 pb-lg-20 border-bottom border-width-1 border-default-color-2 desktop-container">
            <div class="row justify-content-center">
                <div class="col-xl-7 col-lg-12" data-aos="fade-right" data-aos-duration="800" data-aos-once="true">
                    <div class="pb-xl-0 pb-9 text-xl-left text-left">
                        <!-- logo -->
                        <img src="./assets/img/jobinder-logo-white.png" alt="" class="w-35 mb-4">
<!--                        <p class="text-white font-size-5 mb-0 text-left slg-text">-->
<!--                            自分に見合う求人を自分で探すシステムで、効率よく、成約率が高いです。<br>さらに税抜き契約で単価アップしますし、契約書の締結で安心して取引できます。 </p>-->
                    </div>
                </div>
                <div class="col-xl-5 col-lg-12 footer-right" data-aos="fade-left" data-aos-duration="800" data-aos-once="true">
                    <div class="btns d-flex justify-content-xl-end justify-content-center align-items-xl-center flex-wrap mx-n4 footer-btn">
                        <!--            <a class="btn btn-outline-gallery font-size-4 btn-xl mx-4 mt-6 text-uppercase" routerLink="/login">ログイン</a>-->
<!--                        <a class="register-btn mx-2" routerLink="/register">無料登録</a>-->
                        <a class="register-btn mx-2" href="https://slabcorp.co.jp/contact/" target="_blank">問い合わせ</a>
                    </div>
<!--                    <div class="text-right px-4">-->
<!--                        <a class="heading-default-color font-size-4 font-weight-normal" href="https://slabcorp.co.jp/" target="_blank">-->
<!--                            <h3 class="text-white mb-0">運営会社&nbsp;<i class="icofont-home ps-3"></i></h3>-->
<!--                        </a>-->
<!--                    </div>-->
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-6">
            <div class="row">
                <!-- col 2-2 -->
                <div class="col-lg-3 col-md-6 col-sm-3 col-xs-6 pt-20 col-6">
                    <div class="footer-widget widget3 mb-sm-0 mb-13 text-center">
                        <p class="widget-title font-size-4  text-white mb-md-8 mb-7"></p>
                        <ul class="widget-links pl-0 list-unstyled list-hover-primary">
                            <li class="mb-6">
                                <a class="heading-default-color font-size-4 font-weight-normal" href="/term">
                                    <span class="text-white">利用規約</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- col 2-2 -->
                <div class="col-lg-3 col-md-6 col-sm-3 col-xs-6 pt-20 col-6">
                    <div class="footer-widget widget4 mb-sm-0 mb-13 text-center">
                        <p class="widget-title font-size-4  text-white mb-md-8 mb-7"></p>
                        <ul class="widget-links pl-0 list-unstyled list-hover-primary">
                            <li class="mb-6">
                                <a class="heading-default-color font-size-4 font-weight-normal" href="/personal-protection">
                                    <span class="text-white">情報取り扱いについて</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- col 2-3 -->
                <div class="col-lg-3 col-md-6 col-sm-3 col-xs-6 pt-20 col-6">
                    <div class="footer-widget widget3 mb-sm-0 mb-13 text-center">
                        <p class="widget-title font-size-4  text-white mb-md-8 mb-7"></p>
                        <ul class="widget-links pl-0 list-unstyled list-hover-primary">
                            <li class="mb-6">
                                <a class="heading-default-color font-size-4 font-weight-normal" href="/privacy">
                                    <span class="text-white">個人情報保護方針</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- col 2-4 -->
                <div class="col-lg-3 col-md-6 col-sm-3 col-xs-6 pt-20 col-6">
                    <div class="footer-widget widget4 mb-sm-0 mb-13 text-center">
                        <p class="widget-title font-size-4  text-white mb-md-8 mb-7"></p>
                        <ul class="widget-links pl-0 list-unstyled list-hover-primary">
                            <li class="mb-6">
                                <a class="heading-default-color font-size-4 font-weight-normal" href="/cookie-protection">
                                    <span class="text-white">Cookie等の利用について</span>
                                    </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <!-- col 1 -->
<!--            <div class="col-lg-4 col-sm-6 mb-3">-->
<!--                <div class="media mb-11">-->
<!--                    <div class="media-body pl-5">-->
<!--                        <p class="widget-title font-size-4 mb-0 text-white">運営会社 株式会社エスラボ</p>-->
<!--                        <a class="mb-0 font-size-4 footer-contact-mail" href="mailto:info@slabcorp.co.jp">info@slabcorp.co.jp</a>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
            <!-- col 2 -->
            <div class="col-lg-12">
                <p class="widget-links font-size-4 mb-0 text-white text-center copyright">Copyright ©2023 All rights reserved |
                    <a class="text-white" href="https://slabcorp.co.jp/" target="_blank">
                        sLab, Corp.
                    </a>
                </p>
            </div>
        </div>
    </div>
</footer>
