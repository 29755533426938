<section class="section pt-120 pb-100">
    <div class="container web-article">
        <div class="row">
            <div class="col-12 mt-md-0 mt-5">
                <h1 class="text-uppercase title my-2">個人情報保護方針</h1>
            </div>
            <div class="col-lg-12 mt-4 h5t-2">
                <h5>制定日：2022年11月1日   最終改定日：2022年11月1日</h5>
                <p class="text-muted">　当サイトのJobinder運営者である株式会社エスラボ（以下「当社」という。）は、事業において取扱う個人情報の保護について、社会的責任を十分に認識して、本人の権利利益を保護し、個人情報に関する法規制等を遵守いたします。</p>
                <p class="text-muted">また、以下に示す方針を具現化するために、個人情報保護マネジメントシステムを構築し、最新のＩＴ技術の動向、社会的要請の変化、経営環境の変動等を常に認識しながら、その継続的な改善に、全社を挙げて取り組むことをここに宣言いたします。</p>
                <p class="text-muted">ａ）当社事業、並びに従業員の雇用、人事管理等において取扱う個人情報について、予め特定された利用目的の範囲内において、個人情報の適切な取得・利用および提供を行い、利用目的の達成に必要な範囲を超えた個人情報の取扱い(目的外利用)は行いません。また、そのための適切な措置を講じます。</p>
                <p class="text-muted">ｂ）当社は個人情報の取扱いに関する法令、国が定める指針およびその他の規範を遵守いたします。</p>
                <p class="text-muted">ｃ）当社は個人情報への不正アクセス、個人情報の漏えい、滅失またはき損等のリスクに対しては、合理的な安全対策を講じ、事業の実情に合致した経営資源を注入し、個人情報のセキュリティ体制を継続的に向上させていきます。また不適切な事項については是正を行うなどの内部規程を定め、個人情報を最善の状態で保護いたします。</p>
                <p class="text-muted">ｄ）当社は個人情報の取扱いに関する苦情および相談対応への内部規程を定め、苦情および相談には、迅速かつ誠実に対応いたします。</p>
                <p class="text-muted">ｅ）個人情報保護マネジメントシステムについて、当社を取り巻く環境の変化を踏まえ、適時・適切に見直して、その改善を継続的に推進していきます。</p>
                <p class="text-muted">ｆ）当社が保有する求職登録者の個人情報はご本人同意の上、職業紹介事業者による照合の結果に基づいて職業紹介サービスに利用します。</p>
                <p class="text-muted">ｇ）当社は、適用法によってより長い保持期間が義務づけられている場合を除いて、このプライバシーポリシーに記載した用途に従事するために必要な期間、個人情報を保持します。</p>
                <p class="text-muted second-line-pl">当社の保有期間を決定するための基準は以下の通りです。</p>
                <p class="text-muted second-line-pl">・当社が利用者と継続的な関係を持ち、利用者にJobinderシステムサービス及び情報提供をしている機関（例えば、利用者が当社のアカウントを持っているか、またはJobinderシステムサービスを使い続けている限りにおいて）。</p>
                <p class="text-muted second-line-pl">・そのアカウントを通じて、アカウントオーナーが情報を修正するか、そのユーザーから情報の削除要請があり削除するまで。</p>
                <p class="text-muted second-line-pl">・当社が、データを保有する法的義務を有しているかどうか（例えば、特定の法律では、トランザクションを削除する前に、トランザクションのレコーディングを一定期間保有する必要がある）</p>
                <p class="text-muted second-line-pl">または当社の法的立場（契約の履行、紛争の解決、時効の適用、訴訟、規制当局の調査など）に照らして保持が望ましいかどうか。</p>
                <p class="text-muted">ｈ）当社が取得した個人情報についてご本人が開示、訂正、追加、削除等を希望される場合は、個人情報保護方針に定める「お客様対応窓口」までご連絡いただけますようお願い申し上げます。</p>
                <p class="text-muted second-line-pl">お問合せの際にご本人であるかについて、確認させていただきますのであらかじめご了承ください。但し、法令上認められる場合はこの限りではありません。</p>
                <p class="text-muted">ｉ）個人情報の提供について当社は、原則として、利用者の同意のない限り、第三者に個人情報を提供することはありません。</p>
                <p class="text-muted second-line-pl">同意した頂いた場合、Jobinder NETWORK契約者（有料職業紹介事業者）及び法令に従った個人情報取扱合意締結者の第三者に適切な管理の基に情報提供する可能性があります。</p>
                <p class="text-muted second-line-pl">ただし、以下の場合には、利用者の同意なく個人情報を提供することがあります。</p>
                <p class="text-muted second-line-pl">・ 法令に基づく場合</p>
                <p class="text-muted second-line-pl">・ 人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</p>
                <p class="text-muted second-line-pl">・ 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき</p>
                <p class="text-muted second-line-pl">・ 国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることによって当該事務の遂行に支障を及ぼすおそれがあるとき</p>
                <p class="text-muted second-line-pl">当社は前条の利用目的を達成するため、本人の同意を得たうえで個人情報を外国の第三者に提供する可能性があります。</p>
                <p class="text-muted">ｊ）個人情報の委託について、当社は事業運営上、業務委託先に個人情報の取り扱いを委託することがあります。この場合、当社は、個人情報を適切に保護できる管理体制を敷き実行していることを条件として委託先を厳選した上で、 契約等において個人情報の適正管理・機密保持などにより利用者の個人情報の漏洩防止に必要な事項を取決め、適切な管理を実施させます。</p>
                <p class="text-muted">ｋ）第三者提供に関する免責事項について、以下の場合は第三者による個人情報の取得に関し、当社は何らの責任を負いません。</p>
                <p class="text-muted second-line-pl">・ 利用者自らが本システムの機能または別の手段を用いて事業者、求人企業等の第三者に個人情報を明らかにする場合</p>
                <p class="text-muted second-line-pl">・ 利用者の活動情報または利用者が本システムの利用に関して投稿等した情報により、第三者に利用者本人が特定されるにいたった場合</p>
                <p class="text-muted second-line-pl">・ 本システムからリンクされる外部サイトにおいて、利用者より個人情報が提供され、またそれが利用された場合</p>
                <p class="text-muted second-line-pl">・ 利用者本人以外が利用者個人を識別できる情報（ID、パスワード等）を入手した場合</p>
                <p class="text-muted second-line-pl">・ 当社に責めに帰すべき事由がない場合</p>
                <p class="text-muted">ｌ）利用者の追加情報の取得について、当社は、当社の事業の円滑な運営の目的で、当社が利用者の個人情報を提供した事業者から、利用者の追加の個別的同意なくして、内定の事実、入社予定会社、入社予定日、予定年収その他、本システムの利用に関する利用者の追加情報を取得することがあります。</p>
                <p class="text-muted">ｍ）統計処理されたデータの利用について、当社は提供を受けた個人情報をもとに、個人を特定できないよう加工した統計データを作成することがあります。</p>
                <p class="text-muted second-line-pl">個人を特定できない統計データについては、当社は何ら制限なく利用することができるものとします。</p>
                <p class="text-muted">ｎ）本人確認について、当社は利用者の会員登録の場合やJobinder会員が本システムを利用する場合、個人情報の開示、訂正、削除もしくは利用停止の求めに応じる場合など、個人を識別できる情報（氏名、電話番号、メールアドレス、パスワード等）により、本人であることを確認します。ただし、本人以外が個人を識別できる情報を入手し使用した場合、当社は責任を負いません。</p>
                <p class="text-muted">ｏ）個人を特定しない属性情報・行動履歴の取得及び利用について、当社は利用者のプライバシーの保護、利便性の向上、広告の配信、及び統計データの取得のため、Cookieを使用します。また、当社は、CookieやJavaScript等の技術を利用して、会員登録時等にご提供いただいた情報のうち年齢や性別、職業、居住地域など個人が特定できない属性情報（組み合わせることによっても個人が特定できないものに限られます）や、サイト内におけるユーザーの行動履歴（アクセスしたURL、コンテンツ、参照順等）を取得することがあります。ただし、Cookie及び行動履歴には個人情報は一切含まれません。なお、Cookieの受け取りは、ブラウザの設定によって拒否することができます。拒否した場合本システムをご利用いただく上で、一部機能に制約が生じることがあります。</p>
                <p class="text-muted">ｐ）開示、訂正、利用停止等の求めに応じる手続きについて、利用者からの個人情報の利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止（以下「開示等」といいます。）の求めにつき、当社は、当社の保有する個人情報については、利用者の本人確認をおこなった後、合理的な範囲で速やかに対応いたします。ただし、他の利用者その他第三者の生命・身体・財産その他の利益を害するおそれのある場合、当社の業務遂行に著しく支障をきたすと判断した場合、対応することが法令に違反する場合には、この限りではありません。開示等の請求を行う場合、詳細な手続きについては下記の問い合わせ先までご連絡ください。開示等の手続きにかかる費用は無償とさせていただいています。</p>
                <p class="text-muted">ｑ）「個人情報保護方針」「個人情報の取り扱いについて」の変更について、当社は「個人情報保護方針」「個人情報の取り扱いについて」を法令に違反しない範囲で任意に変更することが出来るものとします。</p>
                <p class="text-muted">ｒ）当社は、個人情報に関する苦情及び相談への対応窓口を設置し迅速かつ適切な対応に努めます。</p>
                <p class="text-muted">「個人情報保護方針」と「個人情報の取扱い」に関する問い合わせ先は、次の通りです。</p>
                <p class="text-muted">詳細については、<a href="/privacy" target="_blank">プライバシーポリシー</a>をご覧ください。</p>
                <p class="text-muted">本方針は、全役員並びに従業員に配布して周知徹底させるとともに、いつでも、どなた様でも入手可能な措置を講ずるものとします。</p>
                <br>
                <p class="text-muted">個人情報お問合せ窓口 ／ Email：info@slabcorp.co.jp</p>
            </div>
        </div>

    </div>
</section>
