<!--<div class="page-title-area">-->
<!--  <div class="d-table">-->
<!--    <div class="d-table-cell">-->
<!--      <div class="container">-->
<!--        <div class="page-title-text">-->
<!--          <h2>求人リスト</h2>-->
<!--          <ul>-->
<!--            <li><a routerLink="/">ホーム</a></li>-->
<!--            <li><i class="icofont-simple-right"></i></li>-->
<!--            <li>求人リスト</li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<div class="cover-shadow" #coverShadow>

</div>
<section class="job-area job-area-two pt-120 pt-80-sp">
  <div class="container">
    <div style="height: 100vh" *ngIf="!loaded">
    </div>
    <div class="row" *ngIf="loaded">
      <ng-container *ngIf="totalCount == 0">
        <div class="col-6">
          <img src="assets/img/search_confuse.jpg" class="w-100">
        </div>
        <div class="col-6 align-self-center">
          <p>検索条件に一致するのデータが見つかりませんでいた。</p>
          <p>検索条件を変更して、もう一度お試しください。</p>
        </div>
      </ng-container>
      <ng-container *ngIf="totalCount != 0">
        <ng-container *ngFor="let project of projectList">
          <div class="col-lg-4">
            <div class="job-item">
              <div class="job-inner align-items-center">
                <div class="position-absolute project-list-like-action">
                  <ng-container *ngIf="!project.isLike">
                    <a (click)="like(project.uuid)"
                       matTooltip="お気に入り"
                       matTooltipPosition="right"
                       class="cursor-pointer">
                      <mat-icon class="gray-color3">favorite</mat-icon>
                    </a>
                  </ng-container>
                  <ng-container *ngIf="project.isLike">
                    <a (click)="like(project.uuid)"
                       matTooltip="取り消し"
                       matTooltipPosition="right"
                       class="cursor-pointer">
                      <mat-icon class="danger-color">favorite_border</mat-icon>
                    </a>
                  </ng-container>
                </div>

                <div class="entry-tag" *ngIf="project.isEntry" (click)="toEntry()">
                  応募済
                </div>

                <div class="job-inner-left">
                  <span class="cursor-pointer" (click)="goToClientDetail(project.agentUuid, $event)">{{project.agentName}}</span>
                  <h3 class="text-ellipsis-1 h-title cursor-pointer main-color"
                      (click)="goToDetail(project.uuid, $event)">
                    <label class="project-new" *ngIf="project.isRecent">NEW</label>
                    {{project.title}}</h3>
                </div>
                <div class="row mx-0 col-12 p-2 cursor-pointer w-100" (click)="goToDetail(project.uuid, $event)">
                  <ul>
                    <li>
                      <div class="row mx-0">
                        <div class="d-flex col-5 client-details-info-header">
                          <i class="icofont-yen-true ps-3"></i>
                          <div>月額</div>
                        </div>
                        <div class="col-7 client-details-info-body client-details-info-body-salary ps-3">
                          <div>{{format(project.price)}}円〜</div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="row mx-0">
                        <div class="d-flex col-5 client-details-info-header">
                          <i class="icofont-sand-clock ps-3"></i>
                          <div>経験年数</div>
                        </div>
                        <div class="col-7 client-details-info-body ps-3">
                          <div *ngIf="project.experience != null && project.experience > 0"
                               style="padding-top: 1px">{{project.experience}}年〜</div>
                          <div *ngIf="project.experience == null || project.experience <= 0"
                               style="padding-top: 1px">年数不問</div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="row mx-0">
                        <div class="d-flex col-5 client-details-info-header">
                          <i class="icofont-tags ps-3"></i>
                          <div>キーワード</div>
                        </div>
                        <div class="col-7 client-details-info-body ps-3">
                          <ng-container *ngIf="project.tagList != null && project.tagList.length != 0">
                            <ng-container *ngFor="let tag of project.tagList">
                              <div class="project-list-tag">{{tag.content}}</div>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="project.tagList == null || project.tagList.length == 0">
                            <div>--</div>
                          </ng-container>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>


                <!--                                <div class="job-inner-right">-->
                <!--                                    <ul>-->
                <!--                                        <li *ngIf="!project.isLike"><a (click)="like(project.uuid)" matTooltip="お気に入り" matTooltipPosition="right" class="cursor-pointer"><i class="icofont-heart-alt"></i></a></li>-->
                <!--                                        <li *ngIf="project.isLike"><a (click)="like(project.uuid)" matTooltip="取り消し" matTooltipPosition="right" class="cursor-pointer"><i class="icofont-verification-check"></i></a></li>-->
                <!--&lt;!&ndash;                                        <li><a [routerLink]="'/project/detail/' + project.uuid" matTooltip="詳細" matTooltipPosition="right" class="cursor-pointer"><i class="icofont-justify-all"></i></a></li>&ndash;&gt;-->
                <!--                                    </ul>-->
                <!--                                </div>-->

                <!--                                <div class="border-top w-100" style="margin: 10px 3px;"></div>-->
                <div class="w-100 row mx-0">
                  <div class="col-10">
                    <div class="project-list-desc cursor-pointer" (click)="goToDetail(project.uuid, $event)" [innerText]="project.content">

                    </div>
                  </div>
                  <div class="col-2 category-img align-self-center">
                    <img *ngIf="project.category==null" src="assets/img/category/code.png" width="50" alt="Job">
                    <img *ngIf="project.category!=null && project.category==10" src="assets/img/category/java.png" width="50" alt="Job">
                    <img *ngIf="project.category!=null && project.category==20" src="assets/img/category/python.png" width="50" alt="Job">
                    <img *ngIf="project.category!=null && project.category==30" src="assets/img/category/react.png" width="50" alt="Job">
                    <img *ngIf="project.category!=null && project.category==40" src="assets/img/category/php.png" width="50" alt="Job">
                    <img *ngIf="project.category!=null && project.category==50" src="assets/img/category/ios.svg" width="50" alt="Job">
                    <img *ngIf="project.category!=null && project.category==60" src="assets/img/category/android.svg" width="50" alt="Job">
                    <img *ngIf="project.category!=null && project.category==70" src="assets/img/category/angular.svg" width="50" alt="Job">
                    <img *ngIf="project.category!=null && project.category==80" src="assets/img/category/net.png" width="50" alt="Job">
                    <img *ngIf="project.category!=null && project.category==90" src="assets/img/category/aws.svg" width="50" alt="Job">
                    <img *ngIf="project.category!=null && project.category==100" src="assets/img/category/vue.png" width="50" alt="Job">
                    <img *ngIf="project.category!=null && project.category==999" src="assets/img/category/code.png" width="50" alt="Job">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <div class="flex align-items-center justify-content-center" *ngIf="totalCount != null && totalCount != 0">
        <div class="flex-1">
          <p-paginator (onPageChange)="onPageChange($event)" [first]="offset" [rows]="limit" [totalRecords]="totalCount" [showFirstLastIcon]="true"></p-paginator>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="desktop-container">
  <nav class="settings-sidebar">
    <div class="sidebar-body">
      <a href="" class="settings-sidebar-toggler" (click)="toggleSettingsSidebar($event)">
        <i class="icofont-search-2" id="search-icon"></i>
        <i class="icofont-close" id="left-icon"></i>
        <br>
        <h3 class="text-white my-0 right-count" #countUp [countUp]="count" [options]="myOpts">0</h3>
        <h5 class="text-white my-0 right-count">件</h5>
      </a>
      <div class="row mx-0">
        <div class="col-12 col-sm-8 bg-white" style="border-radius: 5px">
          <p-scrollPanel [style]="{ width: '100%', height: '60vh' }">
            <p class="text-muted my-2">フリーワード:</p>
            <div class="mb-3 pb-3 px-2 border-bottom">
              <input type="text" class="form-control" style="width: 95%"
                     placeholder="例：案件内容、キーワードなど" [(ngModel)]="keyword"
                     name="keyword" (keyup)="onTagSearch()">
            </div>

            <p class="text-muted my-2">スキル:</p>

            <div>
              <div>
                <ng-container *ngFor="let tech of tagTechList | slice:0:(moreTech ? undefined: 30)">
                <span class="search-option-tech"
                      (click)="changeTagTech(tech)"
                      [ngClass]="{'search-option-tech-select': selectTagIdTechList.includes(tech.id)}">
                  {{tech.content}}
                </span>
                </ng-container>
              </div>

              <a *ngIf="tagTechList != null && tagTechList.length > 30" class=" p-2 main-color font-size-3 open-close"
                 (click)="moreTech = !moreTech">{{ moreTech ? 'もとに戻す':'もっと表示する' }}</a>
            </div>
            <div class="mb-3 pb-3 border-bottom"></div>

            <p class="text-muted my-2">職種:</p>
            <div>
              <div>
                <ng-container *ngFor="let position of tagPositionList | slice:0:(morePosition ? undefined: 30)">
          <span class="search-option-position"
                (click)="changeTagPosition(position)"
                [ngClass]="{'search-option-position-select': selectTagIdPositionList.includes(position.id)}">
            {{position.content}}
          </span>
                </ng-container>
              </div>

              <a *ngIf="tagPositionList != null && tagPositionList.length > 30" class=" p-2 main-color font-size-3 open-close"
                 (click)="morePosition = !morePosition">{{ morePosition ? 'もとに戻す':'もっと表示する' }}</a>
            </div>
            <div class="mb-3 pb-3 border-bottom"></div>

            <p class="text-muted my-2">エリア:</p>
            <div>
              <div>
                <ng-container *ngFor="let area of tagAreaList | slice:0:(moreArea ? undefined: 30)">
          <span class="search-option-area"
                (click)="changeTagArea(area)"
                [ngClass]="{'search-option-area-select': selectTagIdAreaList.includes(area.id)}">
            {{area.content}}
          </span>
                </ng-container>
              </div>

              <a *ngIf="tagAreaList != null && tagAreaList.length > 30" class=" p-2 main-color font-size-3 open-close"
                 (click)="moreArea = !moreArea">{{ moreArea ? 'もとに戻す':'もっと表示する' }}</a>
            </div>
            <div class="mb-3 pb-3 border-bottom"></div>

            <p class="text-muted my-2">こだわり:</p>
            <div>
              <div>
                <ng-container *ngFor="let chosen of tagChosenList | slice:0:(moreChosen ? undefined: 30)">
          <span class="search-option-chosen"
                (click)="changeTagChosen(chosen)"
                [ngClass]="{'search-option-chosen-select': selectTagIdChosenList.includes(chosen.id)}">
            {{chosen.content}}
          </span>
                </ng-container>
              </div>

              <a *ngIf="tagChosenList != null && tagChosenList.length > 30" class=" p-2 main-color font-size-3 open-close"
                 (click)="moreChosen = !moreChosen">{{ moreChosen ? 'もとに戻す':'もっと表示する' }}</a>
            </div>
            <div class="mb-3 pb-3 border-bottom"></div>

          </p-scrollPanel>
        </div>
        <div class="col-12 col-sm-4" style="display: grid;align-content: space-between;">
          <div class="w-100 text-center">
            <div class="mb-3 pb-3 border-bottom">
              <label class="text-muted">
                <h3 class="main-color" style="display: contents" #countUp [countUp]="count" [options]="myOpts">0</h3>
                案件が見つかりました！
              </label>
            </div>
            <div class="">
              <p class="text-muted my-2">
                あなたが選んだ検索条件:
              </p>
              <p class="text-muted font-size-3 text-left" *ngIf="selectTagIdTechList.length == 0 && selectTagIdPositionList.length == 0 && selectTagIdAreaList.length == 0 && selectTagIdChosenList.length == 0">
                タグとフリーワードを組み合わせて案件を検索すると、選択した情報がここに表示されます！
              </p>
              <ng-container *ngFor="let tag of selectTagTechList">
                <p-chip [label]="tag.content" [removable]="true" class="select-chip-tech" (onRemove)="changeTagTech(tag)"></p-chip>
              </ng-container>
              <ng-container *ngFor="let tag of selectTagPositionList">
                <p-chip [label]="tag.content" [removable]="true" class="select-chip-position" (onRemove)="changeTagPosition(tag)"></p-chip>
              </ng-container>
              <ng-container *ngFor="let tag of selectTagAreaList">
                <p-chip [label]="tag.content" [removable]="true" class="select-chip-area" (onRemove)="changeTagArea(tag)"></p-chip>
              </ng-container>
              <ng-container *ngFor="let tag of selectTagChosenList">
                <p-chip [label]="tag.content" [removable]="true" class="select-chip-chosen" (onRemove)="changeTagChosen(tag)"></p-chip>
              </ng-container>
            </div>
          </div>
          <div class="w-100 text-center">
            <a class="main-color font-size-3" (click)="clear()">
              条件クリア
            </a>
            <div class="text-center logout-button mb-0 pt-3">
              <a class="btn logout-btn" (click)="onClickSearch()"><i class="icofont-search"></i> 検索</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>
<div class="mobile-container">
  <nav class="settings-sidebar">
    <div class="sidebar-body">
      <a href="" class="settings-sidebar-toggler" (click)="toggleSettingsSidebar($event)" #toggleSearch>
        <i class="icofont-search-2" id="search-icon"></i>
        <i class="icofont-close" id="left-icon"></i>
        <br>
        <h3 class="text-white my-0 right-count" #countUp [countUp]="count" [options]="myOpts">0</h3>
        <h5 class="text-white my-0 right-count">件</h5>
      </a>
      <div class="row mx-0" #toggleSearchArea>
        <div class="col-12 col-sm-8 bg-white py-1" style="border-radius: 5px">
          <p-scrollPanel [style]="{ width: '100%', height: '40vh' }">
            <p class="text-muted my-1">フリーワード:</p>
            <div class="mb-1 pb-2 px-2 border-bottom">
              <input type="text" class="form-control" style="width: 95%;font-size: 11px"
                     placeholder="例：案件内容、キーワードなど" [(ngModel)]="keyword"
                     name="keyword" (keyup)="onTagSearch()">
            </div>

            <p class="text-muted my-2">スキル:</p>
            <div>
              <div>
                <ng-container *ngFor="let tech of tagTechList | slice:0:(moreTech ? undefined: 30)">
                <span class="search-option-tech"
                      (click)="changeTagTech(tech)"
                      [ngClass]="{'search-option-tech-select': selectTagIdTechList.includes(tech.id)}">
                  {{tech.content}}
                </span>
                </ng-container>
              </div>

              <a *ngIf="tagTechList != null && tagTechList.length > 30" class=" p-2 main-color font-size-3 open-close"
                 (click)="moreTech = !moreTech">{{ moreTech ? 'もとに戻す':'もっと表示する' }}</a>
            </div>
            <div class="mb-3 pb-3 border-bottom"></div>

            <p class="text-muted my-2">職種:</p>
            <div>
              <div>
                <ng-container *ngFor="let position of tagPositionList | slice:0:(morePosition ? undefined: 30)">
                <span class="search-option-position"
                      (click)="changeTagPosition(position)"
                      [ngClass]="{'search-option-position-select': selectTagIdPositionList.includes(position.id)}">
                  {{position.content}}
                </span>
                </ng-container>
              </div>

              <a *ngIf="tagPositionList != null && tagPositionList.length > 30" class=" p-2 main-color font-size-3 open-close"
                 (click)="morePosition = !morePosition">{{ morePosition ? 'もとに戻す':'もっと表示する' }}</a>
            </div>

            <div class="mb-3 pb-3 border-bottom"></div>

            <p class="text-muted my-2">エリア:</p>
            <div>
              <div>
                <ng-container *ngFor="let area of tagAreaList | slice:0:(moreArea ? undefined: 30)">
                <span class="search-option-area"
                      (click)="changeTagArea(area)"
                      [ngClass]="{'search-option-area-select': selectTagIdAreaList.includes(area.id)}">
                  {{area.content}}
                </span>
                </ng-container>
              </div>

              <a *ngIf="tagAreaList != null && tagAreaList.length > 30" class=" p-2 main-color font-size-3 open-close"
                 (click)="moreArea = !moreArea">{{ moreArea ? 'もとに戻す':'もっと表示する' }}</a>
            </div>

            <div class="mb-3 pb-3 border-bottom"></div>

            <p class="text-muted my-2">こだわり:</p>
            <div>
              <div>
                <ng-container *ngFor="let chosen of tagChosenList | slice:0:(moreChosen ? undefined: 30)">
                <span class="search-option-chosen"
                      (click)="changeTagChosen(chosen)"
                      [ngClass]="{'search-option-chosen-select': selectTagIdChosenList.includes(chosen.id)}">
                  {{chosen.content}}
                </span>
                </ng-container>
              </div>

              <a *ngIf="tagChosenList != null && tagChosenList.length > 30" class=" p-2 main-color font-size-3 open-close"
                 (click)="moreChosen = !moreChosen">{{ moreChosen ? 'もとに戻す':'もっと表示する' }}</a>
            </div>
            <div class="mb-3 pb-3 border-bottom"></div>
          </p-scrollPanel>
        </div>
        <div class="col-12 col-sm-4 py-1" style="display: grid;align-content: space-between;">
          <div class="w-100 text-center">

            <div class="mb-1 pb-1 border-bottom">
              <label class="text-muted">
                <h3 class="main-color" style="display: contents" #countUp [countUp]="count" [options]="myOpts">0</h3>
                案件が見つかりました！
              </label>

            </div>
            <div class="">
              <p class="text-muted my-1">
                あなたが選んだ検索条件:
              </p>

              <p-scrollPanel [style]="{ width: '100%', height: '20vh' }">
                <p class="text-muted font-size-3 text-left" *ngIf="selectTagIdTechList.length == 0 && selectTagIdPositionList.length == 0 && selectTagIdAreaList.length == 0 && selectTagIdChosenList.length == 0">
                  タグとフリーワードを組み合わせて案件を検索すると、選択した情報がここに表示されます！
                </p>
                <ng-container *ngFor="let tag of selectTagTechList">
                  <p-chip [label]="tag.content" [removable]="true" class="select-chip-tech" (onRemove)="changeTagTech(tag)"></p-chip>
                </ng-container>
                <ng-container *ngFor="let tag of selectTagPositionList">
                  <p-chip [label]="tag.content" [removable]="true" class="select-chip-position" (onRemove)="changeTagPosition(tag)"></p-chip>
                </ng-container>
                <ng-container *ngFor="let tag of selectTagAreaList">
                  <p-chip [label]="tag.content" [removable]="true" class="select-chip-area" (onRemove)="changeTagArea(tag)"></p-chip>
                </ng-container>
                <ng-container *ngFor="let tag of selectTagChosenList">
                  <p-chip [label]="tag.content" [removable]="true" class="select-chip-chosen" (onRemove)="changeTagChosen(tag)"></p-chip>
                </ng-container>
              </p-scrollPanel>
            </div>
          </div>
          <div class="w-100 text-center">
            <a class="main-color font-size-3" (click)="clear()">
              条件クリア
            </a>
            <div class="text-center logout-button mb-0 pt-2">
              <a class="btn logout-btn" (click)="onClickSearch()"><i class="icofont-search"></i> 検索</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>

<!--<div class="">-->

<!--  <mat-expansion-panel class="search-card search-card-mat-expansion-panel">-->
<!--    <mat-expansion-panel-header class="search-card-mat-expansion-panel-header">-->
<!--      <mat-panel-title class="search-card-mat-panel-title">-->
<!--        検索結果数：{{totalCount}}-->
<!--      </mat-panel-title>-->
<!--    </mat-expansion-panel-header>-->

<!--    <div class="sidebar-body-container">-->
<!--      <ul class="sidebar-body-ul">-->
<!--        <li class="sidebar-body-li">-->
<!--          <label>タイトル</label>-->
<!--          <input [(ngModel)]="title" name="title" placeholder="案件名">-->
<!--        </li>-->
<!--        <li class="sidebar-body-li">-->
<!--          <label>地域</label>-->
<!--          <input [(ngModel)]="area" name="area" placeholder="勤務地">-->
<!--        </li>-->
<!--        <li class="sidebar-body-li">-->
<!--          <label>カテゴリー</label>-->
<!--          <div class="category-select">-->
<!--            <select [(ngModel)]="category" name="category">-->
<!--              <option *ngFor="let item of categoryItems | keyvalue"-->
<!--                      [value]="item.key">-->
<!--                {{item.value}}-->
<!--              </option>-->
<!--            </select>-->
<!--          </div>-->
<!--        </li>-->
<!--        <li class="clear-condition sidebar-body-li">-->
<!--          <a (click)="clear()">全ての条件を消す</a>-->
<!--        </li>-->
<!--        <div class="text-left logout-button mb-0 pt-3">-->
<!--          <a class="btn logout-btn" (click)="getSearchData()"><i class="icofont-search"></i> 検索</a>-->
<!--        </div>-->
<!--      </ul>-->
<!--    </div>-->

<!--  </mat-expansion-panel>-->
<!--</div>-->
<!--<nav class="settings-sidebar">-->
<!--  <div class="sidebar-body">-->
<!--    <a href="" class="settings-sidebar-toggler" (click)="toggleSettingsSidebar($event)">-->
<!--      <i class="icofont-search-job" id="search-icon"></i>-->
<!--      <i class="icofont-rounded-double-right" id="left-icon"></i>-->
<!--    </a>-->
<!--  </div>-->
<!--</nav>-->

