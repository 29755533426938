import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../../service/auth.service";
import {CookieService} from "../../../service/cookie.service";
import {DropdownOption, ExYear, Process, ProfileForm, Skill, TechTag} from "../../../form/profile";
import {ProfileService} from "../../../service/profile.service";
import {environment} from "../../../../environments/environment";
import {ActivatedRoute, Router} from "@angular/router";
import {StatusCode} from "../../../form/status-code";
import {HeaderService} from "../../../service/header.service";
import {CommentSave, ProjectList, TagSearchForm} from "../project-list/project-list.model";
import {ProjectService} from "../../../service/project.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  profile = new ProfileForm();

  skillList: Skill[] = [];

  techTagList: TechTag[] = [];

  processList: Process[] = [];

  yearList: ExYear[] = [
    {id:1, content: "1年"},
    {id:2, content: "2年"},
    {id:3, content: "3年"},
    {id:4, content: "4年"},
    {id:5, content: "5年"},
    {id:6, content: "6年"},
    {id:7, content: "7年"},
    {id:8, content: "8年"},
    {id:9, content: "9年"},
    {id:10, content: "10年〜"}
  ];

  projectLikeList: ProjectList[] = [];

  projectEntryList: ProjectList[] = [];

  // Like　Page設定
  limit = 10;
  offset = 0;
  currentPage = 1;
  totalCount = 0;
  pageCount = 1;

  haveLike = true;

  // 応募　Page設定
  limitEntry = 6;
  offsetEntry = 0;
  totalCountEntry = 0;

  haveEntry = true;

  temp!: File;
  resumeName!: string;
  careerName!: string;

  localhost = environment.API_BASE;

  displayBasic2: boolean;

  commentForm = new CommentSave();

  isProfile = true;
  isSkill = false;
  isLike = false;
  isEntry = false;

  currentMenu: string;

  items = [
    {
      label: '基本情報',
      icon: 'pi pi-fw pi-user',
      command: (event) =>{
        this.router.navigate(['/profile',{page: 'isProfile'}]).then(() => {
          window.location.reload();
          window.scrollTo(0, 0);
        });
      },
      // routerLink: ['/profile'],
      // queryParams: {'page': 'isProfile'}
    },
    {
      separator:true
    },
    {
      label: 'スキル情報',
      icon: 'pi pi-fw pi-thumbs-up',
      command: (event) =>{
        this.router.navigate(['/profile',{page: 'isSkill'}]).then(() => {
          window.location.reload();
          window.scrollTo(0, 0);
        });
      },
      // routerLink: ['/profile'],
      // queryParams: {'page': 'isSkill'}
    },
    {
      separator:true
    },
    {
      label: 'お気に入り',
      icon: 'pi pi-fw pi-heart',
      command: (event) =>{
        this.router.navigate(['/profile',{page: 'isLike'}]).then(() => {
          window.location.reload();
          window.scrollTo(0, 0);
        });
      },
      // routerLink: ['/profile'],
      // queryParams: {'page': 'isLike'}
    },
    {
      separator:true
    },
    {
      label: '応募履歴',
      icon: 'pi pi-fw pi-history',
      command: (event) =>{
        this.router.navigate(['/profile',{page: 'isEntry'}]).then(() => {
          window.location.reload();
          window.scrollTo(0, 0);
        });
      },
      // routerLink: ['/profile'],
      // queryParams: {'page': 'isEntry'}
    }
  ];

  countryItems = new Map([
    [0, "--"],
    [1, "日本"],
    [2, "中国"],
    [3, "その他"],
  ]);

  genderItems = new Map([
    [0, "--"],
    [10, "男性"],
    [20, "女性"],
    [30, "その他"],
  ]);

  gender: DropdownOption;
  genderList: DropdownOption[] =[
    {id:0, content: "--"},
    {id:10, content: "男性"},
    {id:20, content: "女性"},
    {id:30, content: "その他"}
  ]

  country: DropdownOption;
  countryList: DropdownOption[] =[
    {id:0, content: "--"},
    {id:1, content: "日本"},
    {id:2, content: "中国"},
    {id:3, content: "その他"}
  ]

  currentStatus: DropdownOption;
  currentStatusList: DropdownOption[] =[
    {id:10, content: "現在転職したくない"},
    {id:20, content: "すぐ転職したい"},
    {id:30, content: "3ヶ月いない転職したい"},
    {id:40, content: "6ヶ月いない転職したい"},
    {id:50, content: "いいチャンスあれば転職を考える"}
  ]

  degree: DropdownOption;
  degreeList: DropdownOption[] =[
    {id:10, content: "大学院博士"},
    {id:20, content: "大学院修士"},
    {id:30, content: "大学"},
    {id:40, content: "短期大学"},
    {id:50, content: "高等専門学校"},
    {id:60, content: "専門学校"},
    {id:70, content: "高等学校"},
    {id:80, content: "中学校"},
    {id:90, content: "その他"}
  ]

  currentStatusItems = new Map([
    [10, "現在転職したくない"],
    [20, "すぐ転職したい"],
    [30, "3ヶ月いない転職したい"],
    [40, "6ヶ月いない転職したい"],
    [50, "いいチャンスあれば転職を考える"],
  ]);

  constructor(
      private authService: AuthService,
      private cookieService: CookieService,
      private profileService: ProfileService,
      public router: Router,
      private statusCode: StatusCode,
      private headerService: HeaderService,
      private activatedRoute: ActivatedRoute,
      private projectService: ProjectService
  ) {
  }

  ngOnInit(): void {
    // search from home
    window.scrollTo(0, 0);
    this.activatedRoute.params.subscribe((data) => {
      console.log(data)
      let page = data['page'];
      if(page != null && page != '' && (page == 'isProfile' || page == 'isSkill' || page == 'isLike' || page == 'isEntry')){
        this.isProfile = page == 'isProfile';
        this.isSkill = page == 'isSkill';
        this.isLike = page == 'isLike';
        this.isEntry = page == 'isEntry';
      }else{
        this.isProfile = true;
        this.isSkill = false;
        this.isLike = false;
        this.isEntry = false;
      }

      if(this.isProfile){
        this.currentMenu = "基本情報"
      }else if(this.isSkill){
        this.currentMenu = "スキル情報"
      }else if(this.isLike){
        this.currentMenu = "お気に入り"
      }else if(this.isEntry){
        this.currentMenu = "応募履歴"
      }else{
        this.currentMenu = "基本情報"
      }
    });
    this.getProfileInfo();
    this.getLikeData();
    this.getEntryData();
  }

  getProfileInfo(): void{

    const jb_user = this.cookieService.getCookie('jb_user');

    if(jb_user){
      this.authService.getProfile().subscribe(res => {
        if (res.code === this.statusCode.SUCCESS) {
          this.profile = res.data.profileForm;
          // this.profile.resume = res.data.profileForm.resumeUrl;
          if(this.profile.resume != null && this.profile.resume != ''){
            this.resumeName = this.profile.resume.split("/jobinder/")[1].substring(4);
          }
          // this.profile.career = res.data.profileForm.careerUrl;
          if(this.profile.career != null && this.profile.career != ''){
            this.careerName = this.profile.career.split("/jobinder/")[1].substring(4);
          }
          if(this.profile.gender != null){
            for(let i = 0; i < this.genderList.length; i++){
              if(this.genderList[i].id == this.profile.gender){
                this.gender = this.genderList[i];
              }
            }
          }
          if(this.profile.country != null){
            for(let i = 0; i < this.countryList.length; i++){
              if(this.countryList[i].id == this.profile.country){
                this.country = this.countryList[i];
              }
            }
          }
          if(this.profile.currentStatus != null){
            for(let i = 0; i < this.currentStatusList.length; i++){
              if(this.currentStatusList[i].id == this.profile.currentStatus){
                this.currentStatus = this.currentStatusList[i];
              }
            }
          }
          if(this.profile.degree != null){
            for(let i = 0; i < this.degreeList.length; i++){
              if(this.degreeList[i].id == this.profile.degree){
                this.degree = this.degreeList[i];
              }
            }
          }
          // this.profile.jb_user = jb_user;
          // if(this.profile.gender != null){
          //   const genderSelect = document.getElementsByClassName("gender-select")[0];
          //   const genderNiceSelect = genderSelect.getElementsByClassName("nice-select")[0];
          //   const genderValueItems = genderNiceSelect.getElementsByClassName("option");
          //   const genderCurrentItem = genderNiceSelect.getElementsByClassName("current")[0];
          //   for(let i=0;i<genderValueItems.length;i++){
          //     if(Number(genderValueItems[i].attributes.getNamedItem("data-value").value) == this.profile.gender){
          //       genderValueItems[i].classList.add("selected");
          //       genderCurrentItem.textContent = this.genderItems.get(this.profile.gender);
          //     }
          //   }
          // }
          // if(this.profile.country != null){
          //   const countrySelect = document.getElementsByClassName("country-select")[0];
          //   const countryNiceSelect = countrySelect.getElementsByClassName("nice-select")[0];
          //   const countryValueItems = countryNiceSelect.getElementsByClassName("option");
          //   const countryCurrentItem = countryNiceSelect.getElementsByClassName("current")[0];
          //   for(let i=0;i<countryValueItems.length;i++){
          //     if(Number(countryValueItems[i].attributes.getNamedItem("data-value").value) == this.profile.country){
          //       countryValueItems[i].classList.add("selected");
          //       countryCurrentItem.textContent = this.countryItems.get(this.profile.country);
          //     }
          //   }
          // }
          // if(this.profile.currentStatus != null){
          //   const currentStatusSelect = document.getElementsByClassName("current-status-select")[0];
          //   const currentStatusNiceSelect = currentStatusSelect.getElementsByClassName("nice-select")[0];
          //   const currentStatusValueItems = currentStatusNiceSelect.getElementsByClassName("option");
          //   const currentStatusCurrentItem = currentStatusNiceSelect.getElementsByClassName("current")[0];
          //   for(let i=0;i<currentStatusValueItems.length;i++){
          //     if(Number(currentStatusValueItems[i].attributes.getNamedItem("data-value").value) == this.profile.currentStatus){
          //       currentStatusValueItems[i].classList.add("selected");
          //       currentStatusCurrentItem.textContent = this.currentStatusItems.get(this.profile.currentStatus);
          //     }
          //   }
          // }
          //
          // if(this.profile.degree != null){
          //   const degreeSelect = document.getElementsByClassName("degree-select")[0];
          //   const degreeNiceSelect = degreeSelect.getElementsByClassName("nice-select")[0];
          //   const degreeValueItems = degreeNiceSelect.getElementsByClassName("option");
          //   const degreeCurrentItem = degreeNiceSelect.getElementsByClassName("current")[0];
          //   for(let i=0;i<degreeValueItems.length;i++){
          //     if(Number(degreeValueItems[i].attributes.getNamedItem("data-value").value) === this.profile.degree){
          //       degreeValueItems[i].classList.add("selected");
          //       degreeCurrentItem.textContent = this.degreeList.get(this.profile.degree);
          //     }
          //   }
          // // }else {
          // //   const degreeSelect = document.getElementsByClassName("degree-select")[0];
          // //   const degreeNiceSelect = degreeSelect.getElementsByClassName("nice-select")[0];
          // //   const degreeCurrentItem = degreeNiceSelect.getElementsByClassName("current")[0];
          // //   degreeCurrentItem.classList.add("placeholder-nice-select");
          // //   degreeCurrentItem.textContent = '最終学歴';
          // }

          this.getSkillInfo();
        }else{
          this.router.navigate(['/login']);
        }
      });
    }else{
      this.router.navigate(['/login']);
    }

  }

  getSkillInfo(): void{
    this.profileService.getSkillList().subscribe( res => {
      if (res.code === this.statusCode.SUCCESS) {
        this.skillList = res.data.skillList;
        this.techTagList = res.data.techTagList;
        this.processList = res.data.positionTagList;
        // if(res.data.processList != null){
        //   for(let i=0;i<res.data.processList.length;i++){
        //     let s = new Process();
        //     s.id = Number(Object.keys(res.data.processList[i]));
        //     s.content = Object.values(res.data.processList[i]).toString();
        //     this.processList.push(s);
        //   }
        // }
      }
    })
  }

  profileSave(): void{
    // const genderSelect = document.getElementsByClassName("gender-select")[0];
    // const genderValueItems = genderSelect.getElementsByClassName("option");
    // for(let i=0;i<genderValueItems.length;i++){
    //   if(genderValueItems[i].classList.contains("selected")){
    //     const a = genderValueItems[i];
    //     // alert(a.attributes.getNamedItem("data-value").value)
    //     this.profile.gender = Number(a.attributes.getNamedItem("data-value").value);
    //   }
    // }
    // const countrySelect = document.getElementsByClassName("country-select")[0];
    // const countryValueItems = countrySelect.getElementsByClassName("option");
    // for(let i=0;i<countryValueItems.length;i++){
    //   if(countryValueItems[i].classList.contains("selected")){
    //     const a = countryValueItems[i];
    //     // alert(a.attributes.getNamedItem("data-value").value)
    //     this.profile.country = Number(a.attributes.getNamedItem("data-value").value);
    //   }
    // }
    // const currentStatusSelect = document.getElementsByClassName("current-status-select")[0];
    // const currentStatusValueItems = currentStatusSelect.getElementsByClassName("option");
    // for(let i=0;i<currentStatusValueItems.length;i++){
    //   if(currentStatusValueItems[i].classList.contains("selected")){
    //     const a = currentStatusValueItems[i];
    //     // alert(a.attributes.getNamedItem("data-value").value)
    //     this.profile.currentStatus = Number(a.attributes.getNamedItem("data-value").value);
    //   }
    // }
    //
    // const degreeSelect = document.getElementsByClassName("degree-select")[0];
    // const degreeValueItems = degreeSelect.getElementsByClassName("option");
    // for(let i=0;i<degreeValueItems.length;i++){
    //   if(degreeValueItems[i].classList.contains("selected")){
    //     const a = degreeValueItems[i];
    //     // alert(a.attributes.getNamedItem("data-value").value)
    //     this.profile.degree = Number(a.attributes.getNamedItem("data-value").value);
    //   }
    // }
    this.profile.gender = this.gender.id;
    this.profile.country = this.country.id;
    this.profile.currentStatus = this.currentStatus.id;
    this.profile.degree = this.degree.id;
    this.profileService.postProfile(this.profile).subscribe( res =>{
      if(res.code == 0){
        Swal.fire({
          title: 'Success!',
          text: '成功に保存しました。',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 2000
        }).then(() => window.location.reload());
      }else{
        Swal.fire({
          title: 'Error!',
          text: '入力情報を確認してください！',
          icon: 'error',
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: '閉じる'
        })
      }
    })
  }

  // add file
  addFile(event: any, num: number): void {
    if (num === 1) { // 履歴書
      this.profile.resume = event.files[0];
    } else { // 職務経歴
      this.profile.career = event.files[0];
    }
  }

  // clear file
  clearFile(num: number): void {
    if (num === 1) { // 履歴書
      this.profile.resume = "";
    } else { // 職務経歴
      this.profile.career = "";
    }
  }

  setFileUrl(event: any, num: number) {
    if (num === 1) { // 履歴書
      this.profile.resume = event.originalEvent.body.data.url;
      this.resumeName = event.files[0].name;
    } else { // 職務経歴
      this.profile.career = event.originalEvent.body.data.url;
      this.careerName = event.files[0].name;
    }
  }

  logout(): void {
    this.authService.logout();
  }

  skillAdd(): void{
    this.skillList.push(new Skill())
  }

  skillSave(): void{
    this.profileService.postSkillList(this.skillList).subscribe( r=> {
      if(r.code == 0){
        Swal.fire({
          title: 'Success!',
          text: '成功に保存しました。',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 2000
        }).then(() => window.location.reload());
      }else{
        Swal.fire({
          title: 'Error!',
          text: '入力情報を確認してください！',
          icon: 'error',
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: '閉じる'
        })
      }
    })
  }

  skillRemove(index: number): void{
    this.skillList.splice(index, 1)
  }

  getLikeData() {
    this.projectService.getProjectLikeList(this.limit, this.offset).subscribe(res => {
      if(res.code == 0 && res.data.projectList != null && res.data.projectList.length != 0){
        this.projectLikeList = res.data.projectList;
        this.totalCount = res.data.likeCount;
        window.scrollTo(0, 0)
        if (this.totalCount >= 11) {
          this.pageCount = Math.ceil(res.data.likeCount / this.limit);
        } else {
          this.pageCount = 1;
        }
      }else{
        this.haveLike = false;
      }

    })
  }

  pager(page: number): void {
    window.scrollTo(0, 0)
    this.offset = 0;
    this.offset += page * this.limit;
    this.currentPage = page + 1;

    this.getLikeData();
  }

  prePage(): void {
    window.scrollTo(0, 0)
    this.offset = 0;
    this.offset += (this.currentPage - 2) * this.limit;
    this.currentPage = this.currentPage - 1;

    this.getLikeData();
  }

  nextPage(): void {
    window.scrollTo(0, 0)
    this.offset = 0;
    this.offset += this.currentPage * this.limit;
    this.currentPage = this.currentPage + 1;

    this.getLikeData();
  }

  firstPage(): void {
    window.scrollTo(0, 0)
    this.offset = 0;
    this.offset += 0 * this.limit;
    this.currentPage = 1;

    this.getLikeData();
  }

  lastPage(): void {
    window.scrollTo(0, 0)
    this.offset = 0;
    this.offset += (this.pageCount - 1) * this.limit;
    this.currentPage = this.pageCount;

    this.getLikeData();
  }

  like(uuid: string):void{
    const jb_user = this.cookieService.getCookie('jb_user');

    if(jb_user){
      this.projectService.like(uuid).subscribe( res => {
        // window.scrollTo(0, 0);
        if(res.code === 0){
          // window.location.reload();
          for(let i = 0; i < this.projectLikeList.length; i++){
            if(this.projectLikeList[i].uuid == uuid){
              this.projectLikeList[i].isLike = !this.projectLikeList[i].isLike;
            }
          }
        }
      })
    }else{
      this.router.navigate(['/login']);
    }
  }

  goToDetail(val: string, event: any) :void{
    if((event.ctrlKey && !event.metaKey) || (!event.ctrlKey && event.metaKey)){
      const url = this.router.serializeUrl(this.router.createUrlTree(['/project/detail/'+val]));
      window.open(url, '_blank');
    }else{
      this.router.navigate(['/project/detail/'+val]);
      //window.open(url);
    }
  }

  goToClientDetail(val: string, event: any) :void{
    if((event.ctrlKey && !event.metaKey) || (!event.ctrlKey && event.metaKey)){
      const url = this.router.serializeUrl(this.router.createUrlTree(['/client/detail/'+val]));
      window.open(url, '_blank');
    }else{
      this.router.navigate(['/client/detail/'+val]);
      //window.open(url);
    }
  }

  getEntryData() {
    this.projectService.getProjectEntryList(this.limitEntry, this.offsetEntry).subscribe(res => {
      if(res.code == 0 && res.data.entryList != null && res.data.entryList.length != 0){
        this.projectEntryList = res.data.entryList;
        this.totalCountEntry = res.data.entryCount;
        window.scrollTo(0, 0)
        // if (this.totalCountEntry >= 11) {
        //   this.pageCountEntry = Math.ceil(res.data.entryCount / this.limitEntry);
        // } else {
        //   this.pageCountEntry = 1;
        // }
      }else{
        this.haveEntry = false;
      }

    })
  }

  // pagerEntry(page: number): void {
  //   window.scrollTo(0, 0)
  //   this.offsetEntry = 0;
  //   this.offsetEntry += page * this.limitEntry;
  //   this.currentPageEntry = page + 1;
  //
  //   this.getEntryData();
  // }
  //
  // prePageEntry(): void {
  //   window.scrollTo(0, 0)
  //   this.offsetEntry = 0;
  //   this.offsetEntry += (this.currentPageEntry - 2) * this.limitEntry;
  //   this.currentPageEntry = this.currentPageEntry - 1;
  //
  //   this.getEntryData();
  // }
  //
  // nextPageEntry(): void {
  //   window.scrollTo(0, 0)
  //   this.offsetEntry = 0;
  //   this.offsetEntry += this.currentPageEntry * this.limitEntry;
  //   this.currentPageEntry = this.currentPageEntry + 1;
  //
  //   this.getEntryData();
  // }
  //
  // firstPageEntry(): void {
  //   window.scrollTo(0, 0)
  //   this.offsetEntry = 0;
  //   this.offsetEntry += 0 * this.limitEntry;
  //   this.currentPageEntry = 1;
  //
  //   this.getEntryData();
  // }
  //
  // lastPageEntry(): void {
  //   window.scrollTo(0, 0)
  //   this.offsetEntry = 0;
  //   this.offsetEntry += (this.pageCountEntry - 1) * this.limitEntry;
  //   this.currentPageEntry = this.pageCountEntry;
  //
  //   this.getEntryData();
  // }

  showBasicDialog2(entryId: number) {
    this.displayBasic2 = true;
    this.commentForm.itemId = entryId;
  }

  sendMessage(): void{
    this.projectService.saveComment(this.commentForm).subscribe( r => {
      if(r.code == 0){
        window.location.reload()
      }
    })
  }

  entryEnd(entryId: number): void{
    this.profileService.updateEntryEnd(entryId).subscribe( r => {
      if(r.code == 0){
        window.location.reload()
      }
    })
  }

  entryRestart(entryId: number): void{
    this.profileService.restartEntryEnd(entryId).subscribe( r => {
      if(r.code == 0){
        window.location.reload()
      }
    })
  }

  format(amount: number): string{
    let amountJPY = "";

    amountJPY = Number(amount).toLocaleString("ja-JP")

    return amountJPY;
  }

  goToMenu(val: string): void{
    this.activatedRoute.queryParamMap
    this.router.navigate(['/profile',{page: val}]).then(() => {
      // window.location.reload();
      window.scrollTo(0, 0);
    });
  }

  onError(): void{
    alert(1)
  }

  onPageEntryChange(event): void {
    this.offsetEntry = event.first;
    this.limitEntry = event.rows;

    this.getEntryData();
  }

  onPageLikeChange(event): void {
    this.offset = event.first;
    this.limit = event.rows;

    this.getLikeData();
  }

  toEntry(): void{
    this.router.navigate(['/profile',{page: 'isEntry'}]).then(() => {
      window.location.reload();
      window.scrollTo(0, 0);
    });
  }
}
