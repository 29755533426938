<section class="section pt-120 pb-100">
    <div class="container web-article">
        <div class="row">
            <div class="col-12 mt-md-0 mt-5">
                <h1 class="text-uppercase title my-2">Cookie等の利用について</h1>
            </div>
            <div class="col-lg-12 mt-4 h5t-2">
                <h5>1.Cookieとは</h5>
                <p class="text-muted ps-4">Cookieとは、ウェブサイトの提供者が同サイトの閲覧者（以下「サイト閲覧者」といいます。）のブラウザを識別するために、サイト閲覧者のコンピュータに一次的に保存されるファイルのことです。Cookieが保存されることにより、サイト閲覧者のコンピュータからのサイト閲覧情報等をCookie発行者のサーバーに記録することができるようになります。</p>
                <p class="text-muted ps-4"></p>
                <h5>2.Cookie等により取得する情報について</h5>
                <p class="text-muted ps-4">当サイトにおいて当社は、Cookieまたは類似の技術を利用して、サイト閲覧者の閲覧したウェブサイト、検索キーワード、閲覧した広告及びそのクリック数に関する履歴、閲覧時間、使用しているブラウザの種類、IPアドレス、IPアドレスから判別できる範囲の企業情報等の情報（以下「閲覧履歴」といいます。）を取得しています。なお、これらの情報には「氏名」「電話番号」「メールアドレス」「生年月日」等の個人を特定する情報は含まれず、サイト閲覧者のプライバシーが侵害されることはありません。</p>
                <p class="text-muted ps-4"></p>
                <h5>3.利用目的</h5>
                <p class="text-muted ps-4">当社は、cookie等により収集したサイト閲覧者の閲覧履歴に関する情報を以下の目的のために利用しています。</p>
                <p class="text-muted ps-4"></p>
                <p class="text-muted ps-4">サイト閲覧者の利便性向上のため。</p>
                <p class="text-muted ps-4">サイト上での利用状況をもとに、最も適切な広告を当社または当社が提携する第三者のサイト上で表示するため。</p>
                <p class="text-muted ps-4">当社のサイトの利用者数やトラフィックを調査するため。</p>
                <p class="text-muted ps-4">当社のサービスを改善するため。</p>
                <p class="text-muted ps-4">当社のサービスの技術的な問題を解決するため。</p>
                <p class="text-muted ps-4">当社の顧客に商品・サービスを提案・提供するため。</p>
                <h5>4.Cookieの設定方法</h5>
                <p class="text-muted ps-4">サイト閲覧者はご自身で、ブラウザ設定を操作することで、Cookieの受け取りを拒否することや一旦受け取られたCookieを削除することも可能です。その場合でも一部の機能を除いてウェブサイトの閲覧に支障を来すことはございません。ブラウザの設定方法は各ソフト製造元へお問い合わせください。</p>
                <p class="text-muted ps-4"></p>
                <h5>5.閲覧履歴の提供先と提供目的</h5>
                <p class="text-muted ps-4">当社は以下の目的のため、当社サービスを利用する法人、広告配信事業者、広告会社、広告主等の取引先企業（以下、併せて「本件法人等」といいます）に対して、その目的に合わせた適切な方法により、閲覧履歴を提供する場合があります。</p>
                <p class="text-muted ps-4"></p>
                <p class="text-muted ps-4">本件法人等による、サイト閲覧者の趣味・嗜好等に合致した情報等の提供。</p>
                <p class="text-muted ps-4">本件法人等による、商品・サービスの開発、改善および提供。</p>
                <p class="text-muted ps-4">本件法人等による、商品・サービスの提案。</p>
                <h5>6.Google Analyticsの利用について</h5>
                <p class="text-muted ps-4">当サイトは、サイトの利用状況を把握するためにGoogle Analyticsを利用しています。Google Analyticsは、Cookieを利用してサイト閲覧者の情報を匿名で収集し、個々のサイト閲覧者を特定することなくウェブサイトの動向データを集計します。Google Analyticsの概要についてはGoogle Analyticsの下記のサイトをご覧ください。</p>
                <p class="text-muted"></p>
                <p class="text-muted">URL: <a href="https://policies.google.com/technologies/partner-sites?hl=ja" target="_blank">https://policies.google.com/technologies/partner-sites?hl=ja</a></p>
                <p class="text-muted"></p>
                <div class="mt-lg-20"></div>
            </div>
        </div>

    </div>
</section>
