import {Component, ElementRef, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ProjectService} from "../../../service/project.service";
import {ProjectList, TagSearchForm} from "./project-list.model";
import {ActivatedRoute, Router} from "@angular/router";
import {DOCUMENT} from "@angular/common";
import {CookieService} from "../../../service/cookie.service";
import {AuthService} from "../../../service/auth.service";
import {CountUpDirective} from "ngx-countup";

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit {
  @ViewChild('countUp') countUp: CountUpDirective;

  @ViewChild('coverShadow')coverShadow: ElementRef;

  isLogin!: boolean;

  loaded: boolean = false;

  // search
  title!: string;
  area!: string;
  category = 0;
  isSearch = false;
  categoryId: number;

  // Page設定
  limit = 15;
  offset = 0;
  // currentPage = 1;
  totalCount = 0;
  count = 0;
  // pageCount = 1;

  projectList: ProjectList[];

  tagTechList = [];
  tagPositionList = [];
  tagAreaList = [];
  tagChosenList = [];

  keyword: string = '';

  selectTagIdTechList = [];
  selectTagIdPositionList = [];
  selectTagIdAreaList = [];
  selectTagIdChosenList = [];

  selectTagTechList = [];
  selectTagPositionList = [];
  selectTagAreaList = [];
  selectTagChosenList = [];

  myOpts = {
    startVal: 0,
    duration: 1.5,
    scrollSpyOnce: true
  }

  moreTech: boolean = false;
  morePosition: boolean = false;
  moreArea: boolean = false;
  moreChosen: boolean = false;

  constructor(
      @Inject(DOCUMENT) private document: Document,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private renderer: Renderer2,
      private cookieService: CookieService,
      private authService: AuthService,
      private projectService: ProjectService
  ) {
    this.isLogin = this.authService.isLoggedIn;


    this.renderer.listen('window', 'click', (event => {
      if (this.coverShadow != null && event.target == this.coverShadow.nativeElement) {
        this.document.body.classList.toggle('settings-open');
      }
    }));
  }

  ngOnInit(): void {
    // search from home
    this.activatedRoute.params.subscribe((data) => {
      if(data['categoryId'] != null){
        this.categoryId = Number(data['categoryId']);
      }
    });

    this.getSearchCondition();
  }

  getSearchCondition(): void{
    this.projectService.getSearchOption().subscribe( res => {
      this.tagTechList = res.data.tagTechList;
      this.tagPositionList = res.data.tagPositionList;
      this.tagAreaList = res.data.tagAreaList;
      this.tagChosenList = res.data.tagChosenList;

      if(this.categoryId != null){
        this.selectTagIdTechList.push(this.categoryId)

        for(let i = 0; i < this.tagTechList.length; i++){
          if(this.tagTechList[i].id == this.categoryId){
            this.selectTagTechList.push(this.tagTechList[i])
            break;
          }
        }

        this.onClickSearch()
      }else{
        this.getData();
      }
    })
  }

  getData() {
    this.projectService.showFooter = false;

    // let jb_user = this.cookieService.getCookie("jb_user");
    this.projectService.getProjectList(this.limit, this.offset).subscribe(res => {

      window.scrollTo(0, 0);
      this.projectList = res.data.projectList;
      this.totalCount = res.data.publishCount;
      this.count = res.data.publishCount;

      this.loaded = true;
      this.projectService.showFooter = true;
    })

  }

  /**
   * Toggle settings-sidebar
   */
  toggleSettingsSidebar(e: Event) {
    e.preventDefault();
    this.document.body.classList.toggle('settings-open');
  }

  clear(){
    this.selectTagIdTechList = [];
    this.selectTagIdPositionList = [];
    this.selectTagIdAreaList = [];
    this.selectTagIdChosenList = [];
    this.selectTagTechList = [];
    this.selectTagPositionList = [];
    this.selectTagAreaList = [];
    this.selectTagChosenList = [];

    this.onTagSearch();
  }

  like(uuid: string):void{
    const jb_user = this.cookieService.getCookie('jb_user');

    if(jb_user){
      this.projectService.like(uuid).subscribe( res => {
        // window.scrollTo(0, 0);
        if(res.code === 0){
          // window.location.reload();
          for(let i = 0; i < this.projectList.length; i++){
            if(this.projectList[i].uuid == uuid){
              this.projectList[i].isLike = !this.projectList[i].isLike;
            }
          }
        }
      })
    }else{
      this.router.navigate(['/login']);
    }
  }

  goToDetail(val: string, event: any) :void{
    if((event.ctrlKey && !event.metaKey) || (!event.ctrlKey && event.metaKey)){
      const url = this.router.serializeUrl(this.router.createUrlTree(['/project/detail/'+val]));
      window.open(url, '_blank');
    }else{
      this.router.navigate(['/project/detail/'+val]);
      //window.open(url);
    }
  }

  goToClientDetail(val: string, event: any) :void{
    if((event.ctrlKey && !event.metaKey) || (!event.ctrlKey && event.metaKey)){
      const url = this.router.serializeUrl(this.router.createUrlTree(['/client/detail/'+val]));
      window.open(url, '_blank');
    }else{
      this.router.navigate(['/client/detail/'+val]);
      //window.open(url);
    }
  }

  changeTagTech(tag: any): void{
    if(this.selectTagIdTechList.includes(tag.id)){
      this.selectTagIdTechList.splice(this.selectTagIdTechList.indexOf(tag.id), 1)
      this.selectTagTechList.splice(this.selectTagTechList.indexOf(tag), 1)
    }else{
      this.selectTagIdTechList.push(tag.id)
      this.selectTagTechList.push(tag)
    }
    this.onTagSearch();
  }

  changeTagPosition(tag: any): void{
    if(this.selectTagIdPositionList.includes(tag.id)){
      this.selectTagIdPositionList.splice(this.selectTagIdPositionList.indexOf(tag.id), 1)
      this.selectTagPositionList.splice(this.selectTagPositionList.indexOf(tag), 1)
    }else{
      this.selectTagIdPositionList.push(tag.id)
      this.selectTagPositionList.push(tag)
    }
    this.onTagSearch();
  }

  changeTagArea(tag: any): void{
    if(this.selectTagIdAreaList.includes(tag.id)){
      this.selectTagIdAreaList.splice(this.selectTagIdAreaList.indexOf(tag.id), 1)
      this.selectTagAreaList.splice(this.selectTagAreaList.indexOf(tag), 1)
    }else{
      this.selectTagIdAreaList.push(tag.id)
      this.selectTagAreaList.push(tag)
    }
    this.onTagSearch();
  }

  changeTagChosen(tag: any): void{
    if(this.selectTagIdChosenList.includes(tag.id)){
      this.selectTagIdChosenList.splice(this.selectTagIdChosenList.indexOf(tag.id), 1)
      this.selectTagChosenList.splice(this.selectTagChosenList.indexOf(tag), 1)
    }else{
      this.selectTagIdChosenList.push(tag.id)
      this.selectTagChosenList.push(tag)
    }
    this.onTagSearch();
  }

  onTagSearch(): void{
    let form = new TagSearchForm();
    form.tagTechList = this.selectTagIdTechList;
    form.tagPositionList = this.selectTagIdPositionList;
    form.tagAreaList = this.selectTagIdAreaList;
    form.tagChosenList = this.selectTagIdChosenList;
    form.keyword = this.keyword;
    this.projectService.getCountProjectListByTag(form).subscribe( r => {
      this.myOpts.startVal = this.count;
      this.count = r.data.count;
      this.countUp.animate;
    })
  }

  onPageChange(event): void {
    this.offset = event.first;
    this.limit = event.rows;

    let form = new TagSearchForm();
    form.tagTechList = this.selectTagIdTechList;
    form.tagPositionList = this.selectTagIdPositionList;
    form.tagAreaList = this.selectTagIdAreaList;
    form.tagChosenList = this.selectTagIdChosenList;
    form.keyword = this.keyword;
    form.offset = this.offset;
    form.limit = this.limit;

    this.loaded = false;
    this.projectService.getProjectListByTag(form).subscribe( res => {

      window.scrollTo(0, 0);
      this.projectList = res.data.projectList;
      this.totalCount = res.data.publishCount;
      this.count = res.data.publishCount;
      this.projectService.showFooter = true;

      this.loaded = true;
    })
  }

  onClickSearch(): void{
    let form = new TagSearchForm();
    form.tagTechList = this.selectTagIdTechList;
    form.tagPositionList = this.selectTagIdPositionList;
    form.tagAreaList = this.selectTagIdAreaList;
    form.tagChosenList = this.selectTagIdChosenList;
    form.keyword = this.keyword;
    form.offset = 0;
    form.limit = this.limit;

    this.loaded = false;
    this.projectService.getProjectListByTag(form).subscribe( res => {

      window.scrollTo(0, 0);
      this.projectList = res.data.projectList;
      this.totalCount = res.data.publishCount;
      this.count = res.data.publishCount;
      this.projectService.showFooter = true;

      this.document.body.classList.toggle('settings-open');

      this.loaded = true;
    })
  }

  format(amount: number): string{
    let amountJPY = "";

    amountJPY = Number(amount).toLocaleString("ja-JP")

    return amountJPY;
  }

  toEntry(): void{
    this.router.navigate(['/profile',{page: 'isEntry'}]);
    // window.open(url);
  }
}
