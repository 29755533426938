import { Injectable } from '@angular/core';
import {BaseService} from "./base.service";
import {HttpClient} from "@angular/common/http";
import {StatusCode} from "../form/status-code";
import {ProfileForm} from "../form/profile";
import {map, Observable} from "rxjs";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ClientService extends BaseService {

  constructor(
      private http: HttpClient,
      private statusCode: StatusCode,
  ) {
    super();
  }

  getClientDetail(uuid: string): Observable<any> {
    return this.http.get(this.API_PATH.CLIENT_DETAIL + uuid + '/', this.httpOptions)
        .pipe(map((res: any) => {
          return res;
        }));
  }
}
