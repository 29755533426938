<!--<div class="page-title-area">-->
<!--    <div class="d-table">-->
<!--        <div class="d-table-cell">-->
<!--            <div class="container">-->
<!--                <div class="page-title-text">-->
<!--                    <h2>パスワード再設定</h2>-->
<!--                    <ul>-->
<!--                        <li><a routerLink="/">ホーム</a></li>-->
<!--                        <li><i class="icofont-simple-right"></i></li>-->
<!--                        <li>パスワード再設定</li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<div *ngIf="showSetPasswordView" class="login-area pt-170">
    <div class="container w-25-container bg-white p-40">

        <form #f="ngForm">
            <div class="col-lg-12">
                <div class="form-group mb-0">
                    <label class="font-size-3 font-weight-600 mb-0 login-title-text-grey" translate>メールアドレス</label>
                    <input type="email" required="required" name="mail"  [(ngModel)]="mail" [value]="mail" readonly
                           class="form-control input-height ng-untouched ng-pristine ng-invalid">
                    <input type="hidden" name="token" [(ngModel)]="token">
                </div>
            </div>
            <div class="col-lg-12">
                <div class="form-group mb-0 pt-4">
                    <label class="font-size-3 font-weight-600 mb-0 login-title-text-grey" translate>パスワード</label>
                    <input type="password" class="form-control h-px-48" minlength="8" required="required" name="password" [(ngModel)]="password"
                           #psd="ngModel" pattern="^((?=.*[A-Z])(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*\d)(?=.*[\~\!\@\#\$\%\^\&\*\?\_\-\.])|(?=.*[A-Z])(?=.*\d)(?=.*[\~\!\@\#\$\%\^\&\*\?\_\-\.])|(?=.*[A-Z])(?=.*[a-z])(?=.*[\~\!\@\#\$\%\^\&\*\?\_\-\.])).{1,}$">
                </div>
            </div>
            <div class="col-lg-12">
                <div class="form-group mb-0 py-4">
                    <label class="font-size-3 font-weight-600 mb-0 login-title-text-grey" translate>確認パスワード</label>
                    <input type="password" class="form-control h-px-48" (keyup)="confirmCheck()" name="confirmPassword" [(ngModel)]="confirmPassword">
                    <div *ngIf="confirmError">
                        <div class="middle alert alert-danger line-height-0 h-px-25 mt-1 font-size-2" translate>
                            確認パスワード入力が間違いました。
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div class="login-page-btn">
            <a (click)="passwordResetSubmit(f.value)" class="login-page-common-btn">確定</a>
        </div>
    </div>
</div>

<div *ngIf="showSetPasswordError" class="404-page bg-default min-h-60vh flex-all-center pt-lg-15 pt-xxl-17 pb-lg-0 pb-18">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-7 px-lg-9">
                <div class="card-404 text-center" data-aos="zoom-in" data-aos-duration="800" data-aos-once="true">
                    <!--          <img src="../assets/image/{{svg}}" alt="" class="w-50 h-50 px-9">-->
                    <div class="404-texts pt-14 common-btn">
                        <p class="card-text font-size-6 px-0 mb-4">{{title}}</p>
                        <h3 class="card-title font-size-9 font-weight-bold">{{content}}</h3>
                        <a routerLink="/" type="button" class="btn login-btn">TOP画面へ</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
