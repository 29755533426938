import { Component, OnInit } from '@angular/core';
import {NgbSlideEvent, NgbSlideEventSource} from "@ng-bootstrap/ng-bootstrap";
import {ClientService} from "../../../service/client.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ClientDetail} from "./client-detail.model";

@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.scss']
})
export class ClientDetailComponent implements OnInit {

  client = new ClientDetail();

  constructor(
      public router: Router,
      private activatedRoute: ActivatedRoute,
      private clientService: ClientService
  ) { }

  bizDesc = "◇独自の研修プログラム『sLab College』があり、実務未経験者も歓迎！\n" +
      "◇月4万件の豊富な案件あり\n" +
      "◇モダンな開発からレガシースキルが求められる案件まで様々\n" +
      "◇入社の決め手の多くが“社員の人柄の良さ”！\n" +
      "\n" +
      "■人材開発\n" +
      "■ITエンジニア派遣\n" +
      "■受注開発\n" +
      "■ITコンサルティング\n" +
      "■SES事業\n" +
      "■オフショア開発";

  industry = "IT・通信業界、ITアウトソーシング、受注開発、ITコンサルティング、SES事業";

  feature = "[s]calability [Lab]oratoryの略称である。\n" +
      "・プロフェッショナル人材を集め、研究を繰り返し、探究するチームである。\n" +
      "・専門知識を活かし、顧客の最大利益を実現する。\n" +
      "・イノベーション精神で人々が喜ぶサービスを創りだすことにチャレンジする。\n" +
      "\n" +
      "個人成長===会社成長\n" +
      "・一人一人個人の成長を大事に、未経験〜プロフェッショナルになる成長環境を備える。\n" +
      "・個人の成長は会社の成長に繋がる。\n" +
      "\n" +
      "努力人を全力で支えていきたい。\n" +
      "・成長するため、個人の意識＆苦労は欠かせない。\n" +
      "・すべての夢の実現に貢献する。";

  comment = "「ハイスキルなエンジニアを育て、IT業界、そして社会に貢献したい」という代表・中山氏の想いから設立されたエスラボ。代表自身、某大手IT会社や大手人材企業の社長室に従事。コードレビューも熟知している技術者出身の代表がトップにいることで、IT業界でのキャリアプランや悩みも安心して打ち明けられる社風が整っている。また、マネジメントについても熱心で、2021年からMBAの取得に挑戦しているという。技術畑にも営業畑にも精通し、確かな経営手腕を持つ代表の元で働くことは、これからのキャリアに必ずプラスとなるはずだ。その証拠に、在籍している社員の多くが、何でも気兼ねなく相談できる代表の人柄に惹かれて入社を決めている。";

  images = []

  ngOnInit(): void {
    this.getData();
  }

  getData(): void{
    let uuid = this.activatedRoute.snapshot.paramMap.get("uuid");
    this.clientService.getClientDetail(uuid).subscribe(r => {
      if(r.code == 0){
        this.client = r.data.client;
        if(this.client.img1 != null && this.client.img1 != ''){
          this.images.push(this.client.img1)
        }
        if(this.client.img2 != null && this.client.img2 != ''){
          this.images.push(this.client.img2)
        }
        if(this.client.img3 != null && this.client.img3 != ''){
          this.images.push(this.client.img3)
        }
        if(this.client.img4 != null && this.client.img4 != ''){
          this.images.push(this.client.img4)
        }
        if(this.client.img5 != null && this.client.img5 != ''){
          this.images.push(this.client.img5)
        }
      }else{
        alert("error")

      }
    })
  }

  format(amount: number): string{
    let amountJPY = "";

    amountJPY = Number(amount).toLocaleString("ja-JP")

    return amountJPY;
  }
}
