<!--<div class="page-title-area">-->
<!--    <div class="d-table">-->
<!--        <div class="d-table-cell">-->
<!--            <div class="container">-->
<!--                <div class="page-title-text">-->
<!--                    <h2>パスワード再設定</h2>-->
<!--                    <ul>-->
<!--                        <li><a routerLink="/">ホーム</a></li>-->
<!--                        <li><i class="icofont-simple-right"></i></li>-->
<!--                        <li>パスワード再設定</li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<div class="login-area pt-170">
    <div class="container w-25-container bg-white p-40">

        <div class="pt-2 mb-0 text-center">
            <a class="mail-login">
                <span class="mail-login login-title-text-grey" translate>パスワード再設定</span>
            </a>
        </div>

        <form #f="ngForm">
            <div class="form-group mb-0 py-4">
                <label class="font-size-3 font-weight-600 mb-0 login-title-text-grey" translate>メールアドレス</label>
                <input type="email" required="required" name="mail" [(ngModel)]="mail"
                       class="form-control input-height ng-untouched ng-pristine ng-invalid">
            </div>
        </form>

        <div class="login-page-btn">
            <a (click)="mailSubmit(f.value)" class="login-page-common-btn">送信</a>
        </div>
    </div>
</div>
