import { Injectable } from '@angular/core';
import {BaseService} from "./base.service";
import {map, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {catchError} from "rxjs/operators";
import {ProfileForm} from "../form/profile";
import {CommentSave, TagSearchForm} from "../components/pages/project-list/project-list.model";

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends BaseService {
  showFooter = true;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  getProjectList(limit: number, offset: number): Observable<any> {
    return this.http.get(this.API_PATH.PROJECT_LIST + limit + '/' + offset + '/', this.httpOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

    getProjectListHome(): Observable<any> {
        return this.http.get(this.API_PATH.PROJECT_LIST_HOME, this.httpOptions)
            .pipe(map((res: any) => {
                return res;
            }));
    }

    getProjectListSimilar(uuid: string): Observable<any> {
        return this.http.get(this.API_PATH.PROJECT_LIST_SIMILAR + uuid + '/', this.httpOptions)
            .pipe(map((res: any) => {
                return res;
            }));
    }

  getProjectLikeList(limit: number, offset: number): Observable<any> {
    return this.http.get(this.API_PATH.PROJECT_LIKE_LIST + limit + '/' + offset + '/', this.httpOptions)
        .pipe(map((res: any) => {
          return res;
        }));
  }

  getProjectEntryList(limit: number, offset: number): Observable<any> {
    return this.http.get(this.API_PATH.PROJECT_ENTRY_LIST + limit + '/' + offset + '/', this.httpOptions)
        .pipe(map((res: any) => {
          return res;
        }));
  }

  getProjectDetail(uuid: string): Observable<any> {
    return this.http.get(this.API_PATH.PROJECT_DETAIL + uuid + '/', this.httpOptions)
      .pipe(map((res: any) => {
        return res;
      }));
  }

  apply(uuid: string, message: string): Observable<any> {
    let form = new FormData();
    form.append("uuid", uuid);
    form.append("message", message);

    const headers = new Headers();
    /** In Angular 5, including the header Content-Type can invalidate your request */
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    this.httpOptions.headers = headers;
    return this.http.post<string>(this.API_PATH.PROJECT_APPLY, form, this.httpOptions)
        .pipe(map((res: any) => {
          return res;
        }));
  }

  like(uuid: string): Observable<any> {
    let form = new FormData();
    form.append("uuid", uuid);

    const headers = new Headers();
    /** In Angular 5, including the header Content-Type can invalidate your request */
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    this.httpOptions.headers = headers;
    return this.http.post<string>(this.API_PATH.PROJECT_LIKE, form, this.httpOptions)
        .pipe(map((res: any) => {
          return res;
        }));
  }

  getSearchProjectListByTitleAndArea(title: string, area: string, category: number, limit: number, offset: number): Observable<any> {
    title = title != '' ? title : ' ';
    area = area != '' ? area : ' ';
    return this.http.get(this.API_PATH.PROJECT_LIST + `${limit}/` + `${offset}/` + `${title}/` + `${area}/` + `${category}/`, this.httpOptions)
        .pipe(map((res: any) => {
          return res;
        }));
  }

    saveComment(form: CommentSave): Observable<any> {
        return this.http.post<string>(this.API_PATH.PROFILE_ENTRY_COMMENT_SAVE, form, this.httpOptions)
            .pipe(map((res: any) => {
                return res;
            }));
    }

    getSearchOption():Observable<any> {
        return this.http.get(this.API_PATH.PROJECT_OPTION, this.httpOptions)
            .pipe(map((res: any) => {
                return res;
            }));
    }

    getCountProjectListByTag(form: TagSearchForm): Observable<any> {
        const headers = new Headers();
        /** In Angular 5, including the header Content-Type can invalidate your request */
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        this.httpOptions.headers = headers;
        return this.http.post<string>(this.API_PATH.PROJECT_LIST_COUNT, form, this.httpOptions)
            .pipe(map((res: any) => {
                return res;
            }));
    }

    getProjectListByTag(form: TagSearchForm): Observable<any> {
        const headers = new Headers();
        /** In Angular 5, including the header Content-Type can invalidate your request */
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        this.httpOptions.headers = headers;
        return this.http.post<string>(this.API_PATH.PROJECT_LIST, form, this.httpOptions)
            .pipe(map((res: any) => {
                return res;
            }));
    }
}
