import { Component } from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationStart, Router} from "@angular/router";
import {filter} from "rxjs";
declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'jobinder-front';
  location: any;
  routerSubscription: any;
  loading = true;

  constructor(public router: Router) {
  }

  ngOnInit(){
    this.recallJsFuntions();
  }

  recallJsFuntions() {
    this.router.events
      .subscribe((event) => {
        if ( event instanceof NavigationStart ) {
          $('.loader').show();
          this.loading = true;
        }
      });
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
      .subscribe(event => {
        $.getScript('../assets/js/custom.js');
        setTimeout(() =>{
            $('.loader').fadeOut('slow');
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            this.loading = false;
            window.scrollTo(0, 0);
        }, 1000)
      });
  }

}
