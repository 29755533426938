import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ProjectService} from "../../../service/project.service";
import {ProjectDetail, TagForm} from "./project-detail.model";
import {ProjectList} from "../project-list/project-list.model";
import {CookieService} from "../../../service/cookie.service";
import {ProfileService} from "../../../service/profile.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {

  form: ProjectDetail;
  projectList: ProjectList[] = [];
  message!: string;
  applyError: boolean = false;
  applyErrorText: string = "";

  enable: boolean = true;
  errorMsg: string = "";
  disableType: number = 0;

  techList: TagForm[] = [];
  positionList: TagForm[] = [];
  areaList: TagForm[] = [];
  chosenList: TagForm[] = [];

  constructor(
      public router: Router,
      private activatedRoute: ActivatedRoute,
      private cookieService: CookieService,
      private projectService: ProjectService,
      private profileService: ProfileService
  ) { }

  ngOnInit(): void {
    window.scrollTo(0,0);

    const jb_user = this.cookieService.getCookie('jb_user');

    if(jb_user){
      let uuid = this.activatedRoute.snapshot.paramMap.get("uuid");
      this.getData(uuid);
    }else{
      this.router.navigate(['/login']);
    }
  }

  getData(uuid: string) {
    this.projectService.getProjectDetail(uuid).subscribe(res => {
      if(res.code === 0){
        this.form = res.data.form;
        this.techList = res.data.techList;
        this.positionList = res.data.positionList;
        this.areaList = res.data.areaList;
        this.chosenList = res.data.chosenList;
        this.enable = res.data.enable;
        this.errorMsg = res.data.errorMsg;
        this.disableType = res.data.disableType;
        this.getProjectList(this.form.uuid);
      }else{
        this.router.navigate(['/login']);
      }
    })
  }

  getProjectList(uuid: string) {
    this.projectService.getProjectListSimilar(uuid).subscribe(res => {
      this.projectList = res.data.projectList;
    })
  }

  apply(){
    const jb_user = this.cookieService.getCookie('jb_user');

    if(jb_user != null && this.form.uuid != null && this.message != null){

      this.profileService.checkStatus().subscribe( res => {
        if(res.code === 0){
          this.projectService.apply(this.form.uuid, this.message).subscribe( res => {
            // window.scrollTo(0, 0);
            if(res.code === 0){
              Swal.fire({
                title: 'Success!',
                text: '成功に応募しました。',
                icon: 'success',
                showCancelButton: false,
                showConfirmButton: true
              }).then((result) => {
                if(result.isConfirmed){
                  window.location.reload();
                }
              });
            }else{
              this.applyError = true;
              this.applyErrorText = "応募に失敗しました。もう一度お試しください";
            }
          })
        }else{
          this.applyError = true;
          this.applyErrorText = res.message;
        }
      })
    }else{
      this.applyError = true;
      this.applyErrorText = "応募メッセージを空にすることはできません";
    }
  }

  like(uuid: string):void{
    const jb_user = this.cookieService.getCookie('jb_user');

    if(jb_user){
      this.projectService.like(uuid).subscribe( res => {
        // window.scrollTo(0, 0);
        if(res.code === 0){
          // window.location.reload();
          for(let i = 0; i < this.projectList.length; i++){
            if(this.projectList[i].uuid == uuid){
              this.projectList[i].isLike = !this.projectList[i].isLike;
            }
          }
        }
      })
    }else{
      this.router.navigate(['/login']);
    }
  }

  goToDetail(val: string, event: any) :void{
    if((event.ctrlKey && !event.metaKey) || (!event.ctrlKey && event.metaKey)){
      const url = this.router.serializeUrl(this.router.createUrlTree(['/project/detail/'+val]));
      window.open(url, '_blank');
    }else{
      this.router.navigate(['/project/detail/'+val]);
      //window.open(url);
    }
  }

  goToClientDetail(val: string, event: any) :void{
    if((event.ctrlKey && !event.metaKey) || (!event.ctrlKey && event.metaKey)){
      const url = this.router.serializeUrl(this.router.createUrlTree(['/client/detail/'+val]));
      window.open(url, '_blank');
    }else{
      this.router.navigate(['/client/detail/'+val]);
      //window.open(url);
    }
  }

  format(amount: number): string{
    let amountJPY = "";

    amountJPY = Number(amount).toLocaleString("ja-JP")

    return amountJPY;
  }

  toEntry(): void{
    this.router.navigate(['/profile',{page: 'isEntry'}]);
    //window.open(url);
  }
}
