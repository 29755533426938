import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {BaseService} from './base.service';
import {StatusCode} from '../form/status-code';
import {Observable} from 'rxjs';
import {ProfileForm, Skill} from "../form/profile";

// interface ResJSONProfileDetail {
//   statusCode: string;
//   resultList: { profile: Profile, countryList: Country[], qualificationList: Qualification[] };
// }
// interface ResJSONProjectTag {
//   statusCode: string;
//   resultList: { areaList: Tag[] };
// }

@Injectable({
    providedIn: 'root'
})


export class ProfileService extends BaseService {

    constructor(
        private http: HttpClient,
        private statusCode: StatusCode,
    ) {
        super();
        // `Authorization` に `Bearer トークン` をセットする
        this.setAuthorization('c3RzZXJ2aWNlOmZZenJleWc1c2E0UQ==');
    }

    public setAuthorization(token: string): void {
        if (!token) {
            return;
        }
        const bearerToken = `Bearer ${token}`;
        this.httpOptions.headers = this.httpOptions.headers.set('Authorization', bearerToken);
    }

    postProfile(form: ProfileForm): Observable<any> {
        // 注: angular2ではファイルアップロードする場合はhttpOptions付けないこと,データはFormDataに入れてバックエンドに渡す
        // const headers = new Headers();
        /** In Angular 5, including the header Content-Type can invalidate your request */
        // headers.append('Content-Type', 'multipart/form-data');
        // headers.append('Accept', 'application/json');
        // this.httpOptions.headers = headers;
        return this.http.post<string>(this.API_PATH.PROFILE_EDIT, form, this.httpOptions)
            .pipe(
                catchError(this.handleError<ProfileForm>(`profile insert failure`))
            );
    }

    getSkillList(): Observable<any> {
        return this.http.get(this.API_PATH.PROFILE_SKILL_LIST, this.httpOptions)
            .pipe(map((res: any) => {
                return res;
            }));
    }

    checkStatus(): Observable<any> {
        return this.http.get(this.API_PATH.PROFILE_STATUS_CHECK, this.httpOptions)
            .pipe(map((res: any) => {
                return res;
            }));
    }

    postSkillList(skillList: Skill[]): Observable<any> {
        return this.http.post<string>(this.API_PATH.PROFILE_SKILL_SAVE, skillList, this.httpOptions)
            .pipe(
                catchError(this.handleError<ProfileForm>(`profile insert failure`))
            );
    }

    updateEntryEnd(entryId: number): Observable<any> {
        return this.http.post<string>(this.API_PATH.PROFILE_ENTRY_END, entryId, this.httpOptions)
            .pipe(map((res: any) => {
                return res;
            }));
    }

    restartEntryEnd(entryId: number): Observable<any> {
        return this.http.post<string>(this.API_PATH.PROFILE_ENTRY_RESTART, entryId, this.httpOptions)
            .pipe(map((res: any) => {
                return res;
            }));
    }
}
