import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../../../service/auth.service";
import {Router} from "@angular/router";
import {StatusCode} from "../../../../form/status-code";
import {HeaderService} from "../../../../service/header.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-password-forget',
  templateUrl: './password-forget.component.html',
  styleUrls: ['./password-forget.component.scss']
})
export class PasswordForgetComponent implements OnInit {

  mail!: string;

  constructor(
      private authService: AuthService,
      public router: Router,
      private statusCode: StatusCode,
      private headerService: HeaderService,
  ) { }

  ngOnInit(): void {
  }

  // メールでPW再設定
  mailSubmit(form: any): void {
    if(form.mail == null){
      return;
    }
    this.authService.sendPasswordResetLink(form.mail).subscribe((res: any) => {
      if (res.code === 0) {
        Swal.fire({
          title: 'Success!',
          html: 'パスワードリセットメールを送りました。<br>メールボックスを確認してください。',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: true
        }).then((result) => {
          if(result.isConfirmed){
            this.router.navigate(['/']).then(() => {
              window.location.reload();
            })
          }
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: '入力情報を確認してください。もう一度お試しください！',
          icon: 'error',
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: '閉じる'
        })
      }
    });
  }

}
