import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../../../service/auth.service";
import {Router} from "@angular/router";
import {StatusCode} from "../../../../form/status-code";
import {HeaderService} from "../../../../service/header.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  message!: string;
  message1!: string;
  message2!: string;
  mail!: string;
  password!: string;

  constructor(
      private authService: AuthService,
      public router: Router,
      private statusCode: StatusCode,
      private headerService: HeaderService,
  ) {
    // const isLogin = this.headerService.getIsLoginEmitter();
    //
    // const user = this.authService.userValue;
    //
    // if(isLogin && user){
    //   this.router.navigate(['/']);
    // }
  }

  ngOnInit(): void {
  }

  // メールでログイン
  mailRegisterSubmit(form: any): void {
    const loginForm = {
      mail: form.mail
    };
    this.authService.postRegister(loginForm).subscribe((res: any) => {
      if (res.code === this.statusCode.SUCCESS) {
        Swal.fire({
          title: 'Success!',
          html: '登録手順メールを送りました。<br>メールボックスを確認してください。',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: true
        }).then((result) => {
          if(result.isConfirmed){
            this.router.navigate(['/']).then(() => {
              window.location.reload();
            })
          }
        });
      } else {
        // this.headerComponent.isLogin = false;
        // this.headerService.emitIsLoginEvent(false);
        // this.message = '申し訳ありません。';
        // this.message1 = res.message;
        // this.message2 = '再度ログインする場合は、運営者にご連絡ください。';
        Swal.fire({
          title: 'Error!',
          text: '登録メールが無効です。もう一度お試しください！',
          icon: 'error',
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: '閉じる'
        })
      }
    });
  }

  facebookLoginSubmit(): void{
    this.authService.facebookLoginSubmit();
  }

  googleLoginSubmit(): void{
    this.authService.googleLoginSubmit();
  }

  wechatLoginSubmit(): void{
    this.authService.wechatLoginSubmit();
  }
}
