<div class="navbar-area fixed-top">

  <div class="mobile-nav">
      <div class="w-100 bg-white navbar-header-sp row mx-0">
          <div class="col-6 px-0">
              <a routerLink="/" class="logo"><img src="assets/img/jobinder-logo2.png" alt="Logo" height="1"></a>
          </div>
          <div class="col-6 text-right align-self-center">
              <button class="navbar-toggler border-0" type="button">
                  <a routerLink="/profile" class="cursor-pointer main-color text-end"><i class="icofont-user-suited"></i></a>
              </button>
          </div>
      </div>

      <div class="collapse navbar-collapse" id="mobile-menu">
          <div style="width: 100%;height: 100%;background-color: red"></div>
      </div>
  </div>

  <div class="main-nav">
    <div class="container">
      <nav class="navbar navbar-expand-md navbar-light">
        <a class="navbar-brand" routerLink="/"><img src="assets/img/jobinder-logo2.png" alt="Logo" height="1"></a>

        <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
<!--          <ul class="navbar-nav">-->
<!--              <li class="nav-item">-->
<!--                  <a routerLink="/project" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">案件検索</a>-->
<!--              </li>-->
<!--              <li class="nav-item">-->
<!--                  <a routerLink="/tips" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">お役立ち情報</a>-->
<!--              </li>-->
<!--              <li class="nav-item">-->
<!--                  <a routerLink="/event" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">イベント情報</a>-->
<!--              </li>-->
<!--          </ul>-->
            <div class="m-auto"></div>

          <div class="common-btn" *ngIf="!isLogin">
            <a class="sign-up-btn" routerLink="/login"><i class="icofont-plus-square"></i> ログイン</a>
            <a class="sign-up-btn" routerLink="/signup"><i class="icofont-user-alt-4"></i> 登録</a>
          </div>

          <div class="" *ngIf="isLogin">
<!--            <a class="sign-up-btn" (click)="logout()"><i class="icofont-logout"></i> ログアウト</a>-->
            <a (click)="toProfile($event)" class="cursor-pointer main-color text-end"><i class="icofont-user-suited"></i></a>
            <a (click)="toProfile($event)" class="ms-3 me-3 cursor-pointer main-color login-name restricted">{{showName}}</a>
          </div>
        </div>
      </nav>
    </div>
  </div>

</div>
