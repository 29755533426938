export class ProjectList {
  uuid: string;
  title: string;
  station: string;
  experience: number;
  price: number;
  position: string;
  isLike: boolean;
  isEntry: string;
  content: string;
  isNew: boolean;
  isRecent: boolean;
  isFeature: boolean;
  category: number;
  agentName: string;
  agentUuid: string;

  entryId: number;
  replyType: number;
  commentList: EntryComment[];

  tagList: TagForm[];
}

export class TagForm{
  publishId: number;
  tagId: number;
  content: string;
}

export class EntryComment {
  adminName: string;
  itemType: number;
  title: string;
  comment: string;
  datetime: string;
}

export class CommentSave {
  itemId: number;
  title: string;
  comment: string;
}

export class TagSearchForm {
  keyword: string;
  tagTechList: number[];
  tagPositionList: number[];
  tagAreaList: number[];
  tagChosenList: number[];
  limit: number;
  offset: number;
}
