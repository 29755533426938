<!--<div class="banner-area banner-area-three" *ngIf="!isLogin">-->
<!--    <div class="d-table">-->
<!--        <div class="d-table-cell">-->
<!--            <div class="container desktop-container">-->
<!--                <div class="row">-->
<!--                    <div class="col-6">-->
<!--                        <div class="banner-text">-->
<!--                            <h1>より <span>良い</span> 仕事を求める</h1>-->
<!--                            <p>仕事、雇用、および将来のキャリアの機会</p>-->

<!--&lt;!&ndash;                            <div class="banner-form-area">&ndash;&gt;-->
<!--&lt;!&ndash;                                <form>&ndash;&gt;-->
<!--&lt;!&ndash;                                    <div class="row">&ndash;&gt;-->
<!--&lt;!&ndash;                                        <div class="col-lg-6">&ndash;&gt;-->
<!--&lt;!&ndash;                                            <div class="form-group">&ndash;&gt;-->
<!--&lt;!&ndash;                                                <label><i class="icofont-search-1"></i></label>&ndash;&gt;-->
<!--&lt;!&ndash;                                                <input type="text" class="form-control" name="title" [(ngModel)]="title" placeholder="タイトル">&ndash;&gt;-->
<!--&lt;!&ndash;                                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                        </div>&ndash;&gt;-->

<!--&lt;!&ndash;                                        <div class="col-lg-6">&ndash;&gt;-->
<!--&lt;!&ndash;                                            <div class="form-group">&ndash;&gt;-->
<!--&lt;!&ndash;                                                <label><i class="icofont-location-pin"></i></label>&ndash;&gt;-->
<!--&lt;!&ndash;                                                <input type="text" class="form-control" name="area" [(ngModel)]="area" placeholder="地域">&ndash;&gt;-->
<!--&lt;!&ndash;                                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </div>&ndash;&gt;-->

<!--&lt;!&ndash;                                    <div class="text-left banner-search-button" (click)="goToSearch()">&ndash;&gt;-->
<!--&lt;!&ndash;                                        <a class="banner-search-common-btn">検索</a>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                    &lt;!&ndash;                            <button type="submit" class="btn banner-form-btn">検索</button>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                </form>&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-6 text-center">-->
<!--                        <img src="./assets/img/download.jpg" alt="" class="w-70">-->
<!--                        <div class="row home-card">-->
<!--                            &lt;!&ndash; check-mark start &ndash;&gt;-->
<!--                            <div class="col-4 check-c">-->
<!--                                <div class="check-mark circle-41">-->
<!--                                    <i class="icofont-check"></i>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            &lt;!&ndash; check-mark end &ndash;&gt;-->
<!--                            &lt;!&ndash; media body start &ndash;&gt;-->
<!--                            <div class="media-body align-self-center">-->
<!--&lt;!&ndash;                                <h6 class="mb-0 font-size-3 text-green text-uppercase">高単価!</h6>&ndash;&gt;-->
<!--                                <p class="mb-0 font-size-4 text-black-2">独自求人多数!</p>-->
<!--                            </div>-->
<!--                            &lt;!&ndash; media body start &ndash;&gt;-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="container mobile-container mt-5">-->
<!--                <div>-->
<!--                    <img src="./assets/img/download.jpg" alt="">-->
<!--                </div>-->
<!--                <div>-->
<!--                    <div class="banner-text">-->
<!--                        <h1>より <span>良い</span> 仕事を求める</h1>-->
<!--                        <p>仕事、雇用、および将来のキャリアの機会</p>-->

<!--&lt;!&ndash;                        <div class="banner-form-area">&ndash;&gt;-->
<!--&lt;!&ndash;                            <form>&ndash;&gt;-->
<!--&lt;!&ndash;                                <div class="row">&ndash;&gt;-->
<!--&lt;!&ndash;                                    <div class="col-lg-6">&ndash;&gt;-->
<!--&lt;!&ndash;                                        <div class="form-group">&ndash;&gt;-->
<!--&lt;!&ndash;                                            <label><i class="icofont-search-1"></i></label>&ndash;&gt;-->
<!--&lt;!&ndash;                                            <input type="text" class="form-control" name="title" [(ngModel)]="title" placeholder="タイトル">&ndash;&gt;-->
<!--&lt;!&ndash;                                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </div>&ndash;&gt;-->

<!--&lt;!&ndash;                                    <div class="col-lg-6">&ndash;&gt;-->
<!--&lt;!&ndash;                                        <div class="form-group">&ndash;&gt;-->
<!--&lt;!&ndash;                                            <label><i class="icofont-location-pin"></i></label>&ndash;&gt;-->
<!--&lt;!&ndash;                                            <input type="text" class="form-control" name="area" [(ngModel)]="area" placeholder="地域">&ndash;&gt;-->
<!--&lt;!&ndash;                                        </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                </div>&ndash;&gt;-->

<!--&lt;!&ndash;                                <div class="text-left banner-search-button" (click)="goToSearch()">&ndash;&gt;-->
<!--&lt;!&ndash;                                    <a class="banner-search-common-btn">検索</a>&ndash;&gt;-->
<!--&lt;!&ndash;                                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                &lt;!&ndash;                            <button type="submit" class="btn banner-form-btn">検索</button>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                            </form>&ndash;&gt;-->
<!--&lt;!&ndash;                        </div>&ndash;&gt;-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<section id="section-hero" class="banner-area banner-area-three section-hero" aria-label="section" *ngIf="!isLogin">
    <div class="container">
        <div class="it_comp_shape6 desktop-container"><img src="assets/img/home/shape2.png" alt="img"></div>
        <div class="it_comp_shape_box desktop-container"><img src="assets/img/home/shape3.png" alt="shape"></div>
        <!--        <div class="it_comp_shape_box2"><img src="assets/img/home/shape4.png" alt="shape"></div>-->
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="it_comp_bg_animation">
                    <img class="it_comp_zoom_fade" src="assets/img/home/shape.png" alt="img">
                </div>
                <div class="spacer-single"></div>
                <h6 class="wow fadeInUp" data-wow-delay=".2s"><span class="main-color">エンジニア求人が豊富な転職サイト</span></h6>
                <h1 class="wow fadeInUp" data-wow-delay=".4s">より <span class="main-color">良い</span> 仕事を求める</h1>
                <p class="wow fadeInUp lead" data-wow-delay=".6s" style="margin-bottom: 50px;margin-top: 50px">
                    高単価×高水準の継続率×豊富な案件数で<br>
                    スキルアップ＆安定した収入を実現<br>
                    <span class="second-color text-bold">エンジニア</span>に特化したIT求人・案件サイト
                </p>
                <div class="wow common-btn mt-4 text-left fadeInUp" data-wow-delay=".7s">
                    <a class="login-btn my-1 mx-1" routerLink="/signup">無料登録 <i class="icofont-swoosh-right"></i></a>
                </div>
                <div class="row mx-0" style="margin-bottom: 100px;margin-top: 50px">
                    <div class="col-lg-3 col-4 wow fadeInRight" data-wow-delay=".85s">
                        <div class="de_count text-left">
                            <h3 class="mb-1 main-color"><span>5</span>k+</h3>
                            <h5 class="second-color">限定案件</h5>
                        </div>
                    </div>

                    <div class="col-lg-3 col-4 wow fadeInRight" data-wow-delay="1s">
                        <div class="de_count text-left">
                            <h3 class="mb-1 main-color"><span>1.6</span>k+</h3>
                            <h5 class="second-color">取引先</h5>
                        </div>
                    </div>

                    <div class="col-lg-3 col-4 wow fadeInRight" data-wow-delay="1.1s">
                        <div class="de_count text-left">
                            <h3 class="mb-1 main-color"><span>900</span>+</h3>
                            <h5 class="second-color">成功実績</h5>
                        </div>
                    </div>
                </div>
                <div class="mb-sm-30"></div>
            </div>
            <div class="col-md-6 xs-hide desktop-container wow fadeInUp" data-wow-delay=".6s">

                <swiper class="top-swiper" [spaceBetween]="20"
                        [loop]="true" [autoHeight]="true" [allowTouchMove]="false" [speed]="2000"
                        [autoplay]="{ delay: 10000, disableOnInteraction: false }" [effect]="'fade'"
                        [pagination]="{ clickable: true }" [navigation]="false">
                    <ng-template swiperSlide><div><img src="assets/img/home/top.png" class="lazy img-fluid" alt=""></div></ng-template>
                    <ng-template swiperSlide><div><img src="assets/img/home/top-swiper-2.png" class="lazy img-fluid" alt=""></div></ng-template>
                    <ng-template swiperSlide><div><img src="assets/img/home/top-swiper-3.png" class="lazy img-fluid" alt=""></div></ng-template>
<!--                    <ng-template swiperSlide><img src="assets/img/home/popular-3.jpg" class="lazy img-fluid" alt=""></ng-template>-->
                </swiper>
            </div>
        </div>
    </div>
</section>

<section *ngIf="!isLogin" class="wow fadeInUp desktop-container swiper-section" data-wow-delay="1s">
    <ng-container>
        <swiper [loop]="true" [slidesPerView]="14" [navigation]="false" [speed]="5000" [lazy]="true"
                [autoplay]="{ delay: 0, disableOnInteraction: false }" [pagination]="false"
                [scrollbar]="false" [watchSlidesProgress]="false">
            <ng-template swiperSlide><img src="assets/img/swiper/a01.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a02.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a03.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a04.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a05.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a06.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a07.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a08.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a09.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a10.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a11.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a12.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a13.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a14.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a15.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a16.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a17.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a18.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a19.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a20.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a21.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a22.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a23.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a24.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a25.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a26.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a27.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a28.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a29.png" loading="lazy"></ng-template>
            <ng-template swiperSlide><img src="assets/img/swiper/a30.png" loading="lazy"></ng-template>
        </swiper>
    </ng-container>
</section>

<!--<section *ngIf="!isLogin" class="wow fadeInUp mobile-container mb-3 swiper-section" data-wow-delay="1s">-->
<!--    <ng-container>-->
<!--        <swiper [loop]="true" [slidesPerView]="6" [navigation]="false" [speed]="5000" [lazy]="true"-->
<!--                [autoplay]="{ delay: 0, disableOnInteraction: false }" [pagination]="false"-->
<!--                [scrollbar]="false" [watchSlidesProgress]="false">-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a01.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a02.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a03.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a04.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a05.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a06.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a07.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a08.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a09.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a10.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a11.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a12.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a13.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a14.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a15.png" loading="lazy"></ng-template>-->
<!--        </swiper>-->
<!--        <swiper [loop]="true" [slidesPerView]="6" [navigation]="false" [speed]="5000" [lazy]="true"-->
<!--                [autoplay]="{ delay: 0, disableOnInteraction: false, reverseDirection: true }" [pagination]="false"-->
<!--                [scrollbar]="false" [watchSlidesProgress]="false">-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a16.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a17.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a18.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a19.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a20.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a21.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a22.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a23.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a24.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a25.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a26.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a27.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a28.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a29.png" loading="lazy"></ng-template>-->
<!--            <ng-template swiperSlide><img src="assets/img/swiper/a30.png" loading="lazy"></ng-template>-->
<!--        </swiper>-->
<!--    </ng-container>-->
<!--</section>-->

<div class="create-area" *ngIf="!isLogin">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8">
                <div class="create-item">
                    <h2>プロフィールを作成して、何千もの仕事を見つけ、<br>キャリアと雇用を築きましょう！</h2>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="create-item">
                    <div class="create-btn">
                        <a *ngIf="!isLogin" routerLink="/login">プロフィール作成</a>
                        <a *ngIf="isLogin" routerLink="/profile">プロフィール編集</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="job-area pt-120 pb-70 pt-80-sp" style="background-color: rgba(101, 113, 255, 0.03)">
    <div class="container">
        <div class="section-title">
            <h2>新着求人一覧</h2>
        </div>

        <div id="container">
            <div class="row">
                <ng-container *ngFor="let project of projectList">
                    <div class="col-lg-4">
                        <div class="job-item">
                            <!--                            <ng-container>-->
                            <!--                                <div class="badge">-->
                            <!--                                    NEW-->
                            <!--                                </div>-->
                            <!--                            </ng-container>-->
                            <div class="job-inner align-items-center">
                                <div class="position-absolute project-list-like-action">
                                    <ng-container *ngIf="!project.isLike">
                                        <a (click)="like(project.uuid)"
                                           matTooltip="お気に入り"
                                           matTooltipPosition="right"
                                           class="cursor-pointer">
                                            <mat-icon class="gray-color3">favorite</mat-icon>
                                        </a>
                                    </ng-container>
                                    <ng-container *ngIf="project.isLike">
                                        <a (click)="like(project.uuid)"
                                           matTooltip="取り消し"
                                           matTooltipPosition="right"
                                           class="cursor-pointer">
                                            <mat-icon class="danger-color">favorite_border</mat-icon>
                                        </a>
                                    </ng-container>
                                </div>

                                <div class="entry-tag" *ngIf="project.isEntry" (click)="toEntry()">
                                    応募済
                                </div>

                                <div class="job-inner-left">
                                    <span class="cursor-pointer"
                                          (click)="goToClientDetail(project.agentUuid, $event)">{{project.agentName}}</span>
                                    <h3 class="text-ellipsis-1 h-title cursor-pointer main-color"
                                        (click)="goToDetail(project.uuid, $event)">{{project.title}}</h3>
                                    <!--                                    <p class="text-ellipsis-3 h-content">{{project.content}}</p>-->
                                    <!--                                    <div class="row gray-color">-->
                                    <!--                                        <div class="col-5" *ngIf="project.price != null && project.price != ''"><i class="icofont-money-bag main-color"></i> ¥ {{project.price}} 万</div>-->
                                    <!--                                        <div class="col-5" *ngIf="project.price == null || project.price == ''"><i class="icofont-money-bag main-color"></i> ¥ &#45;&#45; 万</div>-->
                                    <!--                                        <div class="col-12 station"><i class="icofont-location-pin main-color"></i> {{project.station}}</div>-->
                                    <!--                                    </div>-->
                                </div>
                                <!--                                <div class="border-top w-100" style="margin: 10px 3px;"></div>-->
                                <div class="row mx-0 col-12 p-2 cursor-pointer w-100"
                                     (click)="goToDetail(project.uuid, $event)">
                                    <ul>
                                        <li>
                                            <div class="row mx-0">
                                                <div class="d-flex col-5 client-details-info-header">
                                                    <i class="icofont-yen-true ps-3"></i>
                                                    <div>月額</div>
                                                </div>
                                                <div class="col-7 client-details-info-body client-details-info-body-salary ps-3">
                                                    <div>{{format(project.price)}}円〜</div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="row mx-0">
                                                <div class="d-flex col-5 client-details-info-header">
                                                    <i class="icofont-sand-clock ps-3"></i>
                                                    <div>経験年数</div>
                                                </div>
                                                <div class="col-7 client-details-info-body ps-3">
                                                    <div *ngIf="project.experience != null && project.experience > 0"
                                                         style="padding-top: 1px">{{project.experience}}年〜
                                                    </div>
                                                    <div *ngIf="project.experience == null || project.experience <= 0"
                                                         style="padding-top: 1px">年数不問
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="row mx-0">
                                                <div class="d-flex col-5 client-details-info-header">
                                                    <i class="icofont-tags ps-3"></i>
                                                    <div>キーワード</div>
                                                </div>
                                                <div class="col-7 client-details-info-body ps-3">
                                                    <ng-container
                                                            *ngIf="project.tagList != null && project.tagList.length != 0">
                                                        <ng-container *ngFor="let tag of project.tagList">
                                                            <div class="project-list-tag">{{tag.content}}</div>
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-container
                                                            *ngIf="project.tagList == null || project.tagList.length == 0">
                                                        <div>--</div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="w-100 row mx-0">
                                    <div class="col-10">
                                        <div class="project-list-desc cursor-pointer" (click)="goToDetail(project.uuid, $event)"
                                             [innerText]="project.content">

                                        </div>
                                    </div>
                                    <div class="col-2 category-img align-self-center">
                                        <img *ngIf="project.category==null" src="assets/img/category/code.png"
                                             width="50" alt="Job">
                                        <img *ngIf="project.category!=null && project.category==10"
                                             src="assets/img/category/java.png" width="50" alt="Job">
                                        <img *ngIf="project.category!=null && project.category==20"
                                             src="assets/img/category/python.png" width="50" alt="Job">
                                        <img *ngIf="project.category!=null && project.category==30"
                                             src="assets/img/category/react.png" width="50" alt="Job">
                                        <img *ngIf="project.category!=null && project.category==40"
                                             src="assets/img/category/php.png" width="50" alt="Job">
                                        <img *ngIf="project.category!=null && project.category==50"
                                             src="assets/img/category/ios.svg" width="50" alt="Job">
                                        <img *ngIf="project.category!=null && project.category==60"
                                             src="assets/img/category/android.svg" width="50" alt="Job">
                                        <img *ngIf="project.category!=null && project.category==70"
                                             src="assets/img/category/angular.svg" width="50" alt="Job">
                                        <img *ngIf="project.category!=null && project.category==80"
                                             src="assets/img/category/net.png" width="50" alt="Job">
                                        <img *ngIf="project.category!=null && project.category==90"
                                             src="assets/img/category/aws.svg" width="50" alt="Job">
                                        <img *ngIf="project.category!=null && project.category==100"
                                             src="assets/img/category/vue.png" width="50" alt="Job">
                                        <img *ngIf="project.category!=null && project.category==999"
                                             src="assets/img/category/code.png" width="50" alt="Job">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="col-12">
                    <div class="common-btn mt-3 text-center">
                        <a class="login-btn me-0" (click)="goToList()" style="width: 200px !important;">もっと見る <i
                                class="icofont-swoosh-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-area" id="_about">
    <div class="about-right-bg" style="z-index: -1"><img src="./assets/img/home1/about-right-bg.png" alt=""></div>
    <div class="about-left-bg wow fadeInLeft" data-wow-duration="1.0s" data-wow-delay="0.3s" style="z-index: -1"><img
            src="./assets/img/home1/about-left-banner.png" alt=""></div>
    <div class="about-banner-contact wow fadeInRight" data-wow-duration="1.0s" data-wow-delay="0.3s"><img
            src="./assets/img/home1/about-contact-banner.png" alt=""></div>
    <div class="container">
        <div class="section-title">
            <h2>サービス</h2>
        </div>
        <div class="row">
            <div class="col-lg-6 col-12 desktop-container">
                <div class="about-all-boxes">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6 col-12 wow fadeInUp animated" data-wow-delay=".3s"
                             data-wow-duration="800ms">
                            <div class="about-box">
                                <div class="icon-box">
                                    <span class="color-1 icon"><i class="icofont-price"></i></span>
                                </div>
                                <div class="content">
                                    <h2 class="name">高単価</h2>
                                    <p class="text">過去のマッチング実績により企業からの信頼をいただけているため高単価での発注を実現できます。</p>
                                </div>
                            </div>
                            <div class="about-box">
                                <div class="icon-box">
                                    <span class="color-2 icon"><i class="icofont-checked"></i></span>
                                </div>
                                <div class="content">
                                    <h2 class="name">マッチング</h2>
                                    <p class="text">登録できるスキル数が充実しているので、自分のエンジニア経験を企業にアピールできます。</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-12 wow fadeInUp animated" data-wow-delay=".4s"
                             data-wow-duration="800ms">
                            <div class="about-box-martop80 mar-top-80 desktop-container"></div>
                            <div class="about-box">
                                <div class="icon-box">
                                    <span class="color-3 icon"><i class="icofont-lock"></i></span>
                                </div>
                                <div class="content">
                                    <h2 class="name">限定求人</h2>
                                    <p class="text">他では出会えない旬な求人が多数掲載されています。</p>
                                </div>
                            </div>
                            <div class="about-box">
                                <div class="icon-box">
                                    <span class="color-4 icon"><i class="icofont-support-faq"></i></span>
                                </div>
                                <div class="content">
                                    <h2 class="name">フォロー充実</h2>
                                    <p class="text">アサイン後のフォローアップなど、ご登録者を支えるフォローが充実しております。</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-7 col-12 d-flex align-items-center">
                <div class="banner-text mt-5">
                    <h4><span>Jobinder</span> がエンジニアに</h4>
                    <h1 class="ms-3">選ばれる <span>４つ</span> の理由</h1>

                    <p class="text black-color text-left">&nbsp;&emsp;Jobinderでは、フリーランスのITエンジニアの方々より多数(全登録者数)のご登録を頂いています。
                        多くの方々より選んでいただいているのは四つの強みがあるためです。ぜひこの機会にご登録ください。
                    </p>
                    <div class="common-btn mt-3 text-center desktop-container">
                        <a *ngIf="!isLogin" class="login-btn mx-3 my-1" routerLink="/signup"
                           style="width: 200px !important;">無料登録 <i class="icofont-swoosh-right"></i></a>
                        <a class="login-btn mx-3 my-1" (click)="goToList()" style="width: 200px !important;">案件を見る <i
                                class="icofont-swoosh-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-12 mobile-container">
                <div class="about-all-boxes">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6 col-12 wow fadeInUp animated" data-wow-delay=".3s"
                             data-wow-duration="800ms">
                            <div class="about-box row px-2 py-3">
                                <div class="icon-box col-3">
                                    <span class="color-1 icon"><i class="icofont-price"></i></span>
                                </div>
                                <div class="content col-9">
                                    <h2 class="name">高単価</h2>
                                    <p class="text">過去のマッチング実績により企業からの信頼をいただけているため高単価での発注を実現できます。</p>
                                </div>
                            </div>
                            <div class="about-box row px-2 py-3">
                                <div class="icon-box col-3">
                                    <span class="color-2 icon"><i class="icofont-checked"></i></span>
                                </div>
                                <div class="content col-9">
                                    <h2 class="name">マッチング</h2>
                                    <p class="text">登録できるスキル数が充実しているので、自分のエンジニア経験を企業にアピールできます。</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6 col-12 wow fadeInUp animated" data-wow-delay=".4s"
                             data-wow-duration="800ms">
                            <div class="about-box-martop80 mar-top-80 desktop-container"></div>
                            <div class="about-box row px-2 py-3">
                                <div class="icon-box col-3">
                                    <span class="color-3 icon"><i class="icofont-lock"></i></span>
                                </div>
                                <div class="content col-9">
                                    <h2 class="name">限定求人</h2>
                                    <p class="text">他では出会えない旬な求人が多数掲載されています。</p>
                                </div>
                            </div>
                            <div class="about-box row px-2 py-3">
                                <div class="icon-box col-3">
                                    <span class="color-4 icon"><i class="icofont-support-faq"></i></span>
                                </div>
                                <div class="content col-9">
                                    <h2 class="name">フォロー充実</h2>
                                    <p class="text">アサイン後のフォローアップなど、ご登録者を支えるフォローが充実しております。</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="banner-text mt-5 mobile-container">
                    <div class="common-btn text-center">
                        <a *ngIf="!isLogin" class="login-btn my-1 mx-1" routerLink="/signup">無料登録 <i
                                class="icofont-swoosh-right"></i></a>
                        <a class="login-btn my-1 mx-1" (click)="goToList()">案件を見る <i
                                class="icofont-swoosh-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--<section class="portfoio-area">-->
<!--    <div class="portfolio-right-banner"><img src="./assets/img/home1/portfolio-right-banner.png" alt=""></div>-->
<!--    <div class="container">-->
<!--        <div class="section-title">-->
<!--            <h2>特長</h2>-->
<!--        </div>-->
<!--        <div class="row justify-content-center">-->
<!--            <div class="col-lg-4 col-sm-6 col-12 wow fadeInUp" data-wow-delay=".3s" data-wow-duration="800ms">-->
<!--                <div class="single-portfolio">-->
<!--                    <div class="img-feature1">-->
<!--                        <a><img src="./assets/img/home/9.png" alt=""></a>-->
<!--                    </div>-->
<!--                    <div class="content">-->
<!--                        <h6 class="type">案件数</h6>-->
<!--                        <h2 class="name"><span class="main-color">100,000</span>&nbsp;件以上</h2>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="single-portfolio">-->
<!--                    <div class="img-feature4">-->
<!--                        <a><img src="./assets/img/home/2.png" alt=""></a>-->
<!--                    </div>-->
<!--                    <div class="content">-->
<!--                        <h6 class="type">平均年商</h6>-->
<!--                        <h2 class="name"><span class="main-color">810</span>&nbsp;万円</h2>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-lg-4 col-sm-6 col-12 wow fadeInUp" data-wow-delay="1.2s" data-wow-duration="800ms">-->
<!--                <div class="single-portfolio">-->
<!--                    <div class="img-feature2">-->
<!--                        <a><img src="./assets/img/home/11.png" alt=""></a>-->
<!--                    </div>-->
<!--                    <div class="content">-->
<!--                        <h6 class="type">案件所属</h6>-->
<!--                        <h2 class="name">上場企業&nbsp;<span class="main-color">45%</span></h2>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="single-portfolio">-->
<!--                    <div class="img-feature5">-->
<!--                        <a><img src="./assets/img/home/1.png" alt=""></a>-->
<!--                    </div>-->
<!--                    <div class="content">-->
<!--                        <h6 class="type">非公開</h6>-->
<!--                        <h2 class="name">案件&nbsp;<span class="main-color">75%</span>&nbsp;以上</h2>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-lg-4 col-sm-6 col-12 wow fadeInUp" data-wow-delay="600ms" data-wow-duration="800ms">-->
<!--                <div class="single-portfolio">-->
<!--                    <div class="img-feature3">-->
<!--                        <a><img src="./assets/img/home/10.png" alt=""></a>-->
<!--                    </div>-->
<!--                    <div class="content">-->
<!--                        <h6 class="type">還元率</h6>-->
<!--                        <h2 class="name"><span class="main-color">77%</span>&nbsp;以上</h2>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="single-portfolio">-->
<!--                    <div class="img-feature6">-->
<!--                        <a><img src="./assets/img/home/6.png" alt=""></a>-->
<!--                    </div>-->
<!--                    <div class="content">-->
<!--                        <h6 class="type">商談</h6>-->
<!--                        <h2 class="name">担当者と&nbsp;<span class="main-color">3回</span>&nbsp;以上</h2>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</section>-->

<section class="home2-facilities-area">
    <div class="portfolio-right-banner" style="z-index: -1"><img src="./assets/img/home1/portfolio-right-banner.png"
                                                                 alt=""></div>
    <div class="portfolio-left-banner"><img src="./assets/img/home1/supported-bg.png" alt=""></div>
    <div class="facilities-container">
        <div class="container desktop-container">
            <div class="section-title">
                <h2 class="mb-5">特長</h2>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 wow fadeIn animated" data-wow-delay=".3s"
                     data-wow-duration="800ms">
                    <div class="all-facilities">
                        <div class="single-facilities">
                            <div class="icon-box">
                                <span class="icon"><i class="icofont-medal"></i></span>
                            </div>
                            <div class="content">
                                <h2 class="title mt-0">魅力的な<span class="second-color">案件数</span>&nbsp;<span
                                        class="main-color">100,000</span>&nbsp;件以上</h2>
                                <p class="text">新しいトレンドの技術を取り入れている会社が多く、技術者として面白い案件が多いです。</p>
                            </div>
                        </div>
                        <div class="single-facilities">
                            <div class="icon-box">
                                <span class="icon"><i class="icofont-yen-plus"></i></span>
                            </div>
                            <div class="content">
                                <h2 class="title mt-0">SEエンジニア<span class="second-color">平均年商</span>&nbsp;<span
                                        class="main-color">810</span>&nbsp;万円</h2>
                                <p class="text">ネットワークを活用した独自の優良顧客との取引ができるため、単価交渉にも妥協せず、平均単価を高く維持しております。</p>
                            </div>
                        </div>
                        <div class="single-facilities">
                            <div class="icon-box">
                                <span class="icon"><i class="icofont-chart-histogram"></i></span>
                            </div>
                            <div class="content">
                                <h2 class="title mt-0">案件所属<span class="second-color">上場企業</span>&nbsp;<span
                                        class="main-color">45%</span>&nbsp;以上</h2>
                                <p class="text">
<!--                                    <a class="main-color" href="https://wisefocus.net" target="_blank">「wisefocus」</a>-->
<!--                                    から実現した圧倒的な、豊富な案件。-->
                                    しっかりと審査をして信頼性が高い企業からの案件をご提案します。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 wow fadeIn animated" data-wow-delay=".3s"
                     data-wow-duration="800ms">
                    <div class="all-facilities">
                        <div class="single-facilities">
                            <div class="icon-box">
                                <span class="icon"><i class="icofont-ssl-security"></i></span>
                            </div>
                            <div class="content">
                                <h2 class="title mt-0"><span class="second-color">非公開</span>案件&nbsp;<span
                                        class="main-color">75%</span>&nbsp;以上</h2>
                                <p class="text">サイトには公開していない非公開案件が多数。他では出会えない旬な求人が多数掲載されています。</p>
                            </div>
                        </div>
                        <div class="single-facilities">
                            <div class="icon-box">
                                <span class="icon"><i class="icofont-diamond"></i></span>
                            </div>
                            <div class="content">
                                <h2 class="title mt-0">安定的な<span class="second-color">還元率</span>&nbsp;<span
                                        class="main-color">77%</span>&nbsp;以上</h2>
                                <p class="text">フリーランスエンジニアの年収をUPさせるための仕組み作りを徹底しております。</p>
                            </div>
                        </div>
                        <div class="single-facilities">
                            <div class="icon-box">
                                <span class="icon"><i class="icofont-comment"></i></span>
                            </div>
                            <div class="content">
                                <h2 class="title mt-0">担当者と<span class="second-color">商談</span>&nbsp;<span
                                        class="main-color">3&nbsp;回</span>&nbsp;以上</h2>
                                <p class="text">書類が通過すると、企業との面談に進みます。面談にはクラウドテックのキャリアサポートが付き添い、3者面談を行います。</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container mobile-container">
            <div class="section-title">
                <h2 class="mb-3">特長</h2>
            </div>
            <div class="row all-facilities">
                <div class="col-lg-6 col-md-6 col-sm-12 col-3 text-center py-3 single-facilities">
                    <div class="icon-box">
                        <span class="icon"><i class="icofont-medal"></i></span>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 col-9 text-center pb-3 single-facilities">
                    <div class="content">
                        <h2 class="title mt-0">魅力的な<span class="second-color">案件数</span>&nbsp;<span class="main-color">100,000</span>&nbsp;件以上
                        </h2>
                        <p class="text">新しいトレンドの技術を取り入れている会社が多く、技術者として面白い案件が多いです。</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-3 text-center py-3 single-facilities">
                    <div class="icon-box">
                        <span class="icon"><i class="icofont-yen-plus"></i></span>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 col-9 text-center pb-3 single-facilities">
                    <div class="content">
                        <h2 class="title mt-0">SEエンジニア<span class="second-color">平均年商</span>&nbsp;<span
                                class="main-color">810</span>&nbsp;万円</h2>
                        <p class="text">ネットワークを活用した独自の優良顧客との取引ができるため、単価交渉にも妥協せず、平均単価を高く維持しております。</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-3 text-center py-3 single-facilities">
                    <div class="icon-box">
                        <span class="icon"><i class="icofont-chart-histogram"></i></span>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 col-9 text-center pb-3 single-facilities">
                    <div class="content">
                        <h2 class="title mt-0">案件所属<span class="second-color">上場企業</span>&nbsp;<span class="main-color">45%</span>&nbsp;以上
                        </h2>
                        <p class="text">
                            <a class="main-color" href="https://wisefocus.net" target="_blank">「wisefocus」</a>
                            から実現した圧倒的な、豊富な案件。しっかりと審査をして信頼性が高い企業からの案件をご提案します。
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-3 text-center py-3 single-facilities">
                    <div class="icon-box">
                        <span class="icon"><i class="icofont-ssl-security"></i></span>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 col-9 text-center pb-3 single-facilities">
                    <div class="content">
                        <h2 class="title mt-0"><span class="second-color">非公開</span>案件&nbsp;<span
                                class="main-color">75%</span>&nbsp;以上</h2>
                        <p class="text">サイトには公開していない非公開案件が多数。他では出会えない旬な求人が多数掲載されています。</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-3 text-center py-3 single-facilities">
                    <div class="icon-box">
                        <span class="icon"><i class="icofont-diamond"></i></span>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 col-9 text-center pb-3 single-facilities">
                    <div class="content">
                        <h2 class="title mt-0">安定的な<span class="second-color">還元率</span>&nbsp;<span class="main-color">77%</span>&nbsp;以上
                        </h2>
                        <p class="text">フリーランスエンジニアの年収をUPさせるための仕組み作りを徹底しております。</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-3 text-center py-3 single-facilities">
                    <div class="icon-box">
                        <span class="icon"><i class="icofont-comment"></i></span>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 col-9 text-center pb-3 single-facilities">
                    <div class="content">
                        <h2 class="title mt-0">担当者と<span class="second-color">商談</span>&nbsp;<span class="main-color">3&nbsp;回</span>以上
                        </h2>
                        <p class="text">書類が通過すると、企業との面談に進みます。面談にはクラウドテックのキャリアサポートが付き添い、3者面談を行います。</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home3-featuer-area">
    <div class="container desktop-container">
        <div class="section-title">
            <h2 class="mb-3">ご利用の流れ</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6 col-12 wow fadeInLeft animated category-border pt-5 home3-single-feature-con-1"
                 data-wow-delay=".3s" data-wow-duration="800ms">
                <div class="home3-single-feature">
                    <div class="icon-box">
                        <span class="icon color-1"><i class="icofont-user"></i></span>
                        <!--                        <div class="process-badge">-->
                        <!--                            <span>1</span>-->
                        <!--                        </div>-->
                    </div>
                    <div class="content">
                        <h2 class="title">アカウントを登録</h2>
                        <p class="text">外部連携（Google、Facebook）<br>または電子メールで早速に無料登録</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12 wow fadeInUp animated category-border pt-5 home3-single-feature-con-2"
                 data-wow-delay=".3s" data-wow-duration="800ms">
                <div class="home3-single-feature">
                    <div class="icon-box">
                        <span class="icon color-2"><i class="icofont-page"></i></span>
                    </div>
                    <div class="content">
                        <h2 class="title">履歴書アップロード</h2>
                        <p class="text">興味のある案件を応募する前に<br>個人情報と履歴書をアップロード必要</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12 wow fadeInRight animated category-border-three pt-5 home3-single-feature-con-3"
                 data-wow-delay=".3s" data-wow-duration="800ms">
                <div class="home3-single-feature">
                    <div class="icon-box">
                        <span class="icon color-3"><i class="icofont-star"></i></span>
                    </div>
                    <div class="content">
                        <h2 class="title">理想の仕事に応募</h2>
                        <p class="text">特に気になった案件があれば<br>ぜひ応募してください</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12 wow fadeInLeft animated category-border-two pt-5 home3-single-feature-con-4"
                 data-wow-delay=".3s" data-wow-duration="800ms">
                <div class="home3-single-feature">
                    <div class="icon-box">
                        <span class="icon color-4"><i class="icofont-comment"></i></span>
                    </div>
                    <div class="content">
                        <h2 class="title">トレーニング</h2>
                        <p class="text">必須知識や自身の改善点に気づく<br>ためのカリキュラムなどを演習</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12 wow fadeInUp animated category-border-two pt-5 home3-single-feature-con-5"
                 data-wow-delay=".3s" data-wow-duration="800ms">
                <div class="home3-single-feature">
                    <div class="icon-box">
                        <span class="icon color-5"><i class="icofont-handshake-deal"></i></span>
                    </div>
                    <div class="content">
                        <h2 class="title">商談&nbsp;&&nbsp;交渉成立</h2>
                        <p class="text">クライアントとの商談の場は、<br>専任担当が万全にサポート</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12 wow fadeInRight animated category-border-ten pt-5 home3-single-feature-con-6"
                 data-wow-delay=".3s" data-wow-duration="800ms">
                <div class="home3-single-feature">
                    <div class="icon-box">
                        <span class="icon color-6"><i class="icofont-support"></i></span>
                    </div>
                    <div class="content">
                        <h2 class="title">アフターサポート</h2>
                        <p class="text">作業開始後は皆様の業務が円滑に進むよう<br>専任担当が定期的にご状況をお伺い</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container mobile-container">
        <div class="section-title">
            <h2 class="mb-3">ご利用の流れ</h2>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6 col-12 category-border home3-single-feature-con-1">
                <div class="home3-single-feature row">
                    <div class="icon-box col-3">
                        <span class="icon color-1"><i class="icofont-user"></i></span>
                        <!--                        <div class="process-badge">-->
                        <!--                            <span>1</span>-->
                        <!--                        </div>-->
                    </div>
                    <div class="content col-9">
                        <h2 class="title">アカウントを登録</h2>
                        <p class="text">外部連携（Google、Facebook）<br>または電子メールで早速に無料登録</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12 category-border home3-single-feature-con-2">
                <div class="home3-single-feature row">
                    <div class="icon-box col-3">
                        <span class="icon color-2"><i class="icofont-page"></i></span>
                    </div>
                    <div class="content col-9">
                        <h2 class="title">履歴書アップロード</h2>
                        <p class="text">興味のある案件を応募する前に<br>個人情報と履歴書をアップロード必要</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12 category-border-three home3-single-feature-con-3">
                <div class="home3-single-feature row">
                    <div class="icon-box col-3">
                        <span class="icon color-3"><i class="icofont-star"></i></span>
                    </div>
                    <div class="content col-9">
                        <h2 class="title">理想の仕事に応募</h2>
                        <p class="text">特に気になった案件があれば<br>ぜひ応募してください</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12 category-border-two home3-single-feature-con-4">
                <div class="home3-single-feature row">
                    <div class="icon-box col-3">
                        <span class="icon color-4"><i class="icofont-comment"></i></span>
                    </div>
                    <div class="content col-9">
                        <h2 class="title">トレーニング</h2>
                        <p class="text">必須知識や自身の改善点に気づく<br>ためのカリキュラムなどを演習</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12 category-border-two home3-single-feature-con-5">
                <div class="home3-single-feature row">
                    <div class="icon-box col-3">
                        <span class="icon color-5"><i class="icofont-handshake-deal"></i></span>
                    </div>
                    <div class="content col-9">
                        <h2 class="title">商談&nbsp;&&nbsp;交渉成立</h2>
                        <p class="text">クライアントとの商談の場は、<br>専任担当が万全にサポート</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12 category-border-ten home3-single-feature-con-6">
                <div class="home3-single-feature row">
                    <div class="icon-box col-3">
                        <span class="icon color-6"><i class="icofont-support"></i></span>
                    </div>
                    <div class="content col-9">
                        <h2 class="title">アフターサポート</h2>
                        <p class="text">作業開始後は皆の業務が円滑に進む<br>専任担当が定期的にご状況をお伺い</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="banner-text mobile-container pt-2">
            <div class="common-btn text-center">
                <a *ngIf="!isLogin" class="login-btn my-1 mx-1" routerLink="/signup">無料登録 <i
                        class="icofont-swoosh-right"></i></a>
                <a class="login-btn my-1 mx-1" (click)="goToList()">案件を見る <i class="icofont-swoosh-right"></i></a>
            </div>
        </div>
    </div>
</section>

<div class="account-area account-area-two desktop-container">
    <div class="container px-0">
        <!--        <div class="section-title">-->
        <!--            <h2 class="text-white">ご利用の流れ</h2>-->
        <!--        </div>-->
        <div class="row account-wrap m-auto">
            <div class="col-lg-2 col-6 px-0">
                <div class="account-item">
                    <i class="icofont-user"></i>
                    <span>アカウントを登録</span>
                </div>
            </div>

            <div class="col-lg-2 col-6 px-0">
                <div class="account-item">
                    <i class="icofont-page"></i>
                    <span>履歴書アップロード</span>
                </div>
            </div>
            <div class="col-lg-2 col-6 px-0">
                <div class="account-item">
                    <i class="icofont-star"></i>
                    <span>理想の仕事に応募</span>
                </div>
            </div>

            <div class="col-lg-2 col-6 px-0">
                <div class="account-item">
                    <i class="icofont-comment"></i>
                    <span>トレーニング</span>
                </div>
            </div>

            <div class="col-lg-2 col-6 px-0">
                <div class="account-item">
                    <i class="icofont-handshake-deal"></i>
                    <span>TR&nbsp;&&nbsp;交渉成立</span>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-2 col-6 px-0">
                <div class="account-item account-last">
                    <i class="icofont-support"></i>
                    <span>アフターサポート</span>
                </div>
            </div>
        </div>

        <!--        <div class="banner-btn">-->
        <!--            <a routerLink="/profile">個人情報を編集</a>-->
        <!--            <a routerLink="/profile">履歴書アップロード</a>-->
        <!--        </div>-->
    </div>
</div>

<section class="category-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>希望の開発言語を選択してください</h2>
        </div>

        <div class="row">
            <div class="col-sm-3 col-lg-3 category-border">
                <div class="category-item" (click)="goToSearch(69)">
                    <img src="assets/img/category/java.png" alt="category-logo">
                    <!--                    <a>Java</a>-->
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border">
                <div class="category-item category-two" (click)="goToSearch(72)">
                    <img src="assets/img/category/python.png" alt="category-logo">
                    <!--                    <a>Python</a>-->
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border">
                <div class="category-item category-three" (click)="goToSearch(166)">
                    <img src="assets/img/category/react.png" alt="category-logo">
                    <!--                    <a>React</a>-->
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border-three">
                <div class="category-item category-five" (click)="goToSearch(68)">
                    <img src="assets/img/category/php.png" alt="category-logo">
                    <!--                    <a>PHP</a>-->
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border-two">
                <div class="category-item category-six" (click)="goToSearch(222)">
                    <img src="assets/img/category/ios.svg" alt="category-logo">
                    <!--                    <a>iOS</a>-->
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border-two" (click)="goToSearch(223)">
                <div class="category-item category-seven">
                    <img src="assets/img/category/android.svg" alt="category-logo">
                    <!--                    <a>Android</a>-->
                </div>
            </div>

            <div class="col-sm-3 col-lg-3 category-border-two">
                <div class="category-item category-eight" (click)="goToSearch(81)">
                    <img src="assets/img/category/typescript.svg" alt="category-logo">
                    <!--                    <a>TypeScript</a>-->
                </div>
            </div>

            <div class="col-sm-3 col-lg-3">
                <div class="category-item category-ten" (click)="goToSearch(0)">
                    <img src="assets/img/category/code.png" alt="category-logo">
                    <!--                    <a>その他</a>-->
                </div>
            </div>
        </div>
    </div>
</section>

<!--<div class="portal-area pb-70">-->
<!--    <div class="container">-->
<!--        <div class="row align-items-center">-->
<!--            <div class="col-lg-6">-->
<!--                <div class="portal-item">-->
<!--                    <div class="row">-->
<!--                        <div class="col-lg-7">-->
<!--                            <img src="assets/img/trust2.jpg" alt="Portal" class="portal-1">-->
<!--                        </div>-->

<!--                        <div class="col-lg-5">-->
<!--                            <img src="assets/img/trust1.jpg" alt="Portal" class="portal-2">-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="portal-trusted">-->
<!--                        <p><span>100% </span>信頼</p>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="col-lg-6">-->
<!--                <div class="portal-item portal-right">-->
<!--                    <h2>信頼できる人気のウェブサイト</h2>-->
<!--                    <p>自分に見合う求人を自分で探すシステムで、効率よく、成約率が高いです。<br>さらに、税抜き契約で単価がアップし、安心して契約することができます。</p>-->
<!--                    <div class="common-btn">-->
<!--                        <a class="login-btn" routerLink="/post-a-job">個人情報を編集 <i class="icofont-swoosh-right"></i></a>-->
<!--                        <a class="sign-up-btn" routerLink="/create-account">今すぐに応募 <i class="icofont-swoosh-right"></i></a>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<!--<div class="counter-area pt-120 pb-70">-->
<!--    <div class="container">-->
<!--        <div class="row">-->
<!--            <div class="col-6 col-sm-3 col-lg-3">-->
<!--                <div class="counter-item">-->
<!--                    <i class="flaticon-employee"></i>-->
<!--                    <h3><span class="counter">14</span>k+</h3>-->
<!--                    <p>求人求人</p>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="col-6 col-sm-3 col-lg-3">-->
<!--                <div class="counter-item">-->
<!--                    <i class="flaticon-curriculum"></i>-->
<!--                    <h3><span class="counter">18</span>k+</h3>-->
<!--                    <p>人材</p>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="col-6 col-sm-3 col-lg-3">-->
<!--                <div class="counter-item">-->
<!--                    <i class="flaticon-enterprise"></i>-->
<!--                    <h3><span class="counter">9</span>k+</h3>-->
<!--                    <p>会社</p>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="col-6 col-sm-3 col-lg-3">-->
<!--                <div class="counter-item">-->
<!--                    <i class="flaticon-businessman-paper-of-the-application-for-a-job"></i>-->
<!--                    <h3><span class="counter">350</span>+</h3>-->
<!--                    <p>成功ケース</p>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<!--<div class="popular-area pt-120 pb-70">-->
<!--    <div class="container">-->
<!--        <div class="row align-items-center">-->
<!--            <div class="col-lg-6">-->
<!--                <div class="popular-item">-->
<!--                    <div class="row align-items-center">-->
<!--                        <div class="col-lg-7">-->
<!--                            <img src="assets/img/home/popular-1.JPG" alt="Popular" class="popular-1">-->
<!--                        </div>-->

<!--                        <div class="col-lg-5">-->
<!--                            <div class="practice-inner">-->
<!--                                <img src="assets/img/home/popular-2.jpg" alt="Popular" class="popular-2">-->
<!--                                <img src="assets/img/home/popular-3.jpg" alt="Popular" class="popular-3">-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="col-lg-6">-->
<!--                <div class="popular-item popular-right">-->
<!--                    <div class="section-title text-start">-->
<!--                        <h2>私たちが最も人気がある理由</h2>-->
<!--                    </div>-->
<!--                    <p>自分に見合う求人を自分で探すシステムで、効率よく、成約率が高いです。<br>さらに、税抜き契約で単価がアップし、安心して契約することができます。</p>-->

<!--                    <div class="row popular-wrap">-->
<!--                        <div class="col-sm-6 col-lg-6">-->
<!--                            <ul>-->
<!--                                <li><i class="flaticon-approved"></i> 信頼できる質の高い仕事</li>-->
<!--                                <li><i class="flaticon-no-money"></i> 追加料金なし</li>-->
<!--                            </ul>-->
<!--                        </div>-->

<!--                        <div class="col-sm-6 col-lg-6">-->
<!--                            <ul>-->
<!--                                <li><i class="flaticon-enterprise"></i> トップ企業</li>-->
<!--                                <li><i class="flaticon-employee"></i> 国際的な仕事</li>-->
<!--                            </ul>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
