import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-talent-detail',
  templateUrl: './talent-detail.component.html',
  styleUrls: ['./talent-detail.component.scss']
})
export class TalentDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
