import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../../../service/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {StatusCode} from "../../../../form/status-code";
import {HeaderService} from "../../../../service/header.service";
import {CookieService} from "../../../../service/cookie.service";
import {LoginDialogComponent} from "../../../common/login-dialog/login-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import Swal from "sweetalert2";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  message!: string;
  message1!: string;
  message2!: string;
  mail!: string;
  password!: string;

  constructor(
      private authService: AuthService,
      public router: Router,
      private statusCode: StatusCode,
      private activatedRoute: ActivatedRoute,
      public  dialog: MatDialog,
      private headerService: HeaderService,
  ) {
    // const isLogin = this.headerService.getIsLoginEmitter();
    //
    // const user = this.authService.userValue;
    //
    // if(isLogin && user){
    //   this.router.navigate(['/']);
    // }
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      const status = params['status'];
      const uuid = params['uuid'];
      if (status === 'deny') {
        // this.message = '申し訳ありません。アカウントはまだ登録されていません。';
        // this.openDialog();
        Swal.fire({
          title: 'Error!',
          html: '申し訳ありません。<br>アカウントはまだ登録されていません。',
          icon: 'error',
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: '閉じる'
        })
      } else if (status === 'deny-fb') {
        // this.message = '申し訳ありません。アカウントはまだ登録されていません。';
        // this.openDialog();
        Swal.fire({
          title: 'Error!',
          html: '申し訳ありません。<br>アカウントはまだ登録されていません。',
          icon: 'error',
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: '閉じる'
        })
      } else if (status === 'withDrawal') {
        // this.message = '申し訳ありません。';
        // this.message1 = '該当アカウントは退会済みです。';
        // this.message2 = '再度ログインする場合は、運営者にご連絡ください。';
        // this.openDialog();
        Swal.fire({
          title: 'Error!',
          html: '申し訳ありません。<br>該当アカウントは退会済みです。<br>アカウントはまだ登録されていません。',
          icon: 'error',
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: '閉じる'
        })
      } else if (status === 'nonEffect') {
        // this.message = '申し訳ありません。';
        // this.message1 = '該当アカウントは強制退会です。';
        // this.message2 = '再度ログインする場合は、運営者にご連絡ください。';
        // this.openDialog();
        Swal.fire({
          title: 'Error!',
          html: '申し訳ありません。<br>該当アカウントは強制退会です。<br>アカウントはまだ登録されていません。',
          icon: 'error',
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: '閉じる'
        })
      }
    })
  }

  // openDialog(): void {
  //   const dialogRef = this.dialog.open(LoginDialogComponent, {
  //     // width: '400px',
  //     data: {
  //       // title: 'お知らせ',
  //       message: this.message,
  //       message1: this.message1,
  //       message2: this.message2,
  //     },
  //   });
  //   // dialogRef.afterOpened();
  // }

  // メールでログイン
  mailLoginSubmit(form: any): void {
    const loginForm = {
      username: form.mail,
      password: form.password
    };
    this.authService.postMailLogin(loginForm).subscribe((res: any) => {
      if (res.code === this.statusCode.SUCCESS) {
        this.headerService.emitIsLoginEvent(true);
        this.router.navigate(['/']).then(() => {
          window.location.reload();
          window.scrollTo(0, 0);
        });
      } else {
        // this.headerComponent.isLogin = false;
        this.headerService.emitIsLoginEvent(false);
        // this.message = '申し訳ありません。';
        // this.message1 = res.message;
        // this.message2 = '再度ログインする場合は、運営者にご連絡ください。';
        // this.openDialog();
        Swal.fire({
          title: 'Error!',
          html: '申し訳ありません。<br>ログインに失敗しました。<br>もう一度お試しください！',
          icon: 'error',
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: '閉じる'
        })
      }
    });
  }

  facebookLoginSubmit(): void{
    this.authService.facebookLoginSubmit();
  }

  googleLoginSubmit(): void{
    this.authService.googleLoginSubmit();
  }

  wechatLoginSubmit(): void{
    this.authService.wechatLoginSubmit();
  }

}
