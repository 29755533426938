import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {ResultService} from "../../../service/result.service";

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {

  result!: string;
  title!: string;
  message1!: string;
  message2!: string;
  message3!: string;
  message4!: string;
  message5!: string;
  uuid!: string;
  name!: string;

  constructor(
      private resultService: ResultService,
      private router: Router,
  ) { }

  ngOnInit(): void {

    this.result = this.resultService.result;
    this.title = this.resultService.title;
    this.message1 = this.resultService.message1;
    this.message2 = this.resultService.message2;
    this.message3 = this.resultService.message3;
    this.message4 = this.resultService.message4;
    this.message5 = this.resultService.message5;
    this.uuid = this.resultService.uuid;
    this.name = this.resultService.name;

    if (!this.result && !this.title && !this.message1 && !this.message2 && !this.message3 && !this.message4) {
      this.router.navigateByUrl('');
    }
  }

}
