import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StatusCode {
  // テータスコードの定義

  SUCCESS = 0;
  TOKEN_EXPIRE = 'SU0108';
  COM_DATA_NOT_FOUND = 'SU0102';
  AT_NOT_LOGIN = 'AT1001';
  AUTH_REGISTER_METHOD_ADD = 'AT1004';
  AUTH_ACCOUNT_DUAL_MESSAGE = 'AT0001';
  AUTH_ACCOUNT_INVALID_MESSAGE = 'AT0002';
  ATH_ACCOUNT_STATUS_FAIL_MESSAGE = 'AT0004';
  AUTH_ACCOUNT_UNDER_REVIEW = 'AT0007';
  ERROR_CODE = 'SU9999';
}
