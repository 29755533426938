import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {PasswordSetForm} from "../form/password-set-form";

interface ResJSON {
    statusCode: string;
    resultList: { token: string };
    message: string;
}

@Injectable({
    providedIn: 'root'
})
export class PasswordSetService extends BaseService {

    constructor(
        private http: HttpClient
    ) {
        super();
        // `Authorization` に `Bearer トークン` をセットする
        this.setAuthorization('c3RzZXJ2aWNlOmZZenJleWc1c2E0UQ==');
    }

    public setAuthorization(token: string): void {
        if (!token) {
            return;
        }
        const bearerToken = `Bearer ${token}`;
        this.httpOptions.headers = this.httpOptions.headers.set('Authorization', bearerToken);
    }

    getOneTimeToken(token: string): Observable<any> {
        return this.http.get<string>(this.API_PATH.AUTH_PASSWORD_SET + `${token}/`, this.httpOptions)
            .pipe(
                map((response: any) => {
                    const result = response as ResJSON;
                    return result;
                })
            );
    }

    getResetOneTimeToken(token: string): Observable<any> {
        return this.http.get<string>(this.API_PATH.AUTH_PASSWORD_RESET + `${token}/`, this.httpOptions)
            .pipe(
                map((response: any) => {
                    const result = response as ResJSON;
                    return result;
                })
            );
    }

    // パスワードを設定
    postSetPassword(form: PasswordSetForm): any {
        return this.http.post(this.API_PATH.AUTH_PASSWORD_SET, form, this.httpOptions)
            .pipe(
                map((response: any) => {
                    const result = response as ResJSON;
                    return result;
                })
            );
    }

    // パスワードを再設定
    postResetPassword(form: PasswordSetForm): any {
        return this.http.post(this.API_PATH.AUTH_PASSWORD_RESET, form, this.httpOptions)
            .pipe(
                map((response: any) => {
                    const result = response as ResJSON;
                    return result;
                })
            );
    }
}
