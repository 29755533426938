import {Component, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild} from '@angular/core';
import {User} from "../../../form/profile";
import {isPlatformBrowser} from "@angular/common";
import {HttpClient} from "@angular/common/http";
import {StatusCode} from "../../../form/status-code";
import {HeaderService} from "../../../service/header.service";
import {AuthService} from "../../../service/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CookieService} from "../../../service/cookie.service";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  isLogin!: boolean;
  user!: User;

  showName = "";

  constructor(
      public router: Router,
      public activatedRoute: ActivatedRoute,
      private renderer: Renderer2,
      private authService: AuthService,
      private service: HeaderService,
      private statusCode: StatusCode,
      private httpClient: HttpClient,
      private cookieService: CookieService,
      @Inject(PLATFORM_ID) private platformId: any
  ) {
  }

  ngOnInit(): void {

    this.isLogin = !!this.cookieService.getCookie("jb_user");

    const jb_user = this.cookieService.getCookie('jb_user');
    if(jb_user){
      this.authService.getName().subscribe( res => {
        this.showName = res.data;
      })
    }else {
      this.showName = '';
    }

    // const jb_user = this.cookieService.getCookie('jb_user');

    // if (isPlatformBrowser(this.platformId)) {
    //   if (!localStorage.getItem('user')) {
    //     if(jb_user != null && jb_user != ''){
    //       this.authService.getProfile(jb_user).subscribe(res => {
    //         this.showName = res.user.showName;
    //         this.user = res.user;
    //         this.isLogin = true;
    //         this.service.emitIsLoginEvent(true);
    //       });
    //     }else{
    //       this.isLogin = false;
    //       this.service.emitIsLoginEvent(false);
    //     }
    //   }else{
    //     this.getUserInfo();
    //   }
    // }
  }

  getUserInfo(): void{
    const user = this.authService.userValue;
    if (user) {
      this.user = user;
      this.isLogin = true;
      this.showName = this.user.showName;
      this.service.emitIsLoginEvent(true);
    } else {
      this.isLogin = false;
      this.service.emitIsLoginEvent(false);
    }
  }

  logout(): void {
    this.cookieService.deleteCookie('uid');
    this.cookieService.deleteCookie('jb_user');

    this.authService.logout()
    //     .subscribe(() => {
    //   this.router.navigate(['/']).then(() => {
    //     this.service.emitIsLoginEvent(false);
    //     window.location.reload();
    //     this.ngOnInit();
    //   });
    // });
  }

  toRenew(): void{
    this.router.navigate(['/project']).then(() => {
      window.location.reload();
    })
  }

  toProfile(event: any): void{
    if((event.ctrlKey && !event.metaKey) || (!event.ctrlKey && event.metaKey)){
      const url = this.router.serializeUrl(this.router.createUrlTree(['/profile',{page: 'isProfile'}]));
      window.open(url, '_blank');
    }else{
      this.router.navigate(['/profile',{page: 'isProfile'}]).then(() => {
        window.location.reload();
        window.scrollTo(0, 0);
      });
    }
  }
}
