<!--<div class="job-details-area pt-120 pt-80-sp">-->
<!--    <div class="container">-->
<!--        <div class="row justify-content-center">-->
<!--            <div class="col-lg-4">-->
<!--                <div class="client-details-item">-->
<!--                    <div class="client-overview">-->
<!--                        <img class="px-0" src="assets/img/slabhp.png">-->
<!--                        <p>-->
<!--                            <i class="icofont-location-pin main-color"></i>-->
<!--                            東京都千代田区 岩本町2-15-8-501-->
<!--                        </p>-->
<!--                        <div class="border-top" style="margin: 16px 3px;"></div>-->
<!--                        <div class="client-ul">-->
<!--                            <h4>-->
<!--                                企業概要-->
<!--                            </h4>-->
<!--                            <ul>-->
<!--                            </ul>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-lg-8">-->
<!--                <div class="job-details-item">-->
<!--                    <div class="job-description">-->
<!--                        <div class="row">-->
<!--                            <img class="px-0" src="assets/img/jobinder-icon.png"-->
<!--                                 style="width: 100px;box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;border-radius: 10px">-->
<!--                            <div class="w-70 mb-0 align-self-center">-->
<!--                                <h2 class="mb-2">株式会社エスラボ</h2>-->
<!--                                <h6 class="mb-0">自分に見合う求人を自分で探すシステムで、効率よく、成約率が高いです。-->
<!--                                    さらに税抜き契約で単価アップしますし、契約書の締結で安心して取引できます。</h6>-->
<!--                            </div>-->

<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="job-knowledge">-->

<!--                        <ul>-->
<!--                            <li><i class="icofont-hand-drawn-right"></i> 事業内容：</li>-->
<!--                            <li><i class="icofont-hand-drawn-right"></i> 業種：</li>-->
<!--                            <li><i class="icofont-hand-drawn-right"></i> 会社特徴：</li>-->

<!--                            <li><i class="icofont-hand-drawn-right"></i> </li>-->

<!--                            <li><i class="icofont-hand-drawn-right"></i> 弊社担当者より：</li>-->
<!--                        </ul>-->
<!--                    </div>-->

<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<div class="single-profile-area pt-120 pt-80-sp pb-70">
    <div class="container">
        <div class="row align-items-start">
            <div class="col-lg-4 client-details-info">
                <div class="single-profile-item">
<!--                    <img src="assets/img/slabhp.png" alt="Profile">-->
                    <ngb-carousel
                        #carousel
                        [interval]="3000"
                        [pauseOnHover]="true"
                        [pauseOnFocus]="true"
                    >
                        <ng-template ngbSlide *ngFor="let img of images; index as i">
                            <img [src]="img" alt="company {{i + 1}} description"/>
                        </ng-template>
                    </ngb-carousel>

                    <div class="single-profile-left">

                        <div class="mobile-container">
                            <h2>{{client.agentName}}</h2>
                            <p>{{client.agentDesc}}</p>
                            <div class="common-btn">
                                <a class="sign-up-btn" [href]="client.officialUrl" target="_blank">公式サイトへ <i class="icofont-web"></i></a>
<!--                                <a class="sign-up-btn" routerLink="/">お気に入り <i class="icofont-heart"></i></a>-->
                            </div>
                        </div>

                        <div class="single-profile-social">
                            <h3>企業概要</h3>

                            <ul>
                                <li>
                                    <div class="row mx-0">
                                        <div class="d-flex col-4 client-details-info-header">
                                            <i class="icofont-google-map"></i>
                                            <div>地域</div>
                                        </div>
                                        <div class="col-8 client-details-info-body">
                                            <div>{{client.area}}</div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="row mx-0">
                                        <div class="d-flex col-4 client-details-info-header">
                                            <i class="icofont-calendar"></i>
                                            <div>設立年月</div>
                                        </div>
                                        <div class="col-8 client-details-info-body">
                                            <div>{{client.establishDate}}</div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="row mx-0">
                                        <div class="d-flex col-4 client-details-info-header">
                                            <i class="icofont-ui-user-group"></i>
                                            <div>従業員数</div>
                                        </div>
                                        <div class="col-8 client-details-info-body">
                                            <div>{{client.employeeCount}}</div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="row mx-0">
                                        <div class="d-flex col-4 client-details-info-header">
                                            <i class="icofont-money-bag"></i>
                                            <div>資本金</div>
                                        </div>
                                        <div class="col-8 client-details-info-body">
                                            <div>{{format(client.capital)}}万円</div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="row mx-0">
                                        <div class="d-flex col-4 client-details-info-header">
                                            <i class="icofont-gavel"></i>
                                            <div>代表者</div>
                                        </div>
                                        <div class="col-8 client-details-info-body">
                                            <div>{{client.director}}</div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="single-profile-contact">
                            <h3>連絡情報</h3>

                            <ul>

                                <li>
                                    <div class="row mx-0">
                                        <div class="d-flex col-4 client-details-info-header">
                                            <i class="icofont-phone"></i>
                                            <div>電話番号</div>
                                        </div>
                                        <div class="col-8 client-details-info-body">
                                            <div>{{client.tel}}</div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="row mx-0">
                                        <div class="d-flex col-4 client-details-info-header">
                                            <i class="icofont-email"></i>
                                            <div>メール</div>
                                        </div>
                                        <div class="col-8 client-details-info-body">
                                            <div>{{client.mail}}</div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="row mx-0">
                                        <div class="d-flex col-4 client-details-info-header">
                                            <i class="icofont-ui-map"></i>
                                            <div>住所</div>
                                        </div>
                                        <div class="col-8 client-details-info-body">
                                            <div>{{client.address}}</div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-lg-8">
                <div class="single-profile-item">
                    <div class="single-profile-right">
                        <div class="single-profile-name desktop-container">
                            <h2>{{client.agentName}}</h2>
                            <p>{{client.agentDesc}}</p>
                            <div class="common-btn">
                                <a class="sign-up-btn" [href]="client.officialUrl" target="_blank">公式サイトへ <i class="icofont-web"></i></a>
<!--                                <a class="sign-up-btn" routerLink="/">お気に入り <i class="icofont-heart"></i></a>-->
                            </div>
                        </div>

                        <div class="single-profile-textarea">
                            <div class="single-profile-heading">
                                <span></span>
                                <h3>事業内容<label>Business Description</label></h3>
                            </div>

                            <div class="single-profile-paragraph">
                                <p class="single-profile-p" [innerText]="client.businessDesc"></p>
                            </div>

                            <div class="single-profile-heading">
                                <span></span>
                                <h3>業種<label>Industry</label></h3>
                            </div>

                            <div class="single-profile-paragraph">
                                <p class="single-profile-p" [innerText]="client.industry"></p>
                            </div>

                            <div class="single-profile-heading">
                                <span></span>
                                <h3>会社特徴<label>Company Features</label></h3>
                            </div>

                            <div class="single-profile-paragraph">

                                <p class="single-profile-p" [innerText]="client.companyFeature"></p>
                            </div>

                            <div class="single-profile-heading">
                                <span></span>
                                <h3>弊社担当者より<label>Comment From Us</label></h3>
                            </div>

                            <div class="single-profile-paragraph">
                                <p class="single-profile-p" [innerText]="client.manageComment"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
