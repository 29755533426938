import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { ProjectListComponent } from './components/pages/project-list/project-list.component';
import { ProjectDetailComponent } from './components/pages/project-detail/project-detail.component';
import { TalentDetailComponent } from './components/pages/talent-detail/talent-detail.component';
import { TalentListComponent } from './components/pages/talent-list/talent-list.component';
import { NgbCarouselModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {LoginComponent} from "./components/pages/auth/login/login.component";
import {ProfileComponent} from "./components/pages/profile/profile.component";
import {LoginDialogComponent} from "./components/common/login-dialog/login-dialog.component";
import {FormsModule} from "@angular/forms";
import { SignupComponent } from './components/pages/auth/signup/signup.component';
import { PasswordSetComponent } from './components/pages/auth/password-set/password-set.component';
import { PasswordResetComponent } from './components/pages/auth/password-reset/password-reset.component';
import { PasswordForgetComponent } from './components/pages/auth/password-forget/password-forget.component';
import { ResultComponent } from './components/pages/result/result.component';
import {MatSelectModule} from "@angular/material/select";
import {MatOptionModule} from "@angular/material/core";
import {NgSelectModule} from "@ng-select/ng-select";
import {FileUploadModule} from "primeng/fileupload";
import {MatIconModule} from "@angular/material/icon";
import { HomeComponent } from './components/pages/home/home.component';
import { TermComponent } from './components/pages/term/term.component';
import { PrivacyComponent } from './components/pages/privacy/privacy.component';
import { CookieComponent } from './components/pages/cookie/cookie.component';
import { PersonalProtectionComponent } from './components/pages/personal-protection/personal-protection.component';
import {MatDialogModule} from "@angular/material/dialog";
import { LoginSuccessComponent } from './components/common/login-success/login-success.component';
import {MatExpansionModule} from "@angular/material/expansion";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatButtonModule} from "@angular/material/button";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatTooltipModule} from "@angular/material/tooltip";
import { ClientDetailComponent } from './components/pages/client-detail/client-detail.component';
import {AuthInterceptor} from "./helper/auth.interceptor";
import {AccordionModule} from 'primeng/accordion';
import {TimelineModule} from 'primeng/timeline';
import {CardModule} from 'primeng/card';
import {PanelModule} from "primeng/panel";
import {DialogModule} from "primeng/dialog";
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {ScrollPanelModule} from "primeng/scrollpanel";
import {CountUpModule} from "ngx-countup";
import {PaginatorModule} from "primeng/paginator";
import {ChipModule} from "primeng/chip";
import { DropdownModule } from 'primeng/dropdown';
import { TipsComponent } from './components/pages/tips/tips.component';
import { EventComponent } from './components/pages/event/event.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TableModule } from 'primeng/table';
import {NgwWowModule} from "ngx-wow";
import {SwiperModule} from "swiper/angular";
import {NgxMaskModule} from "ngx-mask";

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    PreloaderComponent,
    ProjectListComponent,
    ProjectDetailComponent,
    TalentDetailComponent,
    TalentListComponent,
    LoginComponent,
    ProfileComponent,
    LoginDialogComponent,
    SignupComponent,
    PasswordSetComponent,
    PasswordResetComponent,
    PasswordForgetComponent,
    ResultComponent,
    HomeComponent,
    TermComponent,
    PrivacyComponent,
    CookieComponent,
    PersonalProtectionComponent,
    LoginSuccessComponent,
    ClientDetailComponent,
    TipsComponent,
    EventComponent
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NgbModule,
        HttpClientModule,
        FormsModule,
        MatSelectModule,
        MatOptionModule,
        NgSelectModule,
        FileUploadModule,
        MatIconModule,
        MatDialogModule,
        MatExpansionModule,
        MatButtonModule,
        MatSidenavModule,
        MatTooltipModule,
        NgbCarouselModule,
        AccordionModule,
        TimelineModule,
        CardModule,
        PanelModule,
        DialogModule,
        InputTextModule,
        InputTextareaModule,
        TieredMenuModule,
        ScrollPanelModule,
        CountUpModule,
        PaginatorModule,
        SweetAlert2Module.forRoot(),
        ChipModule,
        TableModule,
        NgwWowModule,
        SwiperModule,
        DropdownModule,
        NgxMaskModule.forRoot()
    ],
  providers: [
      {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true
      }
      ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
