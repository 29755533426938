import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';

@Injectable({
    providedIn: 'root'
})
export class ResultService extends BaseService {

    private _result!:　string;
    private _title!:　string;
    private _message1!: string;
    private _message2!: string;
    private _message3!: string;
    private _message4!: string;
    private _message5!: string;
    private _uuid!: string;
    private _name!: string;

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get message2(): string {
        return this._message2;
    }

    set message2(value: string) {
        this._message2 = value;
    }

    get message5(): string {
        return this._message5;
    }

    set message5(value: string) {
        this._message5 = value;
    }

    get message3(): string {
        return this._message3;
    }

    set message3(value: string) {
        this._message3 = value;
    }

    get message4(): string {
        return this._message4;
    }

    set message4(value: string) {
        this._message4 = value;
    }

    get uuid(): string {
        return this._uuid;
    }

    set uuid(value: string) {
        this._uuid = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get message1(): string {
        return this._message1;
    }

    set message1(value: string) {
        this._message1 = value;
    }

    get result(): string {
        return this._result;
    }

    set result(value: string) {
        this._result = value;
    }

    constructor(
        private http: HttpClient
    ) {
        super();
    }

}
