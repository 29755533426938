<!--<div class="page-title-area">-->
<!--    <div class="d-table">-->
<!--        <div class="d-table-cell">-->
<!--            <div class="container">-->
<!--                <div class="page-title-text">-->
<!--                    <h2>ログイン</h2>-->
<!--                    <ul>-->
<!--                        <li><a routerLink="/">ホーム</a></li>-->
<!--                        <li><i class="icofont-simple-right"></i></li>-->
<!--                        <li>ログイン</li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<div class="login-area pt-170">
    <div class="container w-25-container bg-white p-40">

        <div class="">
            <button class="facebookSignIn w-100" (click)="facebookLoginSubmit()">
                <div class="w-15">
                    <img src="../../../assets/img/f_logo_RGB-White_1024.png" width="30px" height="30px" class="facebook-icon" alt="Facebook logo">
                </div>
                <div class="w-70">
                    <span class="facebookSignIn_text w-85" translate>Facebook でログイン</span>
                </div>
            </button>
        </div>

        <div class="pt-4 pb-2">
            <button class="googleSignIn w-100" (click)="googleLoginSubmit()">
                <div class="w-15">
                    <img src="../../../assets/img/btn_google_light_normal_ios.svg" width="auto" height="32px" class="google-icon" alt="Google logo">
                </div>
                <div class="w-70">
                    <span class="googleSignIn_text w-85" translate>Google でログイン</span>
                </div>
            </button>
        </div>

<!--        <div class="pb-2">-->
<!--            <button class="wechatSignIn w-100" (click)="wechatLoginSubmit()">-->
<!--                <div class="w-15">-->
<!--                    <img src="../../../assets/img/WeChat-Icon-White-Logo.wine.svg" width="auto" height="32px" class="wechat-icon" alt="WeChat logo">-->
<!--                </div>-->
<!--                <div class="w-70">-->
<!--                    <span class="wechatSignIn_text w-85" translate>WeChat でログイン</span>-->
<!--                </div>-->
<!--            </button>-->
<!--        </div>-->

        <div class="or-line" >
            <span class="font-size-3 login-title-text-grey" translate>または</span>
        </div>

<!--        <div class="pt-2 mb-4 text-center">-->
<!--            <a class="mail-login">-->
<!--                <span class="mail-login login-title-text-grey" translate>メールで ログイン</span>-->
<!--            </a>-->
<!--        </div>-->

        <form #f="ngForm">
            <div class="form-group mb-0">
                <label class="font-size-3 font-weight-600 mb-0 login-title-text-grey" translate>メールアドレス</label>
                <input type="email" required="required" name="mail" [(ngModel)]="mail"
                       class="form-control input-height ng-untouched ng-pristine ng-invalid">
            </div>
            <div class="form-group mb-0 py-4">
                <label class="font-size-3 font-weight-600 mb-0 login-title-text-grey" translate>パスワード</label>
                <input type="password"  required="required" name="password" [(ngModel)]="password" #psd="ngModel"
                       pattern="^((?=.*[A-Z])(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*\d)(?=.*[\~\!\@\#\$\%\^\&amp;\*\?\_\-\.])|(?=.*[A-Z])(?=.*\d)(?=.*[\~\!\@\#\$\%\^\&amp;\*\?\_\-\.])|(?=.*[A-Z])(?=.*[a-z])(?=.*[\~\!\@\#\$\%\^\&amp;\*\?\_\-\.])).{8,}$"
                       class="form-control input-height ng-untouched ng-pristine ng-invalid">
            </div>
        </form>

<!--        <div class="text-center">-->
<!--            <button type="button" (click)="mailLoginSubmit(f.value)" class="btn login-btn">ログイン</button>-->
<!--        </div>-->

        <div class="login-page-btn">
            <a (click)="mailLoginSubmit(f.value)" class="login-page-common-btn">ログイン</a>
        </div>

        <div class="login-sign-in">
            <a routerLink="/password/forget">パスワードをお忘れですか？</a>

            <ul>
                <li>アカウントを持っていませんか ?</li>
                <br>
                <li><a routerLink="/signup">今すぐに登録</a></li>
            </ul>
        </div>
    </div>
</div>
