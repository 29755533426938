import {EventEmitter, Injectable, Output} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {StatusCode} from '../form/status-code';
import {ProfileForm, User} from "../form/profile";
import {RegisterForm} from "../form/register";
import {Login} from "../form/login";
import {CookieService} from "./cookie.service";
import {Router} from "@angular/router";

interface ResJSON {
    statusCode: string;
    message: string;
    resultList: { uid: string, sid: string };
}

interface ResetResJSON {
    statusCode: string;
    resultList: { token: string };
    message: string;
}

@Injectable({
    providedIn: 'root'
})
export class AuthService extends BaseService {

    private userSubject?: BehaviorSubject<User | null>;

    constructor(
        private cookieService: CookieService,
        private http: HttpClient,
        private statusCode: StatusCode,
        public router: Router,
    ) {
        super();
        // if (this.cookieService.getCookie("jb_user")) {
        //     this.isLogin.emit(true);
        // } else {
        //     this.isLogin.emit(false)
        // }
        // this.setAuthorization('c3RzZXJ2aWNlOmZZenJleWc1c2E0UQ==');
        // this.userSubject = new BehaviorSubject<User | null>(JSON.parse(localStorage.getItem('user') as string));
    }

    public setAuthorization(token: string): void {
        if (!token) {
            return;
        }
        const bearerToken = `Bearer ${token}`;
        this.httpOptions.headers = this.httpOptions.headers.set('Authorization', bearerToken);
    }

    // mail登録
    postRegister(form: RegisterForm): any {
        return this.http.post(this.API_PATH.AUTH_MAIL_REGISTER, form, this.httpOptions)
            .pipe(
                catchError(this.handleError<string>(`mail register failure`))
            );
    }

    // mail登録
    postTrueRegister(mail: string): any {
        return this.http.post(this.API_PATH.AUTH_MAIL_TRUE_REGISTER, mail, this.httpOptions)
            .pipe(
                catchError(this.handleError<string>(`mail register failure`))
            );
    }

    /**
     * mailログイン
     */
    postMailLogin(loginForm: Login): any {
        return this.http.post(this.API_PATH.AUTH_MAIL_LOGIN, loginForm, this.httpOptions)
            .pipe(map((res: any) => {
                if (res.code == 0) {
                    this.cookieService.setCookie('jb_user', res.data, 10);
                }
                return res;
            }));
    }

    /**
     * チップ変更
     */
    changeTipsFlg(): any {
        return this.http.post(this.API_PATH.AUTH_TIPS_FLG_CHANGE, null, this.httpOptions)
            .pipe(
                catchError(this.handleError(`logout error`))
            );
    }

    // パスワード再設定リンクを送信
    sendPasswordResetLink(mail: string): Observable<any> {
        return this.http.post(this.API_PATH.AUTH_MAIL_PASSWORD_RESET_LINK, mail, this.httpOptions)
            .pipe(
                map((response: any) => {
                    const result = response as ResetResJSON;
                    return result;
                })
            );
    }

    getProfile(): Observable<any> {
        return this.http.get(this.API_PATH.PROFILE, this.httpOptions)
            .pipe(
                map((response: any) => {
                    if (response.code === this.statusCode.SUCCESS) {
                        if(response.data.user != null){
                            localStorage.setItem('user', JSON.stringify(response.data.user));
                        }
                        if(response.data.profileExist){
                            localStorage.setItem('profileForm', JSON.stringify(response.data.profileForm));
                        }
                        this.userSubject?.next(response.data);
                    }
                    return response;
                })
            );

    }

    getName(): Observable<any>{
        return this.http.get(this.API_PATH.PROFILE_NAME, this.httpOptions)
            .pipe(
                map((response: any) => {
                    if (response.code === this.statusCode.SUCCESS) {
                        localStorage.removeItem("user_name");
                        localStorage.setItem('user_name', response.data);
                    }
                    return response;
                })
            );
    }

    // google login submit
    googleLoginSubmit(): void {
        window.location.href = this.API_PATH.AUTH_GOOGLE_LOGIN;
    }

    // facebook login submit
    facebookLoginSubmit(): void {
        window.location.href = this.API_PATH.AUTH_FACEBOOK_LOGIN;
    }

    // wechat login submit
    wechatLoginSubmit(): void {
        window.location.href = this.API_PATH.AUTH_WECHAT_LOGIN;
    }

    /**
     * get user information
     */
    public get userValue(): User | null | undefined {
        return this.userSubject?.value;
    }

    /**
     * get login status
     */
    public get isLoggedIn(): boolean {
        if (this.userSubject?.value) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * ログアウト
     */
    logout() {
        this.cookieService.deleteCookie("jb_user");
        localStorage.removeItem("user_name");
        localStorage.removeItem("user");

        this.router.navigate(['/login']).then(() => {
            window.location.reload()
        });
    }

    tokenExpired(token: string | undefined): boolean {
        if (token !== undefined) {
            const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
            return (Math.floor((new Date()).getTime() / 1000)) >= expiry;
        } else {
            return true;
        }
    }
}
