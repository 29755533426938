<section class="section pt-120 pb-100">
    <div class="container web-article">
        <div class="row">
            <div class="col-12 mt-md-0 mt-5">
                <h1 class="text-uppercase title my-2">利用規約</h1>
            </div>
            <div class="col-lg-12 mt-4 pt-2">
                <p class="text-muted">Jobinder（運営：株式会社エスラボ）（以下「当社」という）が提供する職業紹介サービス・プラットフォーム（以下「本サービス」という）をご利用になる方（以下「利用者」という）は、以下の利用規約（以下「本規約」という）についてご承諾のうえでご利用をお願いいたします。 </p>
                <h5>第１条（求人情報及び求人者情報ネットワークサービス）</h5>
                <p class="text-muted ps-4">当社が運営する本サービスとは、利用者の方の情報に対して適性に合致すると思われる求人案件・求職者情報をご提供、付随する電子メールサービスを通じて行う各種情報提供、その他の求人及び求職者情報提供サービスの総称をいいます。</p>
                <h5>第２条（本サービスの利用）</h5>
                <p class="text-muted ps-4">利用者は、本サービス利用を、当社が指定した方法によりなすものとし、当社が指定していない方法はできないものとします。</p>
                <p class="text-muted ps-4">利用者は、本サービスの利用にあたって本規約の内容をすべて承諾したものとみなされます。不承諾の意思表示は、本サービスを利用しないことをもってのみ認められるものとします。</p>
                <p class="text-muted ps-4">当社は、本サービス提供することが不適切と判断したときは、本サービスの利用をお断りすることができるものとします。</p>
                <h5>第３条（本サービスの提供・基準）</h5>
                <p class="text-muted ps-4">当社は、以下の中から利用者に適切なものを当社の判断で提供するものとします。</p>
                <p class="text-muted ps-4">(1) 求人情報提供</p>
                <p class="text-muted ps-4">(2) 求職者情報提供</p>
                <p class="text-muted ps-4">(3) 外国人の在留資格申請に伴うリーガルチェック情報提供</p>
                <p class="text-muted ps-4">(4) 求人及び求職支援情報提供</p>
                <p class="text-muted ps-4">(5) 動画管理・配信サービス提供</p>
                <p class="text-muted ps-4">その他利用者の求人及び求職活動に有益と当社が判断としたサービス</p>
                <h5>第４条（免責）</h5>
                <p class="text-muted ps-4">本サービスを利用すること、または利用できないことから生じる一切の損害について、当社は故意または重過失がない限り一切の責任を負わないものとします。</p>
                <p class="text-muted ps-4">当社は、本サービスを通じて第三者が提供するサービスが利用者に与える損害につき、一切責任を負わないものとします。</p>
                <p class="text-muted ps-4">当社は、天変地異等の不可抗力による利用者の被害、その他当社の責めによらない事由による利用者の被害が生じたときは、その一切の責任を負わないものとします。</p>
                <p class="text-muted ps-4">当社は、本サービスにおけるデータが消去・変更されないことを保証いたしません。</p>
                <p class="text-muted ps-4">当社は、当社および求人企業の機密に属する事項についての質問等への回答、その他対応等は一切行いません。</p>
                <p class="text-muted ps-4">当社は、求職者に対して必ず求人案件を紹介すること、および利用者の転職活動が必ず成功することは、保証いたしません。</p>
                <h5>第５条（本サービス利用終了）</h5>
                <p class="text-muted ps-4">当社は、利用者が以下に該当すると判断した場合は、利用者に対して何らの催告を要することなく、本サービスを終了させることができるものとします。</p>
                <p class="text-muted ps-4">利用者が第６条に掲げる行為を行ったとき</p>
                <p class="text-muted ps-4">利用者が本規約に著しく違反したとき</p>
                <p class="text-muted ps-4">利用者と当社との信頼関係が維持できなくなったとき</p>
                <h5>第６条（利用者の禁止事項）</h5>
                <p class="text-muted ps-4">利用者は本サービス上、以下の行為を行ってはならないものとします。</p>
                <p class="text-muted ps-4">第三者または当社の著作権、特許権、実用新案権、意匠権、商標権、その他の知的財産権を侵害する行為。</p>
                <p class="text-muted ps-4">第三者または当社の財産、プライバシー、名誉等の権利を侵害する行為。</p>
                <p class="text-muted ps-4">第三者または当社を誹謗中傷する行為。</p>
                <p class="text-muted ps-4">第三者または当社に不利益を与える、またはそのおそれのある行為。</p>
                <p class="text-muted ps-4">虚偽の情報を登録、告知する行為。</p>
                <p class="text-muted ps-4">本サービスを利用した営業活動、営利を目的とする情報提供等の行為。</p>
                <p class="text-muted ps-4">本サービスを通じて入手した情報を、複製、販売、出版その他私的利用の範囲を超えて使用する行為。</p>
                <p class="text-muted ps-4">本サービスの運営を妨げ、あるいは第三者または当社の信用を毀損するような行為、またはそのおそれのある行為。</p>
                <p class="text-muted ps-4">公序良俗に反する行為、またはそのおそれのある行為。</p>
                <p class="text-muted ps-4">各種法令に違反する行為、またはそのおそれのある行為。</p>
                <h5>第７条（利用者責任）</h5>
                <p class="text-muted ps-4">利用者は、自らの意思によって本サービスを利用し、利用にかかわるすべての責任を負うものとします。</p>
                <p class="text-muted ps-4">当社は、利用者が登録した情報について確認を行うことができるものとします。</p>
                <p class="text-muted ps-4">当社は、利用者に通知した労働条件が、当該雇用契約の確定的なものであることに関して、保証しないものとします。</p>
                <p class="text-muted ps-4">利用者が本サービスの利用に伴い、第三者との間で紛争等が生じたときには、当社に責任がある場合を除き、当社は何らの責任を負わず、利用者は、自身の責任においてこれに対処し、当社に一切の迷惑をかけないものとします。</p>
                <p class="text-muted ps-4">利用者は、本サービスが、必ずしも利用者を転職に対し成功しない可能性があることを認識・承諾するものとします。</p>
                <h5>第８条（提供情報の不保証）</h5>
                <p class="text-muted ps-4">本サービスが提供する情報について重大な過失がある場合を除き、当社が保証するものではありません。</p>
                <h5>第９条（個人情報取扱い）</h5>
                <p class="text-muted ps-4">個人情報の取り扱いについては、「個人情報保護方針」及び「個人情報の適正な取り扱いに関する基本方針」に定めています。当社は、これに基づき利用者の個人情報を収集・利用・管理・保管に努め、第三者への提供を行います。</p>
                <p class="text-muted ps-4">利用者は、「個人情報の適正な取り扱いに関する基本方針」を承諾し本サービスを利用するものとします。</p>
                <p></p>
                <h5>第１０条（著作権および提供された情報の利用）</h5>
                <p class="text-muted ps-4">本サービスに掲載されているすべてのコンテンツ（写真・イラスト・文言等を含む。）の著作権は、当社、若しくは当社が制作を委託した企業に帰属します。</p>
                <h5>第１１条（本サービスの変更等）</h5>
                <p class="text-muted ps-4">当社は、利用者への事前の通知なく、本サービスの変更・中断をすることができるものとします。</p>
                <h5>第１２条（損害賠償）</h5>
                <p class="text-muted ps-4">利用者は、本規約に違反し、当社に対し損害を与えたときは、当社に対し直接・間接を問わず一切の損害の賠償義務を負うものとします。</p>
                <h5>第１３条（規約の変更）</h5>
                <p class="text-muted ps-4">当社は、本規約を必要に応じて変更することができるものとします。変更の内容については、インターネット当社ホームページ上での告知により利用者へ通知し、一ヶ月経過した時点で、利用者が変更を承諾したものとみなします。</p>
                <h5>第１４条（協議事項）</h5>
                <p class="text-muted ps-4">本規約の解釈に疑義が生じた場合、または本規約に定めのない事項については、当社と利用者は、誠意をもって協議の上これを解決するものとします。</p>
                <h5>第１５条（準拠法および管轄）</h5>
                <p class="text-muted ps-4">本規約は日本法を準拠法とし、本サービスおよび本規約に関して生じる一切の紛争については、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。</p>
                <h5>付則</h5>
                <h5>この規約は２０２２年１１月１日から実施します。</h5>

            </div>
        </div>

    </div>
</section>
