import { Injectable } from '@angular/core';
import {HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable, of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  protected httpOptions: any = {

    // Httpヘッダー(CORS対応でAccess-Control-Allow-Originを指定する)
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With,content-type, Authorization, X-PINGOTHER',
    }),
    // withCredentials: true,
  };

  protected handleError<T>(operation = 'operation', result?: T): any {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }

  constructor() { }

  API_PATH = {
    AUTH_FACEBOOK_LOGIN: environment.API_BASE + '/jb/api/auth/facebook/',
    AUTH_GOOGLE_LOGIN: environment.API_BASE + '/jb/api/auth/google/',
    AUTH_WECHAT_LOGIN: environment.API_BASE + '/jb/api/auth/wechat/',
    AUTH_MAIL_REGISTER: environment.API_BASE + '/jb/api/auth/mail/register/',
    AUTH_MAIL_TRUE_REGISTER: environment.API_BASE + '/jb/api/auth/mail/trueRegister/',
    AUTH_TEMPORARY_USER_REGISTER: environment.API_BASE + '/jb/api/auth/temporary/user/register/',
    AUTH_TEMPORARY_CLIENT_REGISTER: environment.API_BASE + '/jb/api/auth/temporary/client/register/',
    AUTH_TRUTH_REGISTER: environment.API_BASE + '/jb/api/auth/truth/register/',
    AUTH_MAIL_PASSWORD_RESET_LINK: environment.API_BASE + '/jb/api/auth/mail/password/reset/link/',
    AUTH_MAIL_PASSWORD_RESET: environment.API_BASE + '/jb/api/auth/mail/password/reset/',
    AUTH_MAIL_LOGIN: environment.API_BASE + '/jb/api/auth/mail/login/',
    AUTH_TIPS_FLG_CHANGE: environment.API_BASE + '/jb/api/auth/change/tips/',
    AUTH_PASSWORD_SET: environment.API_BASE + '/jb/api/auth/password/set/',
    AUTH_PASSWORD_RESET: environment.API_BASE + '/jb/api/auth/password/reset/',
    AUTH_LOGOUT: environment.API_BASE + '/jb/api/auth/logout/',
    PROFILE: environment.API_BASE + '/jb/api/profile/info/',
    PROFILE_NAME: environment.API_BASE + '/jb/api/profile/name/',
    PROFILE_EDIT: environment.API_BASE + '/jb/api/profile/add/',
    PROJECT_LIST: environment.API_BASE + '/jb/api/project/list/',
    PROJECT_LIST_HOME: environment.API_BASE + '/jb/api/project/list/home/',
    PROJECT_LIST_SIMILAR: environment.API_BASE + '/jb/api/project/list/similar/',
    PROJECT_LIST_COUNT: environment.API_BASE + '/jb/api/project/list/count/',
    PROJECT_DETAIL: environment.API_BASE + '/jb/api/project/detail/',
    PROJECT_APPLY: environment.API_BASE + '/jb/api/project/apply/',
    PROJECT_LIKE: environment.API_BASE + '/jb/api/project/like/',
    PROFILE_CURRENT: environment.API_BASE + '/jb/api/profile/current',
    CLIENT_DETAIL: environment.API_BASE + '/jb/api/client/detail/',
    PROFILE_SKILL_LIST: environment.API_BASE + '/jb/api/profile/skill/list/',
    PROFILE_SKILL_SAVE: environment.API_BASE + '/jb/api/profile/skill/save/',
    PROFILE_STATUS_CHECK: environment.API_BASE + '/jb/api/profile/status/check/',
    PROJECT_LIKE_LIST: environment.API_BASE + '/jb/api/project/list/like/',
    PROJECT_OPTION: environment.API_BASE + '/jb/api/project/option/',
    PROJECT_ENTRY_LIST: environment.API_BASE + '/jb/api/project/list/entry/',
    PROFILE_ENTRY_COMMENT_SAVE: environment.API_BASE + '/jb/api/profile/entry/comment/save/',
    PROFILE_ENTRY_END: environment.API_BASE + '/jb/api/profile/entry/end/',
    PROFILE_ENTRY_RESTART: environment.API_BASE + '/jb/api/profile/entry/restart/',
  }
}
