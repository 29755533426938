<!--<div class="page-title-area">-->
<!--    <div class="d-table">-->
<!--        <div class="d-table-cell">-->
<!--            <div class="container">-->
<!--                <div class="page-title-text">-->
<!--                    <h2>結果通知</h2>-->
<!--                    <ul>-->
<!--                        <li><a routerLink="/">ホーム</a></li>-->
<!--                        <li><i class="icofont-simple-right"></i></li>-->
<!--                        <li>結果通知</li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
<section class="container pt-170 min-h-60vh">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="text-center">
                    <!-- <img src="../assets/image/{{svg}}" alt="" class="w-50 h-50 px-9"> -->
                    <div class="pt-14">
                        <p class="font-size-8 px-xxl-28 px-xs-10 px-sm-13 px-lg-13 px-md-28 px-xl-22 px-0 mb-4 desktop-container" data-aos="zoom-in" data-aos-duration="600" data-aos-once="true">{{title}}</p>
                        <p class="font-size-6 px-xxl-28 px-xs-10 px-sm-13 px-lg-13 px-md-28 px-xl-22 px-0 mb-4 mobile-container font-weight-bold" data-aos="zoom-in" data-aos-duration="600" data-aos-once="true">{{title}}</p>
                        <div class="text-center">
                            <span class="font-size-8" data-aos="zoom-in" data-aos-duration="800" data-aos-once="true">{{message1}}{{message5}}</span>
                            <span class="font-size-4" data-aos="zoom-in" data-aos-duration="800" data-aos-once="true">{{message1}}<br>{{message5}}</span>
                        </div>
                        <div class="text-center">
                            <span class="font-size-8" data-aos="zoom-in" data-aos-duration="1000" data-aos-once="true">{{message2}}</span>
                        </div>
                        <div class="text-center">
                            <span class="font-size-8" data-aos="zoom-in" data-aos-duration="1200" data-aos-once="true">{{message3}}</span>
                        </div>
                        <div class="text-center">
                            <span class="font-size-8" data-aos="zoom-in" data-aos-duration="1200" data-aos-once="true">{{message4}}</span>
                        </div>
                        <a routerLink="/" class="btn btn-blue btn-h-40 text-white rounded-5 w-180 m-lg-20 m-20 text-uppercase" translate>トップページへ</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



