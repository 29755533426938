<!--<div class="page-title-area">-->
<!--    <div class="d-table">-->
<!--        <div class="d-table-cell">-->
<!--            <div class="container">-->
<!--                <div class="page-title-text">-->
<!--                    <h2>プロフィール</h2>-->
<!--                    <ul>-->
<!--                        <li><a routerLink="/">ホーム</a></li>-->
<!--                        <li><i class="icofont-simple-right"></i></li>-->
<!--                        <li>プロフィール</li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<div class="dashboard-area pt-120 profile-container-form pt-80-sp">
    <div class="container">

        <div style="min-height: 70vh">
            <div uk-grid>
                <div class="mobile-container w-100 mobile-menu-top">
                    <p-tieredMenu #menu [showTransitionOptions]="'200ms'" [hideTransitionOptions]="'100ms'" [model]="items" [popup]="true"></p-tieredMenu>
                    <button class="w-100" #btn type="button" pButton icon="pi pi-fw pi-list" [label]="currentMenu" (click)="menu.toggle($event)"></button>
                </div>
                <div class="uk-width-medium@m sidebar-background ps-0 desktop-container pe-lg-3 pe-0">
                    <ul class="uk-nav uk-nav-default"
                        uk-toggle="cls: uk-nav uk-nav-default uk-breadcrumb uk-padding-small; mode: media; media: @s"
                        uk-switcher="connect: #component-nav; animation: uk-animation-fade; toggle: > :not(.uk-nav-header)">
                        <li class="side-tab" [ngClass]="{'uk-active':isProfile}" uk-toggle="cls: side-tab; mode: media; media: @m">
                            <a class="side-tab-link" (click)="goToMenu('isProfile')">
                                <span class="button-tab-icon uk-visible@l" uk-icon="icon: user"></span>
                                基本情報
                            </a>
                        </li>
                        <li class="side-tab" [ngClass]="{'uk-active':isSkill}" uk-toggle="cls: side-tab; mode: media; media: @m">
                            <a class="side-tab-link" (click)="goToMenu('isSkill')">
                                <span class="button-tab-icon uk-visible@l" uk-icon="icon: thumbnails"></span>
                                スキル情報
                            </a>
                        </li>
                        <li class="side-tab" [ngClass]="{'uk-active':isLike}" uk-toggle="cls: side-tab; mode: media; media: @m">
                            <a class="side-tab-link" (click)="goToMenu('isLike')">
                                <span class="button-tab-icon uk-visible@l" uk-icon="icon: heart"></span>
                                お気に入り
                            </a>
                        </li>
                        <li class="side-tab" [ngClass]="{'uk-active':isEntry}" uk-toggle="cls: side-tab; mode: media; media: @m">
                            <a class="side-tab-link" (click)="goToMenu('isEntry')">
                                <span class="button-tab-icon uk-visible@l" uk-icon="icon: history"></span>
                                応募履歴
                            </a>
                        </li>
<!--                        <li class="side-tab">-->
<!--                            <a class="side-tab-link" (click)="logout()">-->
<!--                                <span class="button-tab-icon uk-visible@l" uk-icon="icon: sign-out"></span>-->
<!--                                ログアウト-->
<!--                            </a>-->
<!--                        </li>-->
                    </ul>
                </div>
                <div class="uk-width-expand@m">
                    <ul id="component-nav" class="uk-switcher uk-margin-large ">
                        <li>
                            <div class="uk-section uk-section-default py-0">
                                <div class="uk-container mx-0 px-0 px-lg-1">

                                    <div class="create-information">
                                        <form #f="ngForm" (ngSubmit)="profileSave()">
                                            <div class="row">
                                                <div class="col-6">
                                                    <h3>基本情報</h3>
                                                </div>
                                                <div class="col-6 text-right">
                                                    <mat-icon class="cursor-pointer main-color" pTooltip="サインアウト" (click)="logout()">logout</mat-icon>
                                                </div>
                                            </div>

                                            <!--                <div class="create-information-btn">-->
                                            <!--                    <a routerLink="/" class="profile-common-btn">履歴書を添付</a>-->
                                            <!--                    <a routerLink="/" class="profile-common-btn">職務経歴書を添付</a>-->
                                            <!--                </div>-->

                                            <div class="attachment-file row">
                                                <div class="w-50 w-100-sp form-group">
                                                    <label for="resume">履歴書</label>
<!--                                                    <span class="badge bg-danger tx-9 ms-1 profile-mast-span">必須</span>-->
<!--                                                    <p-fileUpload id="resume" (onRemove)="clearFile(1)" [maxFileSize]="1048575"-->
<!--                                                                  [auto]="true" [url]="localhost + '/jb/api/profile/file/upload/'"-->
<!--                                                                  (onUpload)="setFileUrl($event, 1)" chooseLabel="選択">-->
<!--                                                        <ng-template pTemplate="content">-->
<!--                                                            <div class="row mx-0" *ngIf="profile.resume != null && profile.resume != ''">-->
<!--                                                                <a class="col-11 text-ellipsis-1 main-color" [href]="this.profile.resume" target="_blank">{{resumeName}}</a>-->
<!--                                                                <mat-icon class="cursor-pointer px-0" (click)="clearFile(1)">close</mat-icon>-->
<!--                                                            </div>-->
<!--                                                        </ng-template>-->
<!--                                                    </p-fileUpload>-->
                                                    <div class="form-control align-self-center" style="padding: 3px">
                                                        <div class="row mx-0">
                                                            <div class="col-4 text-left px-0">
                                                                <p-fileUpload mode="basic"
                                                                              id="resume"
                                                                              [url]="localhost + '/jb/api/profile/file/upload/'"
                                                                              [maxFileSize]="10485760"
                                                                              (onError)="onError()"
                                                                              [auto]="true" (onUpload)="setFileUrl($event, 1)" chooseLabel="選択">
                                                                </p-fileUpload>
                                                            </div>
                                                            <div class="col-8 align-self-center">
                                                                <div class="row mx-0" *ngIf="profile.resume != null && profile.resume != ''" style="justify-content: space-between">
                                                                    <a class="col-10 text-ellipsis-1 main-color align-self-center upload-file-name" [href]="this.profile.resume" target="_blank">{{resumeName}}</a>
                                                                    <mat-icon class="cursor-pointer px-0" style="color: #999999" (click)="clearFile(1)">close</mat-icon>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="w-50 w-100-sp form-group">
                                                    <label for="career">職務経歴書</label>
<!--                                                    <span class="badge bg-danger tx-9 ms-1 profile-mast-span">必須</span>-->
<!--                                                    <p-fileUpload id="career" (onRemove)="clearFile(0)" [maxFileSize]="10485760"-->
<!--                                                                  [auto]="true" [url]="localhost + '/jb/api/profile/file/upload/'"-->
<!--                                                                  (onUpload)="setFileUrl($event, 0)" chooseLabel="選択">-->
<!--                                                        <ng-template pTemplate="content">-->
<!--                                                            <div class="row mx-0" *ngIf="profile.career != null && profile.career != ''">-->
<!--                                                                <a class="col-11 text-ellipsis-1 main-color" [href]="this.profile.career" target="_blank">{{careerName}}</a>-->
<!--                                                                <mat-icon class="cursor-pointer px-0" (click)="clearFile(0)">close</mat-icon>-->
<!--                                                            </div>-->
<!--                                                        </ng-template>-->
<!--                                                    </p-fileUpload>-->

                                                    <div class="form-control align-self-center" style="padding: 3px">
                                                        <div class="row mx-0">
                                                            <div class="col-4 text-left px-0">
                                                                <p-fileUpload mode="basic"
                                                                              id="career"
                                                                              [url]="localhost + '/jb/api/profile/file/upload/'"
                                                                              [maxFileSize]="10485760"
                                                                              [auto]="true" (onUpload)="setFileUrl($event, 0)" chooseLabel="選択">
                                                                </p-fileUpload>
                                                            </div>
                                                            <div class="col-8 align-self-center">
                                                                <div class="row mx-0" *ngIf="profile.career != null && profile.career != ''" style="justify-content: space-between">
                                                                    <a class="col-10 text-ellipsis-1 main-color align-self-center upload-file-name" [href]="this.profile.career" target="_blank">{{careerName}}</a>
                                                                    <mat-icon class="cursor-pointer px-0" style="color: #999999" (click)="clearFile(0)">close</mat-icon>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>

                                            </div>

                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>名前</label>
<!--                                                        <span class="badge bg-danger tx-9 ms-1 profile-mast-span">必須</span>-->
                                                        <input type="text" class="form-control" maxlength="32" placeholder="JOBINDER 太郎" [(ngModel)]="profile.nameKanji" name="nameKanji">
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>名前（カナ）</label>
                                                        <input type="text" class="form-control" maxlength="32" placeholder="JOBINDER タロウ" [(ngModel)]="profile.nameKana" name="nameKana">
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>名前（アルファベット）</label>
                                                        <input type="text" class="form-control" maxlength="32" placeholder="JOBINDER TAROU" [(ngModel)]="profile.nameAlphabet" name="nameAlphabet">
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>性別</label>
                                                        <div class="gender-select">
                                                            <p-dropdown [options]="genderList" [(ngModel)]="gender"
                                                                        optionLabel="content" name="gender" styleClass="form-control"></p-dropdown>
<!--                                                            <select [(ngModel)]="profile.gender" name="gender" class="form-control">-->
<!--                                                                <option *ngFor="let item of genderItems | keyvalue"-->
<!--                                                                        [value]="item.key">-->
<!--                                                                    {{item.value}}-->
<!--                                                                </option>-->
<!--                                                            </select>-->
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>誕生日</label>
<!--                                                        <span class="badge bg-danger tx-9 ms-1 profile-mast-span">必須</span>-->
                                                        <input type="date" data-provide="datepicker" data-date-autoclose="true"
                                                               class="form-control"
                                                               [(ngModel)]="profile.birthday" name="birthday">
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>国籍</label>
<!--                                                        <span class="badge bg-danger tx-9 ms-1 profile-mast-span">必須</span>-->
                                                        <div class="country-select">
                                                            <p-dropdown [options]="countryList" [(ngModel)]="country"
                                                                        optionLabel="content" name="country" styleClass="form-control"></p-dropdown>
<!--                                                            <select [(ngModel)]="profile.country" name="country" class="form-control">-->
<!--                                                                <option *ngFor="let item of countryItems | keyvalue"-->
<!--                                                                        [value]="item.key">-->
<!--                                                                    {{item.value}}-->
<!--                                                                </option>-->
<!--                                                            </select>-->
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>出身地</label>
                                                        <input type="text" class="form-control" maxlength="32" placeholder="東京" [(ngModel)]="profile.birthplace" name="birthplace">
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>郵便番号</label>
                                                        <input type="text" class="form-control" placeholder="101-0001"
                                                               mask="999-9999"
                                                               [(ngModel)]="profile.zipcode" name="zipcode">
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>住所</label>
                                                        <input type="text" class="form-control" maxlength="32" placeholder="日本東京都千代田区岩本町2-1-5" [(ngModel)]="profile.address" name="address">
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>電話番号</label>
                                                        <input type="text" class="form-control" placeholder="080 1234 5678"
                                                               mask="999 9999 9999"
                                                               [(ngModel)]="profile.tel" name="tel">
                                                    </div>
                                                </div>

                                                <!--                    <div class="col-lg-6">-->
                                                <!--                        <div class="form-group">-->
                                                <!--                            <label>SNS</label>-->
                                                <!--                            <input type="text" class="form-control" placeholder="WeChatID/LineID" [(ngModel)]="profile.sns" name="sns">-->
                                                <!--                        </div>-->
                                                <!--                    </div>-->

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>経験年数</label>
<!--                                                        <span class="badge bg-danger tx-9 ms-1 profile-mast-span">必須</span>-->
                                                        <input type="number" class="form-control" placeholder="5" [(ngModel)]="profile.experience" name="experience">
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>メールアドレス</label>
<!--                                                        <span class="badge bg-danger tx-9 ms-1 profile-mast-span">必須</span>-->
                                                        <input type="email" class="form-control" placeholder="hello@jobinder.net"
                                                               [(ngModel)]="profile.email" name="email">
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>転職意向</label>
<!--                                                        <span class="badge bg-danger tx-9 ms-1 profile-mast-span">必須</span>-->
                                                        <div class="current-status-select">
                                                            <p-dropdown [options]="currentStatusList" [(ngModel)]="currentStatus"
                                                                        optionLabel="content" name="currentStatus" styleClass="form-control"></p-dropdown>
<!--                                                            <select [(ngModel)]="profile.currentStatus" name="currentStatus" class="form-control">-->
<!--                                                                <option *ngFor="let item of currentStatusItems | keyvalue"-->
<!--                                                                        [value]="item.key">-->
<!--                                                                    {{item.value}}-->
<!--                                                                </option>-->
<!--                                                            </select>-->
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>最寄駅</label>
                                                        <input type="text" class="form-control" placeholder="新宿駅" [(ngModel)]="profile.nearestStation" name="nearestStation">
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="form-group">
                                                        <label>自己紹介</label>
                                                        <textarea id="your_message" class="form-control" rows="8" maxlength="500"
                                                                  [(ngModel)]="profile.selfIntroduction" name="selfIntroduction">{{profile.selfIntroduction}}</textarea>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>最終学歴</label>
                                                        <div class="degree-select">
                                                            <p-dropdown [options]="degreeList" [(ngModel)]="degree"
                                                                        optionLabel="content" name="degree" styleClass="form-control"></p-dropdown>
<!--                                                            <select [(ngModel)]="profile.degree" name="degree" class="form-control">-->
<!--&lt;!&ndash;                                                                <option value="0" disabled selected [defaultSelected]="true">Select your option</option>&ndash;&gt;-->
<!--                                                                <option *ngFor="let item of degreeList | keyvalue"-->
<!--                                                                        [value]="item.key">-->
<!--                                                                    {{item.value}}-->
<!--                                                                </option>-->
<!--                                                            </select>-->
                                                        </div>

<!--                                                        <input type="text" class="form-control" placeholder="大学院" [(ngModel)]="profile.degree" name="degree">-->
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>学校名</label>
                                                        <input type="text" class="form-control" maxlength="32" placeholder="東京大学" [(ngModel)]="profile.schoolName" name="schoolName">
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>専攻</label>
                                                        <input type="text" class="form-control" placeholder="電子情報" [(ngModel)]="profile.major" name="major">
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="form-group">
                                                        <label>時期</label>
                                                        <div class="row mx-0">
                                                            <div class="w-47 px-0">
                                                                <input type="date" class="form-control" placeholder="2016/04/01" [(ngModel)]="profile.studyPeriodStart" name="studyPeriodStart">
                                                            </div>
                                                            <div class="w-6 px-0 period-middle-lable">
                                                                <label class="mb-0">〜</label>
                                                            </div>
                                                            <div class="w-47 px-0">
                                                                <input type="date" class="form-control" placeholder="2020/03/31" [(ngModel)]="profile.studyPeriodEnd" name="studyPeriodEnd">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="text-center profile-save-button mt-3">
                                                    <a class="profile-common-btn w-100" style="max-width: 200px" (click)="f.ngSubmit.emit()">保存</a>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="uk-section uk-section-default py-0">
                                <div class="uk-container mx-0 px-0 px-lg-1">
                                    <div class="create-skills">
                                        <div class="create-skills-wrap">
                                            <div class="col-lg-6 col-4">
                                                <h3>スキル情報</h3>
                                            </div>

                                            <div class="common-btn col-lg-6 col-8 text-right">
                                                <a class="sign-up-btn" (click)="skillSave()"><i class="icofont-ui-check"></i> 保存</a>
                                                <a class="sign-up-btn" (click)="skillAdd()"><i class="icofont-plus-square"></i> 追加</a>
                                            </div>
                                        </div>

                                        <ng-container *ngIf="skillList != null && skillList.length != 0">
                                            <table class="uk-table uk-table-striped uk-table-middle uk-table-divider" *ngIf="skillList != null && skillList.length != 0">
                                                <thead>
                                                <tr class="">
                                                    <th class="uk-table-shrink">スキル</th>
                                                    <th class="uk-table-shrink">役割</th>
                                                    <th class="uk-table-shrink">経験年数</th>
                                                    <th class="uk-table-shrink"></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <ng-container *ngFor="let skill of skillList; let i = index">
                                                    <tr>
                                                        <td class="uk-text-truncate">
                                                            <ng-select [items]="techTagList"
                                                                       bindLabel="content"
                                                                       bindValue="id"
                                                                       placeholder="Java"
                                                                       [appendTo]="'body'"
                                                                       [(ngModel)]="skill.techTagId">
                                                            </ng-select>
                                                        </td>
                                                        <td class="uk-text-truncate">
                                                            <ng-select [items]="processList"
                                                                       bindLabel="content"
                                                                       bindValue="id"
                                                                       placeholder="基本設計"
                                                                       [appendTo]="'body'"
                                                                       [(ngModel)]="skill.processId">
                                                            </ng-select>
                                                        </td>
                                                        <td class="uk-text-truncate">
                                                            <ng-select [items]="yearList"
                                                                       bindLabel="content"
                                                                       bindValue="id"
                                                                       placeholder="3年"
                                                                       [appendTo]="'body'"
                                                                       [(ngModel)]="skill.years">
                                                            </ng-select>
                                                        </td>
                                                        <td class="uk-text-truncate">
                                                            <div>
                                                                <mat-icon class="vertical-align-middle cursor-pointer main-color" (click)="skillRemove(i)">clear</mat-icon>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                                </tbody>
                                            </table>

<!--                                            <div class="create-skills-wrap mt-5" *ngIf="skillList != null && skillList.length != 0">-->
<!--                                                <div class="col-lg-6 col-12">-->
<!--                                                </div>-->

<!--                                                <div class="common-btn col-lg-6 col-12 text-right">-->
<!--                                                    <a class="sign-up-btn" (click)="skillSave()"><i class="icofont-ui-check"></i> 保存</a>-->
<!--                                                    <a class="sign-up-btn" (click)="skillAdd()"><i class="icofont-plus-square"></i> 追加</a>-->
<!--                                                </div>-->
<!--                                            </div>-->
                                        </ng-container>
                                        <ng-container *ngIf="skillList == null || skillList.length == 0">
                                            <div class="row mx-0">
                                                <div class="text-center profile-save-button black-color">
                                                    <p class="font-size-3 mt-5">
                                                        スキル情報はまだありません。<br>自分に合ったスキル情報を追加してください〜
                                                    </p>
                                                </div>
                                                <div class="col-12 text-center">
                                                    <img src="assets/img/search_confuse.png" class="w-50 m-5">
                                                </div>
                                            </div>
                                        </ng-container>

                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="uk-section uk-section-default py-0">
                                <div class="uk-container mx-0 mx-0 px-0 px-lg-1">
                                    <div class="create-skills">
                                        <div class="create-skills-left">
                                            <h3>お気に入り</h3>
                                        </div>
                                        <ng-container *ngIf="haveLike">
                                            <div class="row mx-0">
                                                <div class="col-12 row mx-0 px-0">
                                                    <ng-container *ngFor="let project of projectLikeList">
                                                        <div class="col-lg-6 like-project-list">
                                                            <div class="job-item mx-0 mb-0">
                                                                <div class="job-inner align-items-center">
                                                                    <div class="position-absolute project-list-like-action">
                                                                        <ng-container *ngIf="!project.isLike">
                                                                            <a (click)="like(project.uuid)"
                                                                               matTooltip="お気に入り"
                                                                               matTooltipPosition="right"
                                                                               class="cursor-pointer">
                                                                                <mat-icon class="gray-color3">favorite</mat-icon>
                                                                            </a>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="project.isLike">
                                                                            <a (click)="like(project.uuid)"
                                                                               matTooltip="取り消し"
                                                                               matTooltipPosition="right"
                                                                               class="cursor-pointer">
                                                                                <mat-icon class="danger-color">favorite_border</mat-icon>
                                                                            </a>
                                                                        </ng-container>
                                                                    </div>

                                                                    <div class="entry-tag" *ngIf="project.isEntry" (click)="toEntry()">
                                                                        応募済
                                                                    </div>

                                                                    <div class="job-inner-left">
                                                                        <span class="cursor-pointer" (click)="goToClientDetail(project.agentUuid, $event)">{{project.agentName}}</span>
                                                                        <h3 class="text-ellipsis-1 h-title cursor-pointer main-color" (click)="goToDetail(project.uuid, $event)">
                                                                            <label class="project-new" *ngIf="project.isRecent">NEW</label>{{project.title}}</h3>
                                                                    </div>
                                                                    <div class="row mx-0 col-12 px-2 cursor-pointer w-100 my-2" (click)="goToDetail(project.uuid, $event)">
                                                                        <ul>
                                                                            <li>
                                                                                <div class="row mx-0">
                                                                                    <div class="d-flex col-5 client-details-info-header">
                                                                                        <i class="icofont-yen-true align-self-center ps-3"></i>
                                                                                        <div class="align-self-center">月額</div>
                                                                                    </div>
                                                                                    <div class="col-7 client-details-info-body client-details-info-body-salary ps-3">
                                                                                        <div>{{format(project.price)}}円〜</div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="row mx-0">
                                                                                    <div class="d-flex col-5 client-details-info-header">
                                                                                        <i class="icofont-sand-clock ps-3"></i>
                                                                                        <div>経験年数</div>
                                                                                    </div>
                                                                                    <div class="col-7 client-details-info-body ps-3">
                                                                                        <div *ngIf="project.experience != null && project.experience > 0"
                                                                                             style="padding-top: 1px">{{project.experience}}年〜</div>
                                                                                        <div *ngIf="project.experience == null || project.experience <= 0"
                                                                                             style="padding-top: 1px">年数不問</div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="row mx-0">
                                                                                    <div class="d-flex col-5 client-details-info-header">
                                                                                        <i class="icofont-tags ps-3"></i>
                                                                                        <div>キーワード</div>
                                                                                    </div>
                                                                                    <div class="col-7 client-details-info-body client-details-info-body-tag ps-3">
                                                                                        <ng-container *ngIf="project.tagList != null && project.tagList.length != 0">
                                                                                            <ng-container *ngFor="let tag of project.tagList">
                                                                                                <div class="project-list-tag">{{tag.content}}</div>
                                                                                            </ng-container>
                                                                                        </ng-container>
                                                                                        <ng-container *ngIf="project.tagList == null || project.tagList.length == 0">
                                                                                            <div>--</div>
                                                                                        </ng-container>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="w-100 row mx-0">
                                                                        <div class="col-10">
                                                                            <div class="project-list-desc cursor-pointer" (click)="goToDetail(project.uuid, $event)" [innerText]="project.content">

                                                                            </div>
                                                                        </div>
                                                                        <div class="col-2 category-img align-self-center">
                                                                            <img *ngIf="project.category==null" src="assets/img/category/code.png" width="50" alt="Job">
                                                                            <img *ngIf="project.category!=null && project.category==10" src="assets/img/category/java.png" width="50" alt="Job">
                                                                            <img *ngIf="project.category!=null && project.category==20" src="assets/img/category/python.png" width="50" alt="Job">
                                                                            <img *ngIf="project.category!=null && project.category==30" src="assets/img/category/react.png" width="50" alt="Job">
                                                                            <img *ngIf="project.category!=null && project.category==40" src="assets/img/category/php.png" width="50" alt="Job">
                                                                            <img *ngIf="project.category!=null && project.category==50" src="assets/img/category/ios.svg" width="50" alt="Job">
                                                                            <img *ngIf="project.category!=null && project.category==60" src="assets/img/category/android.svg" width="50" alt="Job">
                                                                            <img *ngIf="project.category!=null && project.category==70" src="assets/img/category/angular.svg" width="50" alt="Job">
                                                                            <img *ngIf="project.category!=null && project.category==80" src="assets/img/category/net.png" width="50" alt="Job">
                                                                            <img *ngIf="project.category!=null && project.category==90" src="assets/img/category/aws.svg" width="50" alt="Job">
                                                                            <img *ngIf="project.category!=null && project.category==100" src="assets/img/category/vue.png" width="50" alt="Job">
                                                                            <img *ngIf="project.category!=null && project.category==999" src="assets/img/category/code.png" width="50" alt="Job">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>

                                                <div class="col-12">
                                                    <div class="flex align-items-center justify-content-center" *ngIf="totalCount != null && totalCount != 0">
                                                        <div class="flex-1">
                                                            <p-paginator (onPageChange)="onPageLikeChange($event)"
                                                                         [first]="offset" [rows]="limit" [totalRecords]="totalCount" [showFirstLastIcon]="true"></p-paginator>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="!haveLike">
                                            <div class="row mx-0">
                                                <div class="text-center profile-save-button black-color">
                                                    <p class="font-size-3 mt-5">
                                                        お気に入りの求人情報はまだありません。<br>
                                                        <a class="profile-common-btn w-100 main-color" routerLink="/project/">求人情報をもっと見る〜</a>
                                                    </p>
                                                </div>
                                                <div class="col-12 text-center">
                                                    <img src="assets/img/search_confuse.png" class="w-50 m-5">
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="uk-section uk-section-default py-0">
                                <div class="uk-container mx-0 px-0 px-lg-1">
                                    <div class="create-skills mobile-container">
                                        <div class="create-skills-left">
                                            <h3>応募履歴</h3>
                                        </div>
                                        <ng-container *ngIf="haveEntry">
                                            <div class="row mx-0">
                                                <div class="col-12 row mx-0 px-0">
                                                    <ng-container *ngFor="let project of projectEntryList">
                                                        <div class="col-lg-6 px-0">
                                                            <div class="job-item mx-0" [ngStyle]="{'background':  project.replyType==40?'#f8f9fa':''}">
                                                                <div class="job-inner align-items-center">
                                                                    <div class="row mx-0 w-100" (click)="goToDetail(project.uuid, $event)">
                                                                        <div class="job-inner-left entry-history-title px-0">
                                                                            <div class="d-flex">
                                                                                <span class="cursor-pointer w-50" (click)="goToClientDetail(project.agentUuid, $event)">{{project.agentName}}</span>
                                                                                <span *ngIf="project.replyType==10" class="w-50 main-color text-right">お客様からの返信待ち</span>
                                                                                <span *ngIf="project.replyType==20" class="w-50 main-color text-right">担当者からの返信待ち</span>
                                                                                <span *ngIf="project.replyType==30" class="w-50 main-color text-right">担当者からの返信待ち</span>
                                                                                <span *ngIf="project.replyType==40" class="w-50 main-color text-right">該当応募が完了</span>
                                                                            </div>

                                                                            <p class="text-ellipsis-1 h-title cursor-pointer mt-1 mb-0 main-color" [routerLink]="'/project/detail/' + project.uuid">{{project.title}}</p>
                                                                        </div>
                                                                    </div>
<!--                                                                    <div class="border-top w-100" style="margin: 10px 3px;"></div>-->
                                                                    <div class="row mx-0 w-100">
                                                                        <div class="entry-history-title mx-0 px-0" (click)="goToDetail(project.uuid, $event)">
                                                                            <ul>
                                                                                <li>
                                                                                    <div class="row mx-0">
                                                                                        <div class="d-flex col-5 client-details-info-header">
                                                                                            <i class="icofont-yen-true ps-3"></i>
                                                                                            <div>月額</div>
                                                                                        </div>
                                                                                        <div class="col-7 client-details-info-body client-details-info-body-salary ps-3">
                                                                                            <div>{{format(project.price)}}円〜</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                                <!--                    <li>-->
                                                                                <!--                      <div class="row mx-0">-->
                                                                                <!--                        <div class="d-flex col-5 client-details-info-header">-->
                                                                                <!--                          <i class="icofont-train-line ps-3"></i>-->
                                                                                <!--                          <div>最寄駅</div>-->
                                                                                <!--                        </div>-->
                                                                                <!--                        <div class="col-7 client-details-info-body ps-3">-->
                                                                                <!--                          <div>{{project.station}}</div>-->
                                                                                <!--                        </div>-->
                                                                                <!--                      </div>-->
                                                                                <!--                    </li>-->
                                                                                <li>
                                                                                    <div class="row mx-0">
                                                                                        <div class="d-flex col-5 client-details-info-header">
                                                                                            <i class="icofont-sand-clock ps-3"></i>
                                                                                            <div>経験年数</div>
                                                                                        </div>
                                                                                        <div class="col-7 client-details-info-body ps-3">
                                                                                            <div *ngIf="project.experience != null && project.experience > 0"
                                                                                                 style="padding-top: 1px">{{project.experience}}年〜</div>
                                                                                            <div *ngIf="project.experience == null || project.experience <= 0"
                                                                                                 style="padding-top: 1px">年数不問</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                                <li>
                                                                                    <div class="row mx-0">
                                                                                        <div class="d-flex col-5 client-details-info-header">
                                                                                            <i class="icofont-tags ps-3"></i>
                                                                                            <div>キーワード</div>
                                                                                        </div>
                                                                                        <div class="col-7 client-details-info-body ps-3">
                                                                                            <ng-container *ngIf="project.tagList != null && project.tagList.length != 0">
                                                                                                <ng-container *ngFor="let tag of project.tagList">
                                                                                                    <div class="project-list-tag">{{tag.content}}</div>
                                                                                                </ng-container>
                                                                                            </ng-container>
                                                                                            <ng-container *ngIf="project.tagList == null || project.tagList.length == 0">
                                                                                                <div>--</div>
                                                                                            </ng-container>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div class="entry-history-btn pe-0 mb-0" *ngIf="project.commentList != null && project.commentList.length != 0">
                                                                            <a class="sign-up-btn" *ngIf="project.replyType!=40" (click)="showBasicDialog2(project.entryId)">連絡する</a>
                                                                            <a class="sign-up-btn" *ngIf="project.replyType!=40" (click)="entryEnd(project.entryId)">応募終了</a>
                                                                            <a class="sign-up-btn" *ngIf="project.replyType==40" (click)="entryRestart(project.entryId)">応募再開</a>
                                                                        </div>
                                                                        <div class="entry-history-btn pe-0 mb-0" *ngIf="project.commentList == null || project.commentList.length == 0">
                                                                            <a class="sign-up-btn mb-0" *ngIf="project.replyType!=40" (click)="showBasicDialog2(project.entryId)">連絡する</a>
                                                                            <a class="sign-up-btn mb-0" *ngIf="project.replyType!=40" (click)="entryEnd(project.entryId)">応募終了</a>
                                                                            <a class="sign-up-btn mb-0" *ngIf="project.replyType==40" (click)="entryRestart(project.entryId)">応募再開</a>
                                                                        </div>
                                                                    </div>
                                                                    <div class="border-top w-100 mt-0" *ngIf="project.commentList != null && project.commentList.length != 0" style="margin: 10px 3px;"></div>
                                                                    <div class="w-100" *ngIf="project.commentList != null && project.commentList.length != 0">
                                                                        <p-accordion>
                                                                            <p-accordionTab header="連絡履歴">
                                                                                <div class="card bg-sohbet border-0 m-0 p-0" style="max-height: 100vh;">
                                                                                    <div id="sohbet" class="card border-0 m-0 p-0 position-relative bg-transparent" style="overflow-y: auto; max-height: 100vh;">
                                                                                        <ng-container *ngFor="let event of project.commentList">
                                                                                            <ng-container *ngIf="event.itemType == 10">
                                                                                                <div class="balon1 p-2 m-0 position-relative">
                                                                                                    <span>
                                                                                                        {{event.datetime}}
                                                                                                    </span>
                                                                                                    <a class="float-right" [innerText]="event.comment"></a>
                                                                                                </div>
                                                                                            </ng-container>
                                                                                            <ng-container *ngIf="event.itemType == 9">
                                                                                                <div class="balon2 p-2 m-0 position-relative">
                                                                                                    <span>
                                                                                                        {{event.datetime}}
                                                                                                    </span>
                                                                                                    <a class="float-left" [innerText]="event.comment"></a>
                                                                                                </div>
                                                                                            </ng-container>
                                                                                        </ng-container>
                                                                                    </div>
                                                                                </div>
                                                                                <!--                                                                                <p-timeline [value]="project.commentList" align="alternate" styleClass="customized-timeline">-->
                                                                                <!--                                                                                    <ng-template pTemplate="marker" let-event>-->
                                                                                <!--                                                                                            <span class="custom-marker p-shadow-2 bg-main-color">-->
                                                                                <!--                                                                                                <i *ngIf="event.itemType==9" class="pi pi-chevron-left"></i>-->
                                                                                <!--                                                                                                <i *ngIf="event.itemType==10" class="pi pi-chevron-right"></i>-->
                                                                                <!--                                                                                            </span>-->
                                                                                <!--                                                                                    </ng-template>-->
                                                                                <!--                                                                                    <ng-template pTemplate="content" let-event>-->
                                                                                <!--                                                                                        <p-panel [header]="event.title" [toggleable]="true" [collapsed]="true" toggler="header">-->
                                                                                <!--                                                                                            <p [innerText]="event.comment"></p>-->
                                                                                <!--                                                                                        </p-panel>-->
                                                                                <!--                                                                                        <div class="row mx-0 w-100 px-2 pt-1 pb-2 comment-time">-->
                                                                                <!--                                                                                            <span class="w-50 text-left">{{event.adminName}}</span>-->
                                                                                <!--                                                                                            <span class="w-50 text-right">{{event.datetime}}</span>-->
                                                                                <!--                                                                                        </div>-->

                                                                                <!--                                                                                    </ng-template>-->
                                                                                <!--                                                                                </p-timeline>-->
                                                                            </p-accordionTab>
                                                                        </p-accordion>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                                <div class="col-12">
                                                    <div class="flex align-items-center justify-content-center" *ngIf="totalCount != null && totalCount != 0">
                                                        <div class="flex-1">
                                                            <p-paginator (onPageChange)="onPageEntryChange($event)" [first]="offsetEntry" [rows]="limitEntry" [totalRecords]="totalCountEntry" [showFirstLastIcon]="true"></p-paginator>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="!haveEntry">
                                            <div class="row mx-0">
                                                <div class="text-center profile-save-button black-color">
                                                    <p class="font-size-3 mt-5">
                                                        現在応募中の求人情報はまだありません。<br>
                                                        <a class="profile-common-btn w-100 main-color" routerLink="/project/">求人情報をもっと見る〜</a>
                                                    </p>
                                                </div>
                                                <div class="col-12 text-center">
                                                    <img src="assets/img/search_confuse.png" class="w-50 m-5">
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div class="create-skills desktop-container">
                                        <div class="create-skills-left">
                                            <h3>応募履歴</h3>
                                        </div>
                                        <ng-container *ngIf="haveEntry">
                                            <div class="row mx-0">
                                                <div class="col-12 row mx-0 px-0">
                                                    <ng-container *ngFor="let project of projectEntryList">
                                                        <div class="col-lg-12 px-0">
                                                            <div class="job-item">
                                                                <div class="job-inner align-items-center">

                                                                    <div class="job-inner-left">
                                                                        <div class="d-flex">
                                                                            <span class="cursor-pointer w-50" (click)="goToClientDetail(project.agentUuid, $event)">{{project.agentName}}</span>
                                                                            <span *ngIf="project.replyType==10" class="w-50 main-color text-right">お客様からの返信待ち</span>
                                                                            <span *ngIf="project.replyType==20" class="w-50 main-color text-right">担当者からの返信待ち</span>
                                                                            <span *ngIf="project.replyType==30" class="w-50 main-color text-right">担当者からの返信待ち</span>
                                                                            <span *ngIf="project.replyType==40" class="w-50 main-color text-right">該当応募が完了</span>
                                                                        </div>

                                                                        <h3 class="text-ellipsis-1 h-title cursor-pointer main-color" [routerLink]="'/project/detail/' + project.uuid">{{project.title}}</h3>
                                                                    </div>
                                                                    <div class="row mx-0 col-12 px-0 cursor-pointer w-50 mt-2" (click)="goToDetail(project.uuid, $event)">
                                                                        <ul>
                                                                            <li>
                                                                                <div class="row mx-0">
                                                                                    <div class="d-flex col-5 client-details-info-header">
                                                                                        <i class="icofont-yen-true ps-3"></i>
                                                                                        <div>月額</div>
                                                                                    </div>
                                                                                    <div class="col-7 client-details-info-body client-details-info-body-salary ps-3">
                                                                                        <div>{{format(project.price)}}円〜</div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="row mx-0">
                                                                                    <div class="d-flex col-5 client-details-info-header">
                                                                                        <i class="icofont-sand-clock ps-3"></i>
                                                                                        <div>経験年数</div>
                                                                                    </div>
                                                                                    <div class="col-7 client-details-info-body ps-3">
                                                                                        <div *ngIf="project.experience != null && project.experience > 0"
                                                                                             style="padding-top: 1px">{{project.experience}}年〜</div>
                                                                                        <div *ngIf="project.experience == null || project.experience <= 0"
                                                                                             style="padding-top: 1px">年数不問</div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="row mx-0">
                                                                                    <div class="d-flex col-5 client-details-info-header">
                                                                                        <i class="icofont-tags ps-3"></i>
                                                                                        <div>キーワード</div>
                                                                                    </div>
                                                                                    <div class="col-7 client-details-info-body ps-3">
                                                                                        <ng-container *ngIf="project.tagList != null && project.tagList.length != 0">
                                                                                            <ng-container *ngFor="let tag of project.tagList">
                                                                                                <div class="project-list-tag">{{tag.content}}</div>
                                                                                            </ng-container>
                                                                                        </ng-container>
                                                                                        <ng-container *ngIf="project.tagList == null || project.tagList.length == 0">
                                                                                            <div>--</div>
                                                                                        </ng-container>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="w-50 row mx-0 mt-2">
                                                                        <div class="col-12 pe-0">
                                                                            <div style="max-height: 127px;display: flex;box-shadow: rgba(0, 0, 0, 0.06) 0 0 14px 0 inset;padding: 8px">
                                                                                <p class="cursor-pointer history-desc mb-0" (click)="goToDetail(project.uuid, $event)" [innerText]="project.content">

                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="w-100 my-2" *ngIf="project.commentList != null && project.commentList.length != 0">
                                                                        <div class="entry-history-btn">
                                                                            <a class="sign-up-btn" *ngIf="project.replyType!=40" (click)="showBasicDialog2(project.entryId)">連絡する</a>
                                                                            <a class="sign-up-btn" *ngIf="project.replyType!=40" (click)="entryEnd(project.entryId)">応募終了</a>
                                                                            <a class="sign-up-btn" *ngIf="project.replyType==40" (click)="entryRestart(project.entryId)">応募再開</a>
                                                                        </div>
                                                                    </div>
                                                                    <div class="w-100 mt-2" *ngIf="project.commentList == null || project.commentList.length == 0">
                                                                        <div class="entry-history-btn">
                                                                            <a class="sign-up-btn" *ngIf="project.replyType!=40" (click)="showBasicDialog2(project.entryId)">連絡する</a>
                                                                            <a class="sign-up-btn" *ngIf="project.replyType!=40" (click)="entryEnd(project.entryId)">応募終了</a>
                                                                            <a class="sign-up-btn" *ngIf="project.replyType==40" (click)="entryRestart(project.entryId)">応募再開</a>
                                                                        </div>
                                                                    </div>

                                                                    <div class="w-100" *ngIf="project.commentList != null && project.commentList.length != 0">
                                                                        <p-accordion>
                                                                            <p-accordionTab header="連絡履歴">
                                                                                <div class="card bg-sohbet border-0 m-0 p-0" style="max-height: 100vh;">
                                                                                    <div id="sohbet" class="card border-0 m-0 p-0 position-relative bg-transparent" style="overflow-y: auto; max-height: 100vh;">
                                                                                        <ng-container *ngFor="let event of project.commentList">
                                                                                            <ng-container *ngIf="event.itemType == 10">
                                                                                                <div class="balon1 p-2 m-0 position-relative">
                                                                                                    <span>
                                                                                                        {{event.datetime}}
                                                                                                    </span>
                                                                                                    <a class="float-right" [innerText]="event.comment"></a>
                                                                                                </div>
                                                                                            </ng-container>
                                                                                            <ng-container *ngIf="event.itemType == 9">
                                                                                                <div class="balon2 p-2 m-0 position-relative">
                                                                                                    <span>
                                                                                                        {{event.datetime}}
                                                                                                    </span>
                                                                                                    <a class="float-left" [innerText]="event.comment"></a>
                                                                                                </div>
                                                                                            </ng-container>
                                                                                        </ng-container>
                                                                                    </div>
                                                                                </div>
                                                                                <!--                                                                                <p-timeline [value]="project.commentList" align="alternate" styleClass="customized-timeline">-->
                                                                                <!--                                                                                    <ng-template pTemplate="marker" let-event>-->
                                                                                <!--                                                                                            <span class="custom-marker p-shadow-2 bg-main-color">-->
                                                                                <!--                                                                                                <i *ngIf="event.itemType==9" class="pi pi-chevron-left"></i>-->
                                                                                <!--                                                                                                <i *ngIf="event.itemType==10" class="pi pi-chevron-right"></i>-->
                                                                                <!--                                                                                            </span>-->
                                                                                <!--                                                                                    </ng-template>-->
                                                                                <!--                                                                                    <ng-template pTemplate="content" let-event>-->
                                                                                <!--                                                                                        <p-panel [header]="event.title" [toggleable]="true" [collapsed]="true" toggler="header">-->
                                                                                <!--                                                                                            <p [innerText]="event.comment"></p>-->
                                                                                <!--                                                                                        </p-panel>-->
                                                                                <!--                                                                                        <div class="row mx-0 w-100 px-2 pt-1 pb-2 comment-time">-->
                                                                                <!--                                                                                            <span class="w-50 text-left">{{event.adminName}}</span>-->
                                                                                <!--                                                                                            <span class="w-50 text-right">{{event.datetime}}</span>-->
                                                                                <!--                                                                                        </div>-->

                                                                                <!--                                                                                    </ng-template>-->
                                                                                <!--                                                                                </p-timeline>-->
                                                                            </p-accordionTab>
                                                                        </p-accordion>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                                <div class="col-12">
                                                    <div class="flex align-items-center justify-content-center" *ngIf="totalCountEntry != null && totalCountEntry != 0">
                                                        <div class="flex-1">
                                                            <p-paginator (onPageChange)="onPageEntryChange($event)" [first]="offsetEntry" [rows]="limitEntry" [totalRecords]="totalCountEntry" [showFirstLastIcon]="true"></p-paginator>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="!haveEntry">
                                            <div class="row mx-0">
                                                <div class="text-center profile-save-button black-color">
                                                    現在応募中の求人情報はまだありません。<br>
                                                    <a class="profile-common-btn w-100 main-color" routerLink="/project/">求人情報をもっと見る〜</a>
                                                </div>
                                                <div class="col-12 text-center">
                                                    <img src="assets/img/search_confuse.png" class="w-50 m-5">
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="uk-section uk-section-default">
                            </div>
                        </li>
                    </ul>
                </div>

            </div>
        </div>


        
<!--        <div class="text-right logout-button mb-2">-->
<!--            <a class="btn logout-btn w-25" (click)="logout()"><i class="icofont-logout"></i> ログアウト</a>-->
<!--        </div>-->
    </div>
</div>
<p-dialog header="メッセージを記入" [(visible)]="displayBasic2" styleClass="msg-dialog">
    <form class="create-information p-3 mb-0" style="border: none; min-width: 40vw">
        <div class="row mx-0 w-100">

<!--            <div class="col-lg-12">-->
<!--                <div class="form-group">-->
<!--                    <label>タイトル</label>-->
<!--                    <input type="text" class="form-control" [(ngModel)]="commentForm.title" name="title">-->
<!--                </div>-->
<!--            </div>-->

            <div class="col-lg-12">
                <div class="form-group mb-0">
<!--                    <label>メッセージ</label>-->
                    <textarea id="comment" class="form-control" rows="8"
                              [(ngModel)]="commentForm.comment" name="comment">{{commentForm.comment}}</textarea>
                </div>
            </div>
        </div>
    </form>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-send" (click)="displayBasic2=false;sendMessage()" label="確定"></p-button>
    </ng-template>
</p-dialog>
