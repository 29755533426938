export class ClientDetail {

    id: number;

    uuid: string;

    agentName: string;

    agentDesc: string;

    officialUrl: string;

    area: string;

    establishDate: string;

    employeeCount: string;

    capital: number;

    director: string;

    tel: string;

    mail: string;

    address: string;

    businessDesc: string;

    industry: string;

    companyFeature: string;

    manageComment: string;

    img1: string;

    img2: string;

    img3: string;

    img4: string;

    img5: string;

}
