import { Component, OnInit } from '@angular/core';
import {HeaderService} from "../../../service/header.service";
import {AuthService} from "../../../service/auth.service";
import {ProjectList} from "../project-list/project-list.model";
import {ProjectService} from "../../../service/project.service";
import {Router} from "@angular/router";
import {CookieService} from "../../../service/cookie.service";
import {NgwWowService} from "ngx-wow";
// import Swiper core and required components
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
} from 'swiper';

// install Swiper components
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller
]);

SwiperCore.use([Virtual]);

declare function recallSort(): any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  isLogin!: boolean;
  isLoaded = false;

  projectList: ProjectList[] = [];

  data: any[] = [
    {url:'../../../assets/img/home/1.png'},
    {url:'../../../assets/img/home/2.png'},
    {url:'../../../assets/img/home/3.png'},
    {url:'../../../assets/img/home/4.png'},
    {url:'../../../assets/img/home/5.png'},
    {url:'../../../assets/img/home/6.png'},
    {url:'../../../assets/img/home/7.png'}
  ]

  title = '' as string;
  area = '' as string;

  word= "【概要】システム移行評価・切替対応 人数】 1名\n" +
      "                                        【作業期間】2023年1月～9月\n" +
      "                                        【作業内容】インターフェース連携システムの新システム移行対応\n" +
      "                                        ※ (システム開発ではなく)連携環境の再構築及び移行評価・切替対応\n" +
      "                                        【場所】・ 田町、テレワーク\n" +
      "                                        【スキル要件】 ① java開発 4年以上② Azure構築経験\n" +
      "                                        ③ 次の技術は必須ではないが、経験あったらベター\n" +
      "                                        SQL Server： JobCenter：ジョブネット作成 NISMAIL：データ集配信ツール Azure\n" +
      "                                        【外国籍】可（日本語はコミュニケーション出来るレベル）\n" +
      "                                        【面談】 1回"

  constructor(
      public router: Router,
      private service: HeaderService,
      private authService: AuthService,
      private cookieService: CookieService,
      private projectService: ProjectService,
      private wowService: NgwWowService
  ) {
    this.isLogin = !!this.cookieService.getCookie("jb_user");
    this.wowService.init();
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getData();
  }

  getData() {
    this.projectService.getProjectListHome().subscribe(res => {
      this.projectList = res.data.projectList;
      console.log(this.projectList)
      recallSort();
      this.isLoaded = true;
    })
  }

  // search
  goToSearch(val: number): void {
    if (val == null) {
      return;
    }
    if(val == 0){
      this.router.navigate(['/project']);
      //window.open(url);
    }else {
      this.router.navigate(['/project', {categoryId: val}]);
      //window.open(url);
    }
  }

  like(uuid: string):void{
    const jb_user = this.cookieService.getCookie('jb_user');

    if(jb_user){
      this.projectService.like(uuid).subscribe( res => {
        // window.scrollTo(0, 0);
        if(res.code === 0){
          // window.location.reload();
          for(let i = 0; i < this.projectList.length; i++){
            if(this.projectList[i].uuid == uuid){
              this.projectList[i].isLike = !this.projectList[i].isLike;
            }
          }
        }
      })
    }else{
      this.router.navigate(['/login']);
    }
  }

  goToDetail(val: string, event: any) :void{
    if((event.ctrlKey && !event.metaKey) || (!event.ctrlKey && event.metaKey)){
      const url = this.router.serializeUrl(this.router.createUrlTree(['/project/detail/'+val]));
      window.open(url, '_blank');
    }else{
      this.router.navigate(['/project/detail/'+val]);
      //window.open(url);
    }
  }

  goToClientDetail(val: string, event: any) :void{
    if((event.ctrlKey && !event.metaKey) || (!event.ctrlKey && event.metaKey)){
      const url = this.router.serializeUrl(this.router.createUrlTree(['/client/detail/'+val]));
      window.open(url, '_blank');
    }else{
      this.router.navigate(['/client/detail/'+val]);
      //window.open(url);
    }
  }

  format(amount: number): string{
    let amountJPY = "";

    amountJPY = Number(amount).toLocaleString("ja-JP")

    return amountJPY;
  }

  goToList(): void{
    if(!this.isLogin){
      this.router.navigate(['/login']);
      //window.open(url);
    }else{
      this.router.navigate(['/project']);
      //window.open(url);
    }
  }

  toEntry(): void{
    this.router.navigate(['/profile',{page: 'isEntry'}]);
    //window.open(url);
  }
}
