import {EventEmitter, Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {StatusCode} from '../form/status-code';

@Injectable({
    providedIn: 'root'
})
export class HeaderService extends BaseService {
    lang: EventEmitter<string> = new EventEmitter<string>();
    tipsFlg: EventEmitter<boolean> = new EventEmitter<boolean>();
    isLogin: EventEmitter<boolean> = new EventEmitter<boolean>();
    constructor(
        private http: HttpClient,
        private statusCode: StatusCode,
    ) {
        super();
        // `Authorization` に `Bearer トークン` をセットする
        this.setAuthorization('c3RzZXJ2aWNlOmZZenJleWc1c2E0UQ==');
    }

    emitIsLoginEvent(isLogin: boolean): void {
        this.isLogin.emit(isLogin);
    }
    getIsLoginEmitter(): EventEmitter<boolean> {
        return this.isLogin;
    }

    emitLangChangeEvent(lang: string): void {
        this.lang.emit(lang);
    }
    getLangChangeEmitter(): EventEmitter<string> {
        return this.lang;
    }

    emitTipsFlgEvent(tipsFlg: boolean): void {
        this.tipsFlg.emit(tipsFlg);
    }
    getTipsFlgEmitter(): EventEmitter<boolean> {
        return this.tipsFlg;
    }

    public setAuthorization(token: string): void {
        if (!token) {
            return;
        }
        const bearerToken = `Bearer ${token}`;
        this.httpOptions.headers = this.httpOptions.headers.set('Authorization', bearerToken);
    }

    // // リーガルチック報告
    // legalJudgementSubmit(uuid: string): void {
    //   window.location.href = this.API_PATH.LEGAL_JUDGEMENT + uuid + '/';
    // }
    // // リーガルチック領収書
    // legalReceiptSubmit(uuid: string): void {
    //   window.location.href = this.API_PATH.LEGAL_RECEIPT + uuid + '/';
    // }
    //
    // legalJudgementPreview(uuid: string): Observable<any> {
    //   return this.http.get(this.API_PATH.LEGAL_JUDGEMENT_PREVIEW + uuid + '/', this.httpOptions)
    //     .pipe(
    //       map((response: any) => {
    //         if (response.code !== this.statusCode.SUCCESS) {
    //           return null;
    //         }
    //         return response.data;
    //       })
    //     );
    // }
    //
    // legalReceiptPreview(uuid: string): Observable<any> {
    //   return this.http.get(this.API_PATH.LEGAL_RECEIPT_PREVIEW + uuid + '/', this.httpOptions)
    //     .pipe(
    //       map((response: any) => {
    //         if (response.code !== this.statusCode.SUCCESS) {
    //           return null;
    //         }
    //         return response.data;
    //       })
    //     );
    // }
    //
    // supportJudgementPreview(uuid: string): Observable<any> {
    //   return this.http.get(this.API_PATH.SUPPORT_JUDGEMENT_PREVIEW + uuid + '/', this.httpOptions)
    //     .pipe(
    //       map((response: any) => {
    //         if (response.code !== this.statusCode.SUCCESS) {
    //           return null;
    //         }
    //         return response.data;
    //       })
    //     );
    // }
}
