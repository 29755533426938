import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PasswordSetService} from "../../../../service/password-set.service";
import {StatusCode} from "../../../../form/status-code";
import {ResultService} from "../../../../service/result.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  showSetPasswordView = true;
  showSetPasswordError = false;
  mail!: string;
  token!: string;
  confirmPassword!: string;
  svg!: string;
  title!: string;
  content!: string;
  password!: string;
  confirmError = false;

  message = "";
  messageConfirm = "";
  foreignTypeList: number[] = [];

  foreignTypeMap = new Map([
    [10, "Mail"],
    [20, "Twitter"],
    [30, "Facebook"],
    [40, "Google"],
    [50, "WeChat"],
    [60, "その他"],
  ]);

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private service: PasswordSetService,
      private statusCode: StatusCode,
      private resultService: ResultService
  ) {

  }

  ngOnInit(): void {
    const token = String(this.route.snapshot.paramMap.get('token'));
    this.service.getResetOneTimeToken(token).subscribe(res => {

      // パスワードformデフォルト値を設定
      if (res.code === this.statusCode.SUCCESS) {
        this.mail = res.data.mail;
        this.token = res.data.token;
        // console.debug('------' + res.data.mail);
      }

      // エラー提示
      if (res.code === this.statusCode.COM_DATA_NOT_FOUND) {
        this.showSetPasswordView = false;
        this.showSetPasswordError = true;
        this.svg = 'caveat.svg';
        this.resultService.title = '担当者へご連絡ください！';
        this.resultService.message1 = '無効なアクセスを検知しました！';
        this.router.navigateByUrl('/result');
      }

      // 新しい方式追加
      if (res.code === this.statusCode.AUTH_REGISTER_METHOD_ADD) {
        this.mail = res.data.mail;
        this.token = res.data.token;
        this.message = res.message;
        this.messageConfirm = "パスワードの設定手順を完了すると、新しく追加されたメールのログイン方法がデフォルトで同じアカウントに接続されます。";
        this.foreignTypeList = res.data.foreignTypeList;
      }
    });
  }

  // reset password
  passwordResetSubmit(form: any): void {
    const passwordSetForm = {
      mail: form.mail,
      token: form.token,
      password: form.password
    };
    this.service.postResetPassword(passwordSetForm).subscribe((res: any) => {
      // パスワード設定のDIVを閉じる
      // this.showSetPasswordView = false;
      // 操作結果DIVを表示
      // this.showSetPasswordError = true;
      // エラー提示
      // if (res.code === this.statusCode.COM_DATA_NOT_FOUND) {
      //   this.svg = 'caveat.svg';
      //   this.resultService.title = '担当者へご連絡ください！';
      //   this.resultService.message1 = '無効なアクセスを検知しました！';
      //   this.router.navigateByUrl('/result');
      // }
      // // データ挿入失敗
      // if (res.code === this.statusCode.ATH_ACCOUNT_STATUS_FAIL_MESSAGE) {
      //   this.svg = 'caveat.svg';
      //   this.title = '担当者へご連絡ください！';
      //   this.content = res.message;
      // }
      // パスワード設定完了
      if (res.code === 0) {
        Swal.fire({
          title: 'Success!',
          text: '成功に設定しました。',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: true
        }).then((result) => {
          if(result.isConfirmed){
            this.router.navigate(['/login']).then(() => {
              window.location.reload();
            })
          }
        });
      }else{
        Swal.fire({
          title: 'Error!',
          text: '設定失敗しました。もう一度お試しください！',
          icon: 'error',
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: '閉じる'
        })
      }
    });
  }

  confirmCheck(): void{
    if (this.password !== this.confirmPassword){
      this.confirmError = true;
    }else{
      this.confirmError = false;
    }
  }

}
