import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CookieService} from "../../../service/cookie.service";

@Component({
  selector: 'app-login-success',
  templateUrl: './login-success.component.html',
  styleUrls: ['./login-success.component.scss']
})
export class LoginSuccessComponent implements OnInit {

  constructor(
      private activatedRoute: ActivatedRoute,
      private cookieService: CookieService,
      public router: Router,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      const status = params['status'];
      const jb_user = params['token'];
      // const uid = params['uid'];
      // const jb_user = params['jb_user'];
      if (status === 'success') {
        this.cookieService.setCookie("jb_user", jb_user, 10);
        // this.cookieService.setCookie("uid", uid, 10);
        // this.cookieService.setCookie("jb_user", jb_user, 10);
        this.router.navigate(['/']).then(() => {
          window.location.reload();
        })
      }else{
        this.router.navigate(['/login']).then(() => {
          window.location.reload();
        })
      }
    })
  }

}
