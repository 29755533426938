import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ProjectListComponent} from "./components/pages/project-list/project-list.component";
import {ProjectDetailComponent} from "./components/pages/project-detail/project-detail.component";
import {TalentListComponent} from "./components/pages/talent-list/talent-list.component";
import {TalentDetailComponent} from "./components/pages/talent-detail/talent-detail.component";
import {LoginComponent} from "./components/pages/auth/login/login.component";
import {ProfileComponent} from "./components/pages/profile/profile.component";
import {SignupComponent} from "./components/pages/auth/signup/signup.component";
import {PasswordSetComponent} from "./components/pages/auth/password-set/password-set.component";
import {ResultComponent} from "./components/pages/result/result.component";
import {PasswordResetComponent} from "./components/pages/auth/password-reset/password-reset.component";
import {PasswordForgetComponent} from "./components/pages/auth/password-forget/password-forget.component";
import {HomeComponent} from "./components/pages/home/home.component";
import {TermComponent} from "./components/pages/term/term.component";
import {PrivacyComponent} from "./components/pages/privacy/privacy.component";
import {PersonalProtectionComponent} from "./components/pages/personal-protection/personal-protection.component";
import {CookieComponent} from "./components/pages/cookie/cookie.component";
import {LoginSuccessComponent} from "./components/common/login-success/login-success.component";
import {ClientDetailComponent} from "./components/pages/client-detail/client-detail.component";
import {AuthGuard} from "./helper/auth.guard";
import {TipsComponent} from "./components/pages/tips/tips.component";
import {EventComponent} from "./components/pages/event/event.component";

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'project', component: ProjectListComponent},
  {path: 'project/detail/:uuid', component: ProjectDetailComponent, canActivate: [AuthGuard]},
  {path: 'talent', component: TalentListComponent},
  {path: 'talent/detail/:uuid', component: TalentDetailComponent},
  {path: 'login', component: LoginComponent},
  {path: 'signup', component: SignupComponent},
  {path: 'profile', component: ProfileComponent},
  {path: 'password/set/:token', component: PasswordSetComponent},
  {path: 'password/forget', component: PasswordForgetComponent},
  {path: 'password/reset/:token', component: PasswordResetComponent},
  {path: 'result', component: ResultComponent},
  {path: 'term', component: TermComponent},
  {path: 'privacy', component: PrivacyComponent},
  {path: 'cookie-protection', component: CookieComponent},
  {path: 'personal-protection', component: PersonalProtectionComponent},
  {path: 'login-success', component: LoginSuccessComponent},
  {path: 'client/detail/:uuid', component: ClientDetailComponent},
  {path: 'tips', component: TipsComponent},
  {path: 'event', component: EventComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
