import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {ProjectService} from "../../../service/project.service";
import {CookieService} from "../../../service/cookie.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
      public router: Router,
      private projectService: ProjectService,
      private cookieService: CookieService
  ) { }

  ngOnInit(): void {
  }

  getShowFooter(): boolean {
    return this.projectService.showFooter;
  }
}
