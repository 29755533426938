<div class="job-details-area pt-100 pt-80-sp">
  <div class="container">
    <div class="row">
      <div class="col-lg-8">
        <div class="job-details-item" style="margin-top: -30px">
          <div class="job-description">
              <div class="uk-position-sticky bg-light-color" style="top: 60px;padding-top: 40px">
                  <h2>{{form?.name}}</h2>
                  <div class="border-top p-2">
                      <ng-container *ngFor="let tag of techList">
                          <span class="project-detail-tag-tech">{{tag.content}}</span>
                      </ng-container>
                      <ng-container *ngFor="let tag of positionList">
                          <span class="project-detail-tag-position">{{tag.content}}</span>
                      </ng-container>
                      <ng-container *ngFor="let tag of areaList">
                          <span class="project-detail-tag-area">{{tag.content}}</span>
                      </ng-container>
                      <ng-container *ngFor="let tag of chosenList">
                          <span class="project-detail-tag-chosen">{{tag.content}}</span>
                      </ng-container>
                  </div>
              </div>

            <p style="white-space: pre-line;">{{form?.content}}</p>
          </div>

          <div class="job-knowledge">
<!--            <h2>必要な知識、スキル、能力</h2>-->

            <ul>
<!--              <li><i class="icofont-hand-drawn-right"></i> スキル：{{form?.skill == null ? '&#45;&#45;' : form?.skill}}</li>-->
                <li><i class="icofont-hand-drawn-right"></i> <span class="p-detail-item-title">最寄駅</span>&nbsp;：&nbsp;{{form?.station == null ? '--' : form?.station}}</li>
                <li><i class="icofont-hand-drawn-right"></i> <span class="p-detail-item-title">人数</span>&nbsp;：&nbsp;{{form?.people == null ? '--' : form?.people}}</li>
                <li><i class="icofont-hand-drawn-right"></i> <span class="p-detail-item-title">経験年数</span>&nbsp;：&nbsp;{{form?.experience == null ? '--' : form?.experience}}&nbsp;年</li>
                <li><i class="icofont-hand-drawn-right"></i> <span class="p-detail-item-title">最大年齢</span>&nbsp;：&nbsp;{{form?.maxAge == null ? '--' : form?.maxAge}}&nbsp;才</li>
                <li><i class="icofont-hand-drawn-right"></i> <span class="p-detail-item-title">単価目安</span>&nbsp;：&nbsp;{{format(form?.price)}}&nbsp;円〜</li>
                <li><i class="icofont-hand-drawn-right"></i> <span class="p-detail-item-title">精算時間</span>&nbsp;：&nbsp;{{form?.workTime == '〜' ? '--' : form?.workTime}}&nbsp;時間／月</li>
                <li><i class="icofont-hand-drawn-right"></i> <span class="p-detail-item-title">面談回数</span>&nbsp;：&nbsp;{{form?.interviewNumber == null ? '--' : form?.interviewNumber}}&nbsp;回</li>
                <li><i class="icofont-hand-drawn-right"></i> <span class="p-detail-item-title">備考</span>&nbsp;：&nbsp;{{form?.remarks == null ? '--' : form?.remarks}}</li>
            </ul>
          </div>

                  <div class="job-knowledge sticky-bottom mt-5 mb-0" style="bottom: 20px">
                      <div class="contact-form-area">
                          <div class="container-fluid px-0">
                              <div class="row contact-wrap mx-0">
                                  <mat-expansion-panel hideToggle *ngIf="!form?.isEntry" class="apply-mat-expansion-panel">
                                      <mat-expansion-panel-header class="apply-mat-expansion-panel-header">
                                          <mat-panel-title class="apply-mat-panel-title">
                                              <i class="icofont-location-arrow"></i> 今すぐ応募
                                          </mat-panel-title>
                                      </mat-expansion-panel-header>
                                      <div class="desktop-container">
                                          <div class="row mx-0">
                                              <label class="col-2 px-0 text-center main-color">メッセージ</label>
                                              <textarea class="col-10 message" rows="5" [(ngModel)]="message">{{message}}</textarea>
                                              <label class="col-2" style="padding: 5px"></label>
                                              <label *ngIf="applyError" class="col-10 pl-3 text-left text-danger pt-0 mt-0 font-size-3">
                                                  {{applyErrorText}}
                                                  <a routerLink="/profile" class="main-color" style="text-decoration: underline" *ngIf="applyError && !applyErrorText.includes('空')">個人画面へ</a>
                                              </label>
                                          </div>
                                          <div class="row mx-0 justify-content-end confirm-apply mt-0">
                                              <a class=" col-2 apply-btn" (click)="apply()">確定</a>
                                          </div>
                                          <div class="enable-cover" *ngIf="!enable">
                                              <p *ngIf="disableType == 1" class="entry-disabled-type1">応募する前に、<span class="danger-color">個人情報</span>を記入する必要があります。</p>
                                              <p *ngIf="disableType == 2" class="entry-disabled-type2">応募する前に、個人情報の<br>
                                                  <span class="danger-color">「名前」、「メールアドレス」、「履歴書」、「転職意向」</span><br>
                                                  を記入する必要があります。
                                              </p>
                                              <a routerLink="/profile" class="main-color" style="text-decoration: underline">個人画面へ</a>
                                          </div>
                                      </div>
                                      <div class="mobile-container">
                                          <div class="row mx-0">
                                              <label class="col-12 px-0 text-left main-color pt-0">メッセージ</label>
                                              <textarea class="col-12 message" rows="5" [(ngModel)]="message">{{message}}</textarea>
                                              <label *ngIf="applyError" class="pl-3 text-left text-danger pt-0 mt-0 font-size-3">
                                                  {{applyErrorText}}
                                                  <br>
                                                  <a routerLink="/profile" class="main-color" style="text-decoration: underline" *ngIf="applyError && !applyErrorText.includes('空')">個人画面へ</a>
                                              </label>
                                          </div>
                                          <div class="row mx-0 justify-content-center confirm-apply">
                                              <a class=" col-2 apply-btn" (click)="apply()">確定</a>
                                          </div>
                                          <div class="enable-cover" *ngIf="!enable">
                                              <p *ngIf="disableType == 1" class="entry-disabled-type1">応募する前に、<br><span class="danger-color">個人情報</span>を記入する必要があります。</p>
                                              <p *ngIf="disableType == 2" class="entry-disabled-type2">応募する前に、個人情報の<br>
                                                  <span class="danger-color">
                                                      「名前」、「メールアドレス」、<br>
                                                      「履歴書」、「転職意向」
                                                  </span><br>
                                                  を記入する必要があります。
                                              </p>
                                              <a routerLink="/profile" class="main-color" style="text-decoration: underline">個人画面へ</a>
                                          </div>
                                      </div>
                                  </mat-expansion-panel>
                                  <div class="col-md-12 col-lg-12 px-0" *ngIf="form?.isEntry">
                                      <div class="text-left logout-button mb-0">
                                          <a class="btn logout-btn disabled"><i class="icofont-check"></i> すでに応募済み</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

        </div>
      </div>

      <div class="col-lg-4 mobile-container">
        <div class="job-details-item2">

          <div class="job-overview job-company">

            <h3 style="margin-top: 20px">相関求人一覧</h3>

              <div class="row">
                  <ng-container *ngFor="let project of projectList">
                      <div class="col-12">
                          <div class="job-item">
                              <div class="job-inner align-items-center">
                                  <div class="position-absolute project-list-like-action">
                                      <ng-container *ngIf="!project.isLike">
                                          <a (click)="like(project.uuid)"
                                             matTooltip="お気に入り"
                                             matTooltipPosition="right"
                                             class="cursor-pointer">
                                              <mat-icon class="gray-color3">favorite</mat-icon>
                                          </a>
                                      </ng-container>
                                      <ng-container *ngIf="project.isLike">
                                          <a (click)="like(project.uuid)"
                                             matTooltip="取り消し"
                                             matTooltipPosition="right"
                                             class="cursor-pointer">
                                              <mat-icon class="danger-color">favorite_border</mat-icon>
                                          </a>
                                      </ng-container>
                                  </div>

                                  <div class="job-inner-left">
                                      <span class="cursor-pointer" (click)="goToClientDetail(project.agentUuid, $event)">{{project.agentName}}</span>
                                      <h3 class="text-ellipsis-1 h-title cursor-pointer main-color" (click)="goToDetail(project.uuid, $event)">
                                          <label class="project-new" *ngIf="project.isRecent">NEW</label>
                                          {{project.title}}</h3>
                                      <!--                                    <p class="text-ellipsis-3 h-content">{{project.content}}</p>-->
                                      <!--                                    <div class="row gray-color">-->
                                      <!--                                        <div class="col-5" *ngIf="project.price != null && project.price != ''"><i class="icofont-money-bag main-color"></i> ¥ {{project.price}} 万</div>-->
                                      <!--                                        <div class="col-5" *ngIf="project.price == null || project.price == ''"><i class="icofont-money-bag main-color"></i> ¥ &#45;&#45; 万</div>-->
                                      <!--                                        <div class="col-12 station"><i class="icofont-location-pin main-color"></i> {{project.station}}</div>-->
                                      <!--                                    </div>-->
                                  </div>
                                  <!--                                <div class="border-top w-100" style="margin: 10px 3px;"></div>-->
                                  <div class="row mx-0 col-12 px-2 cursor-pointer w-100 my-2" (click)="goToDetail(project.uuid, $event)">
                                      <ul>
                                          <li>
                                              <div class="row mx-0">
                                                  <div class="d-flex col-5 client-details-info-header">
                                                      <i class="icofont-yen-true ps-3"></i>
                                                      <div>月額</div>
                                                  </div>
                                                  <div class="col-7 client-details-info-body client-details-info-body-salary ps-3">
                                                      <div>{{format(project.price)}}円〜</div>
                                                  </div>
                                              </div>
                                          </li>
<!--                                          <li>-->
<!--                                              <div class="row mx-0">-->
<!--                                                  <div class="d-flex col-5 client-details-info-header">-->
<!--                                                      <i class="icofont-train-line ps-3"></i>-->
<!--                                                      <div>最寄駅</div>-->
<!--                                                  </div>-->
<!--                                                  <div class="col-7 client-details-info-body ps-3">-->
<!--                                                      <div>{{project.station}}</div>-->
<!--                                                  </div>-->
<!--                                              </div>-->
<!--                                          </li>-->
                                          <li>
                                              <div class="row mx-0">
                                                  <div class="d-flex col-5 client-details-info-header">
                                                      <i class="icofont-sand-clock ps-3"></i>
                                                      <div>経験年数</div>
                                                  </div>
                                                  <div class="col-7 client-details-info-body ps-3">
                                                      <div *ngIf="project.experience != null && project.experience > 0"
                                                           style="padding-top: 1px">{{project.experience}}年〜</div>
                                                      <div *ngIf="project.experience == null || project.experience <= 0"
                                                           style="padding-top: 1px">年数不問</div>
                                                  </div>
                                              </div>
                                          </li>
                                          <li>
                                              <div class="row mx-0">
                                                  <div class="d-flex col-5 client-details-info-header">
                                                      <i class="icofont-tags ps-3"></i>
                                                      <div>キーワード</div>
                                                  </div>
                                                  <div class="col-7 client-details-info-body ps-3">
                                                      <ng-container *ngIf="project.tagList != null && project.tagList.length != 0">
                                                          <ng-container *ngFor="let tag of project.tagList">
                                                              <div class="project-list-tag">{{tag.content}}</div>
                                                          </ng-container>
                                                      </ng-container>
                                                      <ng-container *ngIf="project.tagList == null || project.tagList.length == 0">
                                                          <div>--</div>
                                                      </ng-container>
                                                  </div>
                                              </div>
                                          </li>
                                      </ul>
                                  </div>


                                  <!--                                <div class="job-inner-right">-->
                                  <!--                                    <ul>-->
                                  <!--                                        <li *ngIf="!project.isLike"><a (click)="like(project.uuid)" matTooltip="お気に入り" matTooltipPosition="right" class="cursor-pointer"><i class="icofont-heart-alt"></i></a></li>-->
                                  <!--                                        <li *ngIf="project.isLike"><a (click)="like(project.uuid)" matTooltip="取り消し" matTooltipPosition="right" class="cursor-pointer"><i class="icofont-verification-check"></i></a></li>-->
                                  <!--&lt;!&ndash;                                        <li><a [routerLink]="'/project/detail/' + project.uuid" matTooltip="詳細" matTooltipPosition="right" class="cursor-pointer"><i class="icofont-justify-all"></i></a></li>&ndash;&gt;-->
                                  <!--                                    </ul>-->
                                  <!--                                </div>-->

                                  <!--                                <div class="border-top w-100" style="margin: 10px 3px;"></div>-->
                                  <div class="w-100 row mx-0">
                                      <div class="col-10">
                                          <div class="project-list-desc cursor-pointer" (click)="goToDetail(project.uuid, $event)" [innerText]="project.content">

                                          </div>
                                      </div>
                                      <div class="col-2 category-img align-self-center">
                                          <img *ngIf="project.category==null" src="assets/img/category/code.png" width="50" alt="Job">
                                          <img *ngIf="project.category!=null && project.category==10" src="assets/img/category/java.png" width="50" alt="Job">
                                          <img *ngIf="project.category!=null && project.category==20" src="assets/img/category/python.png" width="50" alt="Job">
                                          <img *ngIf="project.category!=null && project.category==30" src="assets/img/category/react.png" width="50" alt="Job">
                                          <img *ngIf="project.category!=null && project.category==40" src="assets/img/category/php.png" width="50" alt="Job">
                                          <img *ngIf="project.category!=null && project.category==50" src="assets/img/category/ios.svg" width="50" alt="Job">
                                          <img *ngIf="project.category!=null && project.category==60" src="assets/img/category/android.svg" width="50" alt="Job">
                                          <img *ngIf="project.category!=null && project.category==70" src="assets/img/category/angular.svg" width="50" alt="Job">
                                          <img *ngIf="project.category!=null && project.category==80" src="assets/img/category/net.png" width="50" alt="Job">
                                          <img *ngIf="project.category!=null && project.category==90" src="assets/img/category/aws.svg" width="50" alt="Job">
                                          <img *ngIf="project.category!=null && project.category==100" src="assets/img/category/vue.png" width="50" alt="Job">
                                          <img *ngIf="project.category!=null && project.category==999" src="assets/img/category/code.png" width="50" alt="Job">
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </ng-container>
              </div>
<!--              <ng-container *ngFor="let project of projectList">-->
<!--                  <div class="w-100">-->
<!--                      <div class="job-item p-3">-->
<!--                          <div class="job-inner align-items-center ps-0">-->
<!--                              <div class="w-100">-->
<!--                                  <h6 class="text-ellipsis-2 h-title cursor-pointer" [routerLink]="'/project/detail/' + project.uuid">{{project.title}}</h6>-->
<!--                                  &lt;!&ndash;                                    <p class="text-ellipsis-3 h-content">{{project.content}}</p>&ndash;&gt;-->
<!--                                  <div class="row gray-color">-->
<!--                                      &lt;!&ndash;                                        <div class="col-5" *ngIf="project.price != null && project.price != ''"><i class="icofont-money-bag main-color"></i> ¥ {{project.price}} 万</div>&ndash;&gt;-->
<!--                                      &lt;!&ndash;                                        <div class="col-5" *ngIf="project.price == null || project.price == ''"><i class="icofont-money-bag main-color"></i> ¥ &#45;&#45; 万</div>&ndash;&gt;-->
<!--                                      <div class="col-12 station"><i class="icofont-location-pin main-color"></i> {{project.station}}</div>-->
<!--                                  </div>-->
<!--                              </div>-->
<!--                          </div>-->
<!--                      </div>-->
<!--                  </div>-->
<!--              </ng-container>-->
          </div>
        </div>

          <div class="px-0">
              <div class="text-left logout-button mb-0">
                  <a class="btn logout-btn" routerLink="/project">求人一覧に戻る</a>
              </div>
          </div>
      </div>


        <div class="col-lg-4 px-0 desktop-container" style="height: calc(100vh - 120px);overflow: auto;position: sticky;top: 100px">
            <div class="d-flex">
                <div class="job-details-item2">
                    <div class="job-overview job-company">
                        <h3 style="position: sticky;top: 0;background: #ffffff;padding: 5px 12px;z-index: 1;margin-bottom: 10px">相関求人一覧</h3>
                        <div class="row mx-0" style="padding-top: 12px;box-shadow: rgba(0, 0, 0, 0.06) 0 0 14px 0 inset">
                            <ng-container *ngFor="let project of projectList">
                                <div class="col-12">
                                    <div class="job-item">
                                        <div class="job-inner align-items-center">
                                            <div class="position-absolute project-list-like-action">
                                                <ng-container *ngIf="!project.isLike">
                                                    <a (click)="like(project.uuid)"
                                                       matTooltip="お気に入り"
                                                       matTooltipPosition="right"
                                                       class="cursor-pointer">
                                                        <mat-icon class="gray-color3">favorite</mat-icon>
                                                    </a>
                                                </ng-container>
                                                <ng-container *ngIf="project.isLike">
                                                    <a (click)="like(project.uuid)"
                                                       matTooltip="取り消し"
                                                       matTooltipPosition="right"
                                                       class="cursor-pointer">
                                                        <mat-icon class="danger-color">favorite_border</mat-icon>
                                                    </a>
                                                </ng-container>
                                            </div>

                                            <div class="entry-tag" *ngIf="project.isEntry" (click)="toEntry()">
                                                応募済
                                            </div>

                                            <div class="job-inner-left">
                                                <span class="cursor-pointer" (click)="goToClientDetail(project.agentUuid, $event)">{{project.agentName}}</span>
                                                <h3 class="text-ellipsis-1 h-title cursor-pointer main-color" (click)="goToDetail(project.uuid, $event)">
                                                    <label class="project-new" *ngIf="project.isRecent">NEW</label>{{project.title}}</h3>
                                            </div>
                                            <div class="row mx-0 col-12 px-2 cursor-pointer w-100 my-2" (click)="goToDetail(project.uuid, $event)">
                                                <ul>
                                                    <li>
                                                        <div class="row mx-0">
                                                            <div class="d-flex col-5 client-details-info-header">
                                                                <i class="icofont-yen-true ps-3"></i>
                                                                <div>月額</div>
                                                            </div>
                                                            <div class="col-7 client-details-info-body client-details-info-body-salary ps-3">
                                                                <div>{{format(project.price)}}円〜</div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="row mx-0">
                                                            <div class="d-flex col-5 client-details-info-header">
                                                                <i class="icofont-sand-clock ps-3"></i>
                                                                <div>経験年数</div>
                                                            </div>
                                                            <div class="col-7 client-details-info-body ps-3">
                                                                <div *ngIf="project.experience != null && project.experience > 0"
                                                                     style="padding-top: 1px">{{project.experience}}年〜</div>
                                                                <div *ngIf="project.experience == null || project.experience <= 0"
                                                                     style="padding-top: 1px">年数不問</div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="row mx-0">
                                                            <div class="d-flex col-5 client-details-info-header">
                                                                <i class="icofont-tags ps-3"></i>
                                                                <div>キーワード</div>
                                                            </div>
                                                            <div class="col-7 client-details-info-body ps-3">
                                                                <ng-container *ngIf="project.tagList != null && project.tagList.length != 0">
                                                                    <ng-container *ngFor="let tag of project.tagList">
                                                                        <div class="project-list-tag">{{tag.content}}</div>
                                                                    </ng-container>
                                                                </ng-container>
                                                                <ng-container *ngIf="project.tagList == null || project.tagList.length == 0">
                                                                    <div>--</div>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="w-100 row mx-0">
                                                <div class="col-10">
                                                    <div class="project-list-desc cursor-pointer" (click)="goToDetail(project.uuid, $event)" [innerText]="project.content">

                                                    </div>
                                                </div>
                                                <div class="col-2 category-img align-self-center">
                                                    <img *ngIf="project.category==null" src="assets/img/category/code.png" width="50" alt="Job">
                                                    <img *ngIf="project.category!=null && project.category==10" src="assets/img/category/java.png" width="50" alt="Job">
                                                    <img *ngIf="project.category!=null && project.category==20" src="assets/img/category/python.png" width="50" alt="Job">
                                                    <img *ngIf="project.category!=null && project.category==30" src="assets/img/category/react.png" width="50" alt="Job">
                                                    <img *ngIf="project.category!=null && project.category==40" src="assets/img/category/php.png" width="50" alt="Job">
                                                    <img *ngIf="project.category!=null && project.category==50" src="assets/img/category/ios.svg" width="50" alt="Job">
                                                    <img *ngIf="project.category!=null && project.category==60" src="assets/img/category/android.svg" width="50" alt="Job">
                                                    <img *ngIf="project.category!=null && project.category==70" src="assets/img/category/angular.svg" width="50" alt="Job">
                                                    <img *ngIf="project.category!=null && project.category==80" src="assets/img/category/net.png" width="50" alt="Job">
                                                    <img *ngIf="project.category!=null && project.category==90" src="assets/img/category/aws.svg" width="50" alt="Job">
                                                    <img *ngIf="project.category!=null && project.category==100" src="assets/img/category/vue.png" width="50" alt="Job">
                                                    <img *ngIf="project.category!=null && project.category==999" src="assets/img/category/code.png" width="50" alt="Job">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <!--              <ng-container *ngFor="let project of projectList">-->
                        <!--                  <div class="w-100">-->
                        <!--                      <div class="job-item p-3">-->
                        <!--                          <div class="job-inner align-items-center ps-0">-->
                        <!--                              <div class="w-100">-->
                        <!--                                  <h6 class="text-ellipsis-2 h-title cursor-pointer" [routerLink]="'/project/detail/' + project.uuid">{{project.title}}</h6>-->
                        <!--                                  &lt;!&ndash;                                    <p class="text-ellipsis-3 h-content">{{project.content}}</p>&ndash;&gt;-->
                        <!--                                  <div class="row gray-color">-->
                        <!--                                      &lt;!&ndash;                                        <div class="col-5" *ngIf="project.price != null && project.price != ''"><i class="icofont-money-bag main-color"></i> ¥ {{project.price}} 万</div>&ndash;&gt;-->
                        <!--                                      &lt;!&ndash;                                        <div class="col-5" *ngIf="project.price == null || project.price == ''"><i class="icofont-money-bag main-color"></i> ¥ &#45;&#45; 万</div>&ndash;&gt;-->
                        <!--                                      <div class="col-12 station"><i class="icofont-location-pin main-color"></i> {{project.station}}</div>-->
                        <!--                                  </div>-->
                        <!--                              </div>-->
                        <!--                          </div>-->
                        <!--                      </div>-->
                        <!--                  </div>-->
                        <!--              </ng-container>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</div>
